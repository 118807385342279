import React from 'react'
import { useTranslation } from 'react-i18next'

import { ContactWithSupport } from 'components/ContactWithSupport'

import secureBadge from 'assets/images/newYearOnboarding/secure-badge.jpg'

import { PagesSource } from 'root-constants'

import { StyledSecurityInfoNewYear as S } from './SecurityInfoNewYear.styles'

export const SecurityInfoNewYear: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <S.SecureBadge src={secureBadge} alt="Secure badge" />
      <S.Title>{t`purchase1.securityInfo.titleFirst`}</S.Title>
      <S.Text>{t`purchase1.securityInfo.textFirst`}</S.Text>
      <S.Title>{t`purchase1.securityInfo.titleSecond`}</S.Title>
      <S.Text>{t`purchase1.securityInfo.textSecond`}</S.Text>
      <S.Title>{t`purchase1.securityInfo.titleThird`}</S.Title>

      <S.Text>
        <ContactWithSupport
          source={PagesSource.PAYWALL}
          text={t('commonComponents.contactSupport')}
        />
      </S.Text>
    </S.Wrapper>
  )
}
