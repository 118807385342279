import React, { Suspense } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { GrowthBookProvider } from '@growthbook/growthbook-react'
import { polyfill as smoothScrollPolyfill } from 'smoothscroll-polyfill'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import { LayoutProvider } from 'components/LayoutProvider'
import { Spinner } from 'components/Spinner'

import {
  selectConfig,
  selectCurrentVariant,
  selectLanguage,
} from 'root-redux/selects/common'
import { selectUserStatus } from 'root-redux/selects/user'

import { growthBook } from 'hooks/useGrowthBook'
import { useHotJarInitialization } from 'hooks/useHotJarInitialization'
import { useInContextInitialization } from 'hooks/useInContextInitialization'
import { useInitAppConfig } from 'hooks/useInitAppConfig'
import { useLanguage } from 'hooks/useLanguage'
import { useOutbrainPixelInitialization } from 'hooks/useOutbrainPixelInitialization'
import { useRedditPixelInitialization } from 'hooks/useRedditPixelInitialization'
import { useScrollFix } from 'hooks/useScrollFix'
import { useSetUserStatus } from 'hooks/useSetUserStatus'
import { useSetUuid } from 'hooks/useSetUuid'
import { useSetVariant } from 'hooks/useSetVariant'
import { useSnapPixelInitialization } from 'hooks/useSnapPixelInitialization'
import { useTaboolaPixelInitialization } from 'hooks/useTaboolaPixelInitialization'
import { useTestEnvQueryParameter } from 'hooks/useTestEnvQueryParameter'
import { useTiktokPixelInitialization } from 'hooks/useTiktokPixelInitialization'

import { setViewportFullHeight } from 'helpers/setViewportFullHeight'

import { browserHistory } from 'browser-history'

import { CustomRouter } from '../CustomRouter'

setViewportFullHeight()
smoothScrollPolyfill()

export const App: React.FC = () => {
  const userStatus = useSelector(selectUserStatus, shallowEqual)
  const variant = useSelector(selectCurrentVariant, shallowEqual)
  const config = useSelector(selectConfig)
  const language = useSelector(selectLanguage)

  useInitAppConfig()
  useScrollFix()
  useSetVariant()
  useLanguage()
  useSetUuid()
  useTestEnvQueryParameter()
  useSetUserStatus()
  useTiktokPixelInitialization()
  useSnapPixelInitialization()
  useRedditPixelInitialization()
  useTaboolaPixelInitialization()
  useOutbrainPixelInitialization()
  useHotJarInitialization()
  useInContextInitialization()

  return (
    <CustomRouter history={browserHistory}>
      <Suspense fallback={<Spinner />}>
        <GrowthBookProvider growthbook={growthBook}>
          {userStatus && variant && config && language ? (
            <LayoutProvider variant={variant} />
          ) : (
            <Spinner />
          )}
        </GrowthBookProvider>
      </Suspense>
    </CustomRouter>
  )
}
