import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Header } from 'components/Header'
import { HeaderBellyFlow } from 'components/HeaderBellyFlow'
import { InContextControlPanel } from 'components/InContextControlPanel'
import { LiveChat } from 'components/LiveChat'
import { OnboardingProgressBarBelly } from 'components/OnboardingProgressBarBelly'
import { PostPaymentProgressBar } from 'components/PostPaymentProgressBar'
import { GlobalFontStyle } from 'components/styles'

import { selectLanguage } from 'root-redux/selects/common'

import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useChatBotEmail } from 'hooks/useChatBotEmail'
import { useDynamicDiscount } from 'hooks/useDynamicDiscount'
import { useEventLoggerInitialization } from 'hooks/useEventLoggerInitialization'
import { useFacebookPixelInitialization } from 'hooks/useFacebookPixelInitialization'
import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useGrowthBook } from 'hooks/useGrowthBook'
import { useInitGoogleAnalyticsTracker } from 'hooks/useInitGoogleAnalyticsTracker'
import { useInitPinterestTag } from 'hooks/useInitPinterestTag'
import { useLiveChatAvailability } from 'hooks/useLiveChatAvailability'
import { useScrollToTop } from 'hooks/useScrollToTop'
import { useSetPlanAdditions } from 'hooks/useSetPlanAdditions'

import { getCurrentEnv } from 'helpers/getCurrentEnv'

import { IVariant } from 'models/variant.model'

import { Cohort, CurrentEnvironment, POST_PAYMENT_PAGES } from 'root-constants'

import { RouteList } from '../RouteList'
import { OnboardingProgressBar } from './OnboardingProgressBar'

interface IProps {
  variant: IVariant
}

export const LayoutProvider: React.FC<IProps> = ({ variant }) => {
  const { i18n } = useTranslation()
  const language = useSelector(selectLanguage)
  const { currentPageId } = useGetPageInfo()
  const { hasLiveChat } = useLiveChatAvailability()
  const { cohort, parentCohort, facebookPixelIds, pinterestTagId } = variant

  const isDevEnvironment = getCurrentEnv() === CurrentEnvironment.DEV

  const hasSubscription = useMemo(
    () => POST_PAYMENT_PAGES.includes(currentPageId),
    [currentPageId],
  )

  const isBellyFlow = useMemo(() => {
    const cohortToUse = parentCohort || cohort

    return cohortToUse === Cohort.DB_BELLY || cohortToUse === Cohort.DB_LEGS
  }, [cohort, parentCohort])

  useChatBotEmail()
  useInitGoogleAnalyticsTracker()
  useSetPlanAdditions()
  useFacebookPixelInitialization(facebookPixelIds)
  useInitPinterestTag(pinterestTagId)
  useEventLoggerInitialization(cohort)
  useAmplitudeInitialization(cohort as Cohort)
  useScrollToTop()
  useGrowthBook()
  useDynamicDiscount()

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [i18n, language])

  return (
    <>
      {isDevEnvironment && <InContextControlPanel />}

      {isBellyFlow && (
        <>
          <GlobalFontStyle />
          <HeaderBellyFlow />
          {hasSubscription ? (
            <PostPaymentProgressBar />
          ) : (
            <OnboardingProgressBarBelly />
          )}
        </>
      )}

      {!isBellyFlow && (
        <>
          <Header />
          {hasSubscription ? (
            <PostPaymentProgressBar />
          ) : (
            <OnboardingProgressBar />
          )}
        </>
      )}

      {hasLiveChat && <LiveChat />}
      <RouteList />
    </>
  )
}
