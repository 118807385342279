import styled, { css } from 'styled-components'

import { Color, DynamicDiscountTheme } from 'root-constants'

export const StyledLimitedDeal = {
  Container: styled.div<{ $theme?: string }>`
    display: flex;
    width: 100%;
    padding: 14px 12px;
    align-items: center;
    border-radius: 16px;
    margin-bottom: 32px;

    ${({ $theme }) => {
      switch ($theme) {
        case DynamicDiscountTheme.CORAL:
          return css`
            background: linear-gradient(
              91deg,
              rgba(255, 83, 67, 0.15) -8.21%,
              rgba(225, 34, 108, 0.15) 95.97%
            );
          `
        case DynamicDiscountTheme.YELLOW:
          return css`
            background: linear-gradient(
              91deg,
              rgba(255, 206, 60, 0.15) -8.21%,
              rgba(255, 144, 0, 0.15) 95.97%
            );
          `
        case DynamicDiscountTheme.VIOLET:
          return css`
            background: linear-gradient(
              269deg,
              rgba(116, 112, 255, 0.15) 0%,
              rgba(226, 163, 248, 0.15) 125.26%
            );
          `
        default:
          return css`
            background: linear-gradient(
              269deg,
              rgba(116, 112, 255, 0.15) 0%,
              rgba(226, 163, 248, 0.15) 125.26%
            );
          `
      }
    }}
  `,
  DiscountLabel: styled.div<{ $theme?: string }>`
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    color: ${Color.WHITE};
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
    margin-right: 16px;
    border-radius: 10px;

    ${({ $theme }) => {
      switch ($theme) {
        case DynamicDiscountTheme.CORAL:
          return css`
            background: linear-gradient(91deg, #ff5343 -8.21%, #e1226c 95.97%);
          `
        case DynamicDiscountTheme.YELLOW:
          return css`
            background: linear-gradient(91deg, #ffce3c -8.21%, #ff9000 95.97%);
          `
        case DynamicDiscountTheme.VIOLET:
          return css`
            background: linear-gradient(269deg, #7470ff 0%, #e2a3f8 125.26%);
          `
        default:
          return css`
            background: linear-gradient(269deg, #7470ff 0%, #e2a3f8 125.26%);
          `
      }
    }}
  `,
  Text: styled.p<{ $theme?: string }>`
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    ${({ $theme }) => {
      switch ($theme) {
        case DynamicDiscountTheme.CORAL:
          return css`
            background-image: linear-gradient(
              91deg,
              #ff5343 -8.21%,
              #e1226c 95.97%
            );
          `
        case DynamicDiscountTheme.YELLOW:
          return css`
            background-image: linear-gradient(
              91deg,
              #ffce3c -8.21%,
              #ff9000 95.97%
            );
          `
        case DynamicDiscountTheme.VIOLET:
          return css`
            background-image: linear-gradient(
              269deg,
              #7470ff 0%,
              #e2a3f8 125.26%
            );
          `
        default:
          return css`
            background-image: linear-gradient(
              269deg,
              #7470ff 0%,
              #e2a3f8 125.26%
            );
          `
      }
    }}
  `,
}
