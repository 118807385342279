import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledFeaturedBlockNewYear = {
  Wrapper: styled.div`
    margin-bottom: 24px;
  `,
  Title: styled.div`
    padding-bottom: 24px;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: ${Color.DISABLED};
    text-align: center;
  `,
}
