import styled, { css } from 'styled-components'

import { Container } from 'components/Container'
import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

import { StyledAgeFlowContainer } from '../Gender/Gender.styles'

type TSvgImageProps = {
  $top?: number
  $left?: number
}

type TOptionContentProps = {
  $isChecked: boolean
}

export const StyledImproveAreasMx = {
  TitleContainer: styled.div`
    padding: 0 5px;
  `,
  Row: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
  `,
  Image: styled.img`
    max-width: 202px;
  `,
  Col: styled.div`
    margin-left: 8px;
    flex-shrink: 0;
  `,
  OptionContent: styled.div<TOptionContentProps>`
    position: relative;
    min-width: 118px;
    margin-bottom: 16px;
    border-radius: 16px;
    background-color: ${Color.WHITE};
    border: 1px solid ${Color.PRIMARY_LIGHT};
    box-shadow: 0 8px 20px 0 rgba(2, 146, 211, 0.15);

    svg {
      stroke: ${Color.PRIMARY_LIGHT};
    }

    ${({ $isChecked }) =>
      $isChecked &&
      css`
        background-color: ${Color.PRIMARY};
        color: ${Color.WHITE};

        svg {
          stroke: ${Color.PRIMARY};
        }
      `}
  `,
  SvgImage: styled(SvgImage)<TSvgImageProps>`
    position: absolute;
    transform: translateX(-100%);
    color: ${Color.PRIMARY_LIGHT};
    scale: 1;
    top: ${({ $top }) => `${$top}px`};
    left: ${({ $left }) => `${$left}px`};
  `,
  OptionText: styled.p`
    padding: 22px 24px;
    border-radius: 16px;
    background-color: inherit;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: inherit;
  `,
  ProgramDescription: styled.p`
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: ${Color.DARK};
  `,
  ButtonContainer: styled.div`
    position: sticky;
    bottom: 0;
    z-index: 2;
    max-width: 285px;
    margin-top: 20px;
    padding-bottom: 30px;
    background: linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 40%
    );
  `,
  Container: styled(Container)`
    ${StyledAgeFlowContainer};
    padding: 0 16px;
  `,
}
