import React, { FC } from 'react'
import { Trans } from 'react-i18next'

import { ToggleV2 } from 'components/ToggleV2'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'

import { ISubscription } from 'models/subscriptions.model'

import { StyledUpsellSubscriptionItem as S } from './UpsellSubscriptionItem.styles'

type TProps = {
  subscription: ISubscription
  onChange: (value) => void
  isSelected: boolean
  value: string
}

export const UpsellSubscriptionItem: FC<TProps> = ({
  onChange,
  value,
  isSelected,
  subscription: {
    id,
    product,
    currency,
    mainPrices: { fullPrice },
  },
}) => (
  <S.Wrapper $isChecked={isSelected} key={id}>
    <S.ToggleContainer>
      <ToggleV2 onChange={onChange} value={value} isChecked={isSelected} />
      <S.PlanName $isChecked={isSelected}>
        <Trans i18nKey={`upsellV2.plans.${product}`} components={[<br />]} />
      </S.PlanName>
    </S.ToggleContainer>

    <S.PriceBlock $isChecked={isSelected}>
      <Trans
        i18nKey="upsellV2.price"
        values={{
          currency: CURRENCY_SYMBOLS[currency],
          price: fullPrice,
        }}
      />
    </S.PriceBlock>
  </S.Wrapper>
)
