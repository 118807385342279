import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  savePlanAdditionsAction,
  sendUserConfigAction,
} from 'root-redux/actions/user'
import {
  selectIsStayFitFlow,
  selectScreenId,
  selectScreenName,
} from 'root-redux/selects/common'
import { selectUUID, selectUserOnboardingEmail } from 'root-redux/selects/user'

import { useCreatePaypalSubscription } from 'hooks/useCreatePaypalSubscription'

import { createIntroOfferProductName } from 'helpers/createIntroOfferProductName'
import { createProductId } from 'helpers/createProductId'

import { PaymentMethod, PaymentSystem } from 'modules/purchase/constants'
import { logGeneralPurchaseEvents } from 'modules/purchase/helpers/logGeneralPurchaseEvents'
import { logStartedPayPalPayment } from 'modules/purchase/helpers/logStartedPayPalPayment'
import {
  selectCurrency,
  selectPayPalPlanId,
  selectSubscription,
  selectSubscriptionFullPrice,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialLookupKey,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import { ONBOARDING_GOAL_EVENT } from 'root-constants'

type TValues = {
  isButtonTouched: boolean
  handlePaymentApprove: (data, actions) => Promise<any>
  handlePaymentError: (error) => void
  handlePaymentCancel: () => void
  handleButtonClick: () => void
}

export const usePayPalButton = (): TValues => {
  const [isButtonTouched, setIsButtonTouched] = useState<boolean>(false)

  const currentPrice = useSelector(selectSubscriptionFullPrice)
  const currentUUID = useSelector(selectUUID)
  const currentPeriodName = useSelector(selectSubscriptionPeriodName)
  const currentPeriodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const paypalPlanId = useSelector(selectPayPalPlanId)
  const currency = useSelector(selectCurrency)
  const trialPriceId = useSelector(selectSubscriptionTrialLookupKey)
  const trialPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const screenName = useSelector(selectScreenName)
  const screenId = useSelector(selectScreenId)
  const email = useSelector(selectUserOnboardingEmail)
  const isStayFitGoal = useSelector(selectIsStayFitFlow)
  const selectedSubscription = useSelector(selectSubscription)

  const dispatch = useDispatch()

  const productName = useMemo(
    () =>
      trialPriceId
        ? createIntroOfferProductName({
            price: currentPrice,
            trialPrice,
            trialPeriodQuantity: trialPeriodDays,
            periodName: currentPeriodName,
            periodQuantity: currentPeriodQuantity,
          })
        : createProductId({
            periodName: currentPeriodName,
            periodQuantity: currentPeriodQuantity,
            price: currentPrice,
          }),
    [
      currentPeriodName,
      currentPeriodQuantity,
      currentPrice,
      trialPeriodDays,
      trialPrice,
      trialPriceId,
    ],
  )

  const {
    handleError,
    createSubscription,
    handleSuccess,
  } = useCreatePaypalSubscription()

  const handlePaymentApprove = useCallback(
    async (data) => {
      setIsButtonTouched(false)

      const response = await createSubscription(paypalPlanId)

      if (response.status) {
        dispatch(
          sendUserConfigAction(
            {
              payment_currency: currency,
              payment_method: PaymentMethod.PAYPAL,
              payment_system: PaymentMethod.PAYPAL,
              is_download_visited: false,
              subscription_price: `${currentPrice}`,
              subscription_duration: `${currentPeriodQuantity}${currentPeriodName}`,
              price_id: paypalPlanId,
              trial_price: `${trialPrice}`,
              trial_period: `${trialPeriodDays}`,
            },
            null,
          ),
        )
        dispatch(savePlanAdditionsAction())

        handleSuccess({
          productId: paypalPlanId,
          productName,
          price: currentPrice,
          trialPrice,
          trialPeriodDays,
          subscriptionId: data.subscriptionID,
          uuid: currentUUID,
          periodName: currentPeriodName,
          periodQuantity: currentPeriodQuantity,
          paymentMethod: PaymentMethod.PAYPAL,
          paymentSystem: PaymentSystem.PAYPAL,
          isTrialActive: !!trialPeriodDays,
          currency,
          screenName,
          screenId,
          email,
        })
      }
    },
    [
      paypalPlanId,
      currentPrice,
      screenName,
      screenId,
      currency,
      trialPeriodDays,
      createSubscription,
      dispatch,
      currentPeriodQuantity,
      currentPeriodName,
      trialPrice,
      handleSuccess,
      productName,
      currentUUID,
      email,
    ],
  )

  const handlePaymentError = useCallback(
    (error) => {
      setIsButtonTouched(false)

      handleError({
        productId: paypalPlanId,
        price: currentPrice,
        error,
        paymentMethod: PaymentMethod.PAYPAL,
        paymentSystem: PaymentSystem.PAYPAL,
        screenName,
        screenId,
        currency,
        isTrialActive: !!trialPeriodDays,
      })
    },
    [
      handleError,
      paypalPlanId,
      currentPrice,
      screenId,
      screenName,
      currency,
      trialPeriodDays,
    ],
  )

  const handlePaymentCancel = useCallback(() => {
    setIsButtonTouched(false)
    eventLogger.logPayPalPaymentPopupClose()
  }, [])

  const handleButtonClick = useCallback(() => {
    setIsButtonTouched(true)

    eventLogger.logPaymentMethodSelected(PaymentMethod.PAYPAL)

    logStartedPayPalPayment({
      productId: paypalPlanId,
      price: currentPrice,
      paymentMethod: PaymentMethod.PAYPAL,
      paymentSystem: PaymentSystem.PAYPAL,
      screenName,
      screenId,
      currency,
      isTrialActive: !!trialPeriodDays,
      goal: isStayFitGoal
        ? ONBOARDING_GOAL_EVENT.STAY_FIT
        : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
    })

    logGeneralPurchaseEvents({
      currency,
      email,
      periodQuantity: currentPeriodQuantity,
      periodName: currentPeriodName,
      fullPrice: currentPrice,
      selectedSubscription,
      uuid: currentUUID,
    })

    dispatch(
      sendUserConfigAction(
        {
          payment_currency: currency,
          subscription_price: `${currentPrice}`,
          subscription_duration: `${currentPeriodQuantity}${currentPeriodName}`,
          price_id: paypalPlanId,
          trial_price: `${trialPrice}`,
          trial_period: `${trialPeriodDays}`,
          product_name: productName,
          screen_name: screenName,
          period_name: `${currentPeriodName}`,
          period_quantity: currentPeriodQuantity,
          payment_method: PaymentMethod.PAYPAL,
          payment_system: PaymentMethod.PAYPAL,
          goal: isStayFitGoal
            ? ONBOARDING_GOAL_EVENT.STAY_FIT
            : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
        },
        null,
      ),
    )
  }, [
    paypalPlanId,
    currentPrice,
    screenName,
    screenId,
    currency,
    trialPeriodDays,
    isStayFitGoal,
    email,
    currentPeriodQuantity,
    currentPeriodName,
    selectedSubscription,
    currentUUID,
    dispatch,
    trialPrice,
    productName,
  ])

  return {
    isButtonTouched,
    handlePaymentApprove,
    handlePaymentError,
    handlePaymentCancel,
    handleButtonClick,
  }
}
