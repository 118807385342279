import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AnswerButton } from 'components/AnswerButton'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'
import { PageTitleDescription } from 'components/PageTitleDescription'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { ADULT_COHORT, Cohort } from 'root-constants'

import { StyledEnergy as S } from './Energy.styles'
import { OPTION_VALUES_ENERGY, QUESTION } from './constants'

export const Energy: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <S.Container $isAgeFlow={isAgeFlow}>
      <PageTitle
        marginBottom={10}
        isAgeFlow={isAgeFlow}
      >{t`onboarding.energy.question`}</PageTitle>
      <S.QuestionDescription>
        <PageTitleDescription>
          {isAgeFlow
            ? t`onboarding.energy.questionDescriptionAgeFlow`
            : t`onboarding.energy.questionDescription`}
        </PageTitleDescription>
      </S.QuestionDescription>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES_ENERGY.STABLE}
      >
        <AnswerButton
          isAgeFlow={isAgeFlow}
        >{t`onboarding.energy.stable`}</AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES_ENERGY.DRAGGING_BEFORE_MEALS}
      >
        <AnswerButton
          isAgeFlow={isAgeFlow}
        >{t`onboarding.energy.draggingBeforeMeals`}</AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES_ENERGY.WANT_SLEEP}
      >
        <AnswerButton
          isAgeFlow={isAgeFlow}
        >{t`onboarding.energy.wantSleep`}</AnswerButton>
      </Option>
    </S.Container>
  )
}
