import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Answer, answerTheme } from 'storybook-ui'

import { Container } from 'components/Container'
import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import sleepBg from 'assets/images/flow-belly/sleep.png'

import { StyledSleep as S } from './Sleep.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const Sleep: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <Container isLarge>
      <PageTitle marginBottom={24}>
        <Trans i18nKey="onboarding.sleep.title" />
      </PageTitle>

      <S.Img src={sleepBg} alt="How much sleep do you usually get?" />

      <S.OptionsContainer>
        {OPTION_VALUES.map(({ value, title }) => (
          <Answer
            {...optionProps}
            disabled={isAnswersDisabled}
            theme={answerTheme.DANCEBIT}
            minWidth="163px"
            maxWidth="163px"
            borderRadius="16px"
            key={value}
            value={value}
          >
            {t(title)}
          </Answer>
        ))}
      </S.OptionsContainer>
    </Container>
  )
}
