import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SvgImage } from 'components/SvgImage'

import { selectIsStayFitFlow } from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import starSvg from 'assets/images/sprite/star.svg'

import { CUSTOMER_REVIEWS, MAIN_GOALS } from 'root-constants'

import { StyledReviews as S } from './Reviews.styles'

type TReview = {
  author: string
  review: string
}

type TProps = {
  reviews?: TReview[]
  className?: string
}

export const Reviews: React.FC<TProps> = ({
  reviews = CUSTOMER_REVIEWS,
  className = '',
}) => {
  const { t } = useTranslation()
  const { customerReviewsTitle, customerReviews } = useDynamicPaywallConfig()
  const isStayFitFlow = useSelector(selectIsStayFitFlow)

  return (
    <S.Wrapper className={className}>
      <S.Header>
        <S.Title>
          {customerReviewsTitle || (
            <Trans i18nKey="purchaseWithoutIntro.reviewsTitle" />
          )}
        </S.Title>
      </S.Header>

      {(customerReviews.length ? customerReviews : reviews).map(
        ({ author, review }) => (
          <S.Comment key={author}>
            <S.Author>{t(author)}</S.Author>
            <S.Rating>
              <SvgImage svg={starSvg} />
              <SvgImage svg={starSvg} />
              <SvgImage svg={starSvg} />
              <SvgImage svg={starSvg} />
              <SvgImage svg={starSvg} />
            </S.Rating>
            <S.Text>
              {t(review, {
                context: isStayFitFlow
                  ? MAIN_GOALS.STAY_FIT
                  : MAIN_GOALS.LOSE_WEIGHT,
              })}
            </S.Text>
          </S.Comment>
        ),
      )}
    </S.Wrapper>
  )
}
