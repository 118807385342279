import styled from 'styled-components'

import { Button } from 'components/Button'
import { Footer } from 'components/Footer'

import introDesktopBg from 'assets/images/newYearOnboarding/intro-ny-bg-desktop.jpg'
import introBg from 'assets/images/newYearOnboarding/intro-ny-bg-mobile.jpg'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledIntroNewYear = {
  Root: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    min-height: var(--full-height);
    background-image: url(${introBg});
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      background-image: url(${introDesktopBg});
    }
  `,
  Container: styled.div`
    padding: 0 30px;
  `,
  Title: styled.h2`
    padding-bottom: 8px;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    color: ${Color.WHITE};
    text-align: center;
  `,
  Subtitle: styled.h3`
    padding-bottom: 30px;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: ${Color.WHITE};
    text-align: center;
  `,
  Button: styled(Button)`
    margin-bottom: 16px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      margin-bottom: 64px;
    }
  `,
  Footer: styled(Footer)`
    padding-bottom: 16px;
    color: #e7eaf0;

    li a {
      color: #e7eaf0;
      text-decoration-color: #e7eaf0;
    }

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      padding-bottom: 64px;
    }
  `,
}
