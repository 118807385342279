import dancingStyleIntroFemaleMxAgeFlow from 'assets/images/dancing-style-intro-female-mx-age-flow.png'
import dancingStyleIntroFemaleMxAgeFlowWebp from 'assets/images/dancing-style-intro-female-mx-age-flow.webp'
import dancingStyleIntroMaleMxAgeFlow from 'assets/images/dancing-style-intro-male-mx-age-flow.png'
import dancingStyleIntroMaleMxAgeFlowWebp from 'assets/images/dancing-style-intro-male-mx-age-flow.webp'

import { Gender } from 'root-constants'

export const QUESTION =
  'We have different dance styles, rhythms, and intensity levels. You can easily adjust it by yourself!'

export const DANCING_STYLE_INTRO_IMAGE_MX_AGE = {
  [Gender.MALE]: {
    PNG: dancingStyleIntroMaleMxAgeFlow,
    WEBP: dancingStyleIntroMaleMxAgeFlowWebp,
  },
  [Gender.FEMALE]: {
    PNG: dancingStyleIntroFemaleMxAgeFlow,
    WEBP: dancingStyleIntroFemaleMxAgeFlowWebp,
  },
}
