import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Swiper, { Autoplay, Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import {
  selectAnswers,
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import {
  SLIDES_FEMALE,
  SLIDES_MALE,
} from 'modules/purchase/components/PhotoResult/constants'

import { PageId } from 'page-constants'
import { Cohort, Gender } from 'root-constants'

import { StyledPhotoResult as S } from './PhotoResult.styles'

export const PhotoResult: React.FC = () => {
  Swiper.use([Pagination, Autoplay])

  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort

  const isMale = useMemo(() => userAnswers?.[PageId.GENDER] === Gender.MALE, [
    userAnswers,
  ])

  const isClearCohort = useMemo(
    () =>
      cohortToUse === Cohort.DANCEBIT_CLEAR_FLOW ||
      cohortToUse === Cohort.DB_CLEAR_INTRO_FAST,
    [cohortToUse],
  )

  const slideList = useMemo(() => (isMale ? SLIDES_MALE : SLIDES_FEMALE), [
    isMale,
  ])

  return (
    <S.ResultContainer>
      <S.ResultTitle>
        <Trans
          i18nKey="purchase1.photoResult.title"
          components={[<br />, <strong />]}
        />
      </S.ResultTitle>
      <S.CarouselContainer>
        <Carousel
          spaceBetween={20}
          slidesPerView={1}
          loop
          pagination={{ clickable: true }}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
        >
          {slideList.map(({ id, img, imgWebp, date }) => (
            <SwiperSlide key={id}>
              <S.Cards>
                <S.Card>
                  <S.ImageContainer>
                    <picture>
                      <source srcSet={imgWebp.before} type="image/webp" />
                      <S.Image src={img.before} alt="before" />
                    </picture>
                  </S.ImageContainer>
                  <S.LabelBefore>{t(date.before)}</S.LabelBefore>
                </S.Card>
                <S.Card>
                  <S.ImageContainer>
                    <picture>
                      <source srcSet={imgWebp.after} type="image/webp" />
                      <S.Image src={img.after} alt="after" />
                    </picture>
                  </S.ImageContainer>
                  <S.LabelAfter>{t(date.after)}</S.LabelAfter>
                </S.Card>
              </S.Cards>
            </SwiperSlide>
          ))}
        </Carousel>
        {isClearCohort && (
          <S.Footnote>{t`purchase1.photoResult.footnote`}</S.Footnote>
        )}
      </S.CarouselContainer>
    </S.ResultContainer>
  )
}
