import { paymentApi } from 'api'

import { startFetching, stopFetching } from 'root-redux/actions/common'
import {
  getUserStatusAction,
  savePlanAdditionsAction,
  sendUserConfigAction,
} from 'root-redux/actions/user'
import { selectIsStayFitFlow } from 'root-redux/selects/common'
import {
  selectUUID,
  selectUserOnboardingEmail,
  selectUserPaymentMethod,
  selectUserPaymentSystem,
} from 'root-redux/selects/user'

import { PaymentSystem } from 'modules/purchase/constants'
import { getStripePurchaseFailedEventParams } from 'modules/purchase/helpers/getStripePurchaseFailedEventParams'
import { getStripePurchaseStartedEventParams } from 'modules/purchase/helpers/getStripePurchaseStartedEventParams'
import { getStripePurchaseSuccessEventParams } from 'modules/purchase/helpers/getStripePurchaseSuccessEventParams'
import { logGeneralPurchaseEvents } from 'modules/purchase/helpers/logGeneralPurchaseEvents'
import { logSuccessfulPayment } from 'modules/purchase/helpers/rootHelpers'
import {
  selectCurrency,
  selectProductId,
  selectSubscription,
  selectSubscriptionFullPrice,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionPlanId,
  selectSubscriptionTrialPeriodDays,
} from 'modules/purchase/redux/selects/common'

import { IAppState, TAppDispatchThunk } from 'models/store.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import { ONBOARDING_GOAL_EVENT } from 'root-constants'

const MODULE_NAME = 'UPSELL'

export const MAKE_UPSELL = `${MODULE_NAME}/MAKE_UPSELL`

export const makeUpsellAction = (): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
): Promise<void> => {
  const state = getState()
  const { search } = window.location
  const uuid = selectUUID(state)
  const planId = selectSubscriptionPlanId(state)
  const currentPrice = selectSubscriptionFullPrice(state)
  const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
  const productId = selectProductId(state)
  const paymentMethod = selectUserPaymentMethod(state)
  const isStayFitGoal = selectIsStayFitFlow(state)
  const paymentSystem = selectUserPaymentSystem(state)
  const currency = selectCurrency(state)
  const email = selectUserOnboardingEmail(state)
  const selectedSubscription = selectSubscription(state)
  const periodName = selectSubscriptionPeriodName(state)
  const periodQuantity = selectSubscriptionPeriodQuantity(state)

  dispatch(startFetching(MAKE_UPSELL))

  const commonPurchaseStartedParams = getStripePurchaseStartedEventParams(state)
  const commonPurchaseSuccessParams = getStripePurchaseSuccessEventParams(state)
  const commonPurchaseFailedParams = getStripePurchaseFailedEventParams(state)

  eventLogger.logPurchaseStarted({
    ...commonPurchaseStartedParams,
    paymentMethod,
    paymentSystem,
    goal: isStayFitGoal
      ? ONBOARDING_GOAL_EVENT.STAY_FIT
      : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
  })

  logGeneralPurchaseEvents({
    currency,
    email,
    periodQuantity,
    periodName,
    fullPrice: currentPrice,
    selectedSubscription,
    uuid,
  })

  try {
    const upsellResponse =
      paymentSystem === PaymentSystem.PRIMER
        ? await paymentApi.makePrimerUpsell({
            uuid,
            planId,
            productId,
          })
        : await paymentApi.makeUpsell({
            uuid,
            planId,
            productId,
          })

    if (upsellResponse.success) {
      logSuccessfulPayment({
        ...commonPurchaseSuccessParams,
        paymentMethod,
        paymentSystem,
        productName: `${commonPurchaseSuccessParams.productName}_upsell`,
        trialPeriodDays,
        productId: upsellResponse.data?.subscription?.product_id,
        price: currentPrice,
        subscriptionId: upsellResponse.data?.subscription?.subscription_id,
        goal: isStayFitGoal
          ? ONBOARDING_GOAL_EVENT.STAY_FIT
          : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
        isUpsell: true,
      })

      dispatch(
        sendUserConfigAction({
          upsell_product_name: productId,
          upsell_product_price: currentPrice,
        }),
      )
      dispatch(savePlanAdditionsAction())
      dispatch(getUserStatusAction(uuid))
      dispatch(stopFetching(MAKE_UPSELL))
      return
    }

    eventLogger.logPurchaseFailed({
      ...commonPurchaseFailedParams,
      paymentSystem,
      paymentMethod,
      goal: isStayFitGoal
        ? ONBOARDING_GOAL_EVENT.STAY_FIT
        : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
      error: { type: upsellResponse.data },
    })

    goTo({
      pathname: PageId.LOGIN,
      search,
    })
    dispatch(getUserStatusAction(uuid))
    dispatch(stopFetching(MAKE_UPSELL))
  } catch (error: any) {
    goTo({
      pathname: PageId.LOGIN,
      search,
    })
    dispatch(getUserStatusAction(uuid))
    dispatch(stopFetching(MAKE_UPSELL))
  }
}
