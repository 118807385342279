import React, { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { FeaturesReady, useFeatureIsOn } from '@growthbook/growthbook-react'

import { OptionType } from 'components/Option'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { Sale } from 'components/Sale'
import { SvgImage } from 'components/SvgImage'
import { TermsOfUseLink } from 'components/TermsOfUseLink'
import { Ticker } from 'components/Ticker'

import { sendFacebookParamsAction } from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'
import { useStartSession } from 'hooks/useStartSession'
import { useTitleFromUrl } from 'hooks/useTitleFromUrl'

import { selectDynamicDiscount } from 'modules/purchase/redux/selects/common'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import arrowSvg from 'assets/images/sprite/arrow.svg'

import {
  Cohort,
  DynamicDiscountType,
  GROWTHBOOK_EXPERIMENT,
  PagesSource,
} from 'root-constants'

import { StyledWelcome as S } from './Welcome.styles'
import {
  DEFAULT_OPTIONS,
  DEFAULT_OPTIONS_MX,
  OPTION_VALUES,
  QUESTION,
} from './constants'

export const Welcome: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const uuid = useSelector(selectUUID)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  const hasNewIllustrations = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_599)

  const cohortToUse = parentCohort || cohort

  const [answer, setAnswer] = useState<string>('')

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const { title, subtitle, stickerText } = useDynamicOBConfig()
  const { headline, subtext } = useTitleFromUrl()

  useStartSession()

  const isSaleFlow = useMemo(
    () => cohort === Cohort.DB_BELLY_SALE || cohort === Cohort.DB_ADULT_SALE,
    [cohort],
  )

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const { titleToShow, subtitleToShow } = useMemo(() => {
    const defaultSubtitle = (
      <Trans
        i18nKey="onboarding.welcome.subtitle"
        components={{
          strong: <strong />,
          styled: <p />,
        }}
        values={{ context: cohortToUse }}
      />
    )

    const defaultTitle = t('onboarding.welcome.title', {
      context: cohortToUse,
    })

    return {
      titleToShow: headline || title || defaultTitle,
      subtitleToShow: subtext || subtitle || defaultSubtitle,
    }
  }, [cohortToUse, headline, subtext, subtitle, t, title])

  const cards = useMemo(() => {
    if (hasNewIllustrations) return DEFAULT_OPTIONS_MX

    if (cohort === Cohort.DB_ADULT_18) return OPTION_VALUES[Cohort.DB_ADULT_18]

    return OPTION_VALUES[cohortToUse as Cohort] || DEFAULT_OPTIONS
  }, [cohort, cohortToUse, hasNewIllustrations])

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        dispatch(sendFacebookParamsAction())
        window.fbq('track', 'CustomizeProduct', {}, { eventID: uuid })
        googleAnalyticsLogger.logFirstPageCompleted()
        eventLogger.logFirstPageCompleted({
          question: QUESTION,
          answers: value,
        })
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
        setAnswer(value)
      },
    }),
    [
      pageId,
      dispatch,
      uuid,
      handleChange,
      handleContinue,
      setIsAnswersDisabled,
    ],
  )

  return (
    <S.Root>
      {dynamicDiscount?.type === DynamicDiscountType.STATIC && <Sale />}

      {isSaleFlow && dynamicDiscount?.type !== DynamicDiscountType.STATIC && (
        <Ticker />
      )}

      {stickerText && (
        <S.Sticker>
          <p>{stickerText}</p>
        </S.Sticker>
      )}
      <S.Container>
        <S.Title $hasSticker={!!stickerText}>{titleToShow}</S.Title>
        <S.Subtitle>{subtitleToShow}</S.Subtitle>
        <FeaturesReady>
          <S.Actions>
            {cards.map(({ value, img, from, to }) => (
              <S.StyledOption
                {...optionProps}
                key={value}
                value={value}
                $isChecked={answer === value}
                disabled={isAnswersDisabled}
              >
                <S.Image $isChecked={answer === value} src={img} alt={value} />
                <S.Action $isChecked={answer === value}>
                  <Trans
                    i18nKey="onboarding.welcome.optionTitle"
                    values={{
                      context: from,
                    }}
                    components={{
                      age: to
                        ? t('onboarding.welcome.age', {
                            from,
                            to,
                          })
                        : from,
                      icon: <SvgImage svg={arrowSvg} height={10} width={16} />,
                    }}
                  />
                </S.Action>
              </S.StyledOption>
            ))}
          </S.Actions>
        </FeaturesReady>
        <S.Terms>
          <p>{t('footer.disclaimer')}</p>
          <TermsOfUseLink source={PagesSource.LANDING} />
          <S.Separator />
          <PrivacyPolicyLink source={PagesSource.LANDING} />
          <p>
            {t('footer.allRightsReserved', {
              year: new Date().getFullYear(),
            })}
          </p>
        </S.Terms>
      </S.Container>
    </S.Root>
  )
}
