import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import {
  PLAN_NAME_BACKGROUND,
  PricePadding,
} from 'modules/purchase/components/SubscriptionItemNewYear/constants'
import { CURRENCY_SYMBOLS, GeneralProduct } from 'modules/purchase/constants'

import { ISubscription } from 'models/subscriptions.model'

import { Cohort } from 'root-constants'

import { StyledSubscriptionItemNewYear as S } from './SubscriptionItemNewYear.styles'

type TProps = {
  subscription: ISubscription
  isSelected: boolean
}

export const SubscriptionItemNewYear: React.FC<TProps> = ({
  subscription: {
    id,
    product,
    mainPrices: { periodQuantity, oldPrices, weekly },
    currency,
  },
  isSelected,
}) => {
  const { t } = useTranslation()
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const discountPercentage = useMemo(
    () => oldPrices.beforeCustomDiscount?.percentOfDiscount,
    [oldPrices],
  )

  const oldPrice = useMemo(() => oldPrices.beforeCustomDiscount?.weekly, [
    oldPrices,
  ])

  const hasMealPlan = useMemo(() => product === GeneralProduct.ONE_YEAR, [
    product,
  ])

  return (
    <S.Wrapper key={id} $isSelected={isSelected}>
      <S.PlanPeriod>
        {t('purchaseNewYear.subscription.planPeriod', { periodQuantity })}
      </S.PlanPeriod>

      {!hasMealPlan && (
        <S.OldPrice>
          {t('purchaseNewYear.subscription.oldPrice', {
            oldPrice,
            currency: CURRENCY_SYMBOLS[currency],
          })}
          {t(`purchaseNewYear.subscription.week`)}
        </S.OldPrice>
      )}

      <S.CurrentPrice
        $paddingBottom={hasMealPlan ? PricePadding.HIGH : PricePadding.LOW}
      >
        {t('purchaseNewYear.subscription.currentPrice', {
          price: weekly,
          currency: CURRENCY_SYMBOLS[currency],
        })}
        {t(`purchaseNewYear.subscription.week`)}
      </S.CurrentPrice>

      <S.PlanName
        $backgroundColor={
          PLAN_NAME_BACKGROUND[(parentCohort || cohort) as Cohort][product]
        }
      >
        {hasMealPlan
          ? t`purchaseNewYear.subscription.mealPlan`
          : t('purchaseNewYear.subscription.discount', {
              discountAmount: discountPercentage,
            })}
      </S.PlanName>

      {hasMealPlan && (
        <S.Disclaimer>{t`purchaseNewYear.subscription.noRestrictions`}</S.Disclaimer>
      )}
    </S.Wrapper>
  )
}
