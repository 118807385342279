import styled from 'styled-components'

import { Container } from 'components/Container'

import imageBg from 'assets/images/flow-belly/weight-fluctuations-bg.png'
import imageBgLegs from 'assets/images/flow-belly/weight-fluctuations-legs-bg.png'

export const WeightFluctuationsStyles = {
  Container: styled(Container)<{ $isLegsFlow: boolean }>`
    min-height: 560px;
    background-image: url(${({ $isLegsFlow }) =>
      $isLegsFlow ? imageBgLegs : imageBg});
    background-size: 375px 500px;
    background-repeat: no-repeat;
    background-position: bottom;
  `,
}
