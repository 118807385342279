import { createProductId } from 'helpers/createProductId'

import { ISubscription } from 'models/subscriptions.model'

import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

export const logGeneralPurchaseEvents = ({
  currency,
  email,
  periodQuantity,
  periodName,
  fullPrice,
  selectedSubscription,
  uuid,
}): void => {
  window.fbq('track', 'AddToCart', {}, { eventID: uuid })
  window.pintrk &&
    window.pintrk('track', 'addtocart', {
      value: fullPrice,
      currency: currency.toUpperCase(),
      line_items: [
        {
          product_category: 'wellness',
          product_name: createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
          product_id: createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
          product_price: fullPrice,
        },
      ],
    })

  window.ttq.identify({ email })
  window.ttq.track('AddToCart', { event_id: uuid })
  window.snaptr('track', 'ADD_CART')
  window.obApi && window.obApi('track', 'Add To Cart')
  window._tfa &&
    window._tfa.push({
      notify: 'event',
      name: 'add_to_cart',
    })
  window.rdt && window.rdt('track', 'AddToCart', { email, externalId: uuid })
  googleAnalyticsLogger.logAddingToCart(selectedSubscription as ISubscription)
}
