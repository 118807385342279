import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AnswerButton } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { NavigationButton } from 'components/NavigationButton'
import { Option, OptionType } from 'components/Option'
import { SvgImage } from 'components/SvgImage'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import emoji from 'assets/images/sprite/christmas-tree.svg'

import { PageId } from 'page-constants'

import { StyledMainGoalInNewYear as S } from './MainGoalInNewYear.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const MainGoalInNewYear: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId: PageId.MAIN_GOAL,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([...answers, value])
        } else {
          setAnswers(answers.filter((item) => item !== value))
        }
      },
    }),
    [pageId, answers],
  )

  const handleClick = useCallback(() => handleContinue(answers), [
    handleContinue,
    answers,
  ])

  return (
    <Container>
      <S.Title>
        <Trans
          i18nKey="onboarding.mainGoalNewYear.question"
          components={{ emoji: <SvgImage svg={emoji} width={24} /> }}
        />
      </S.Title>

      <Option
        {...optionProps}
        value={OPTION_VALUES.REACHING_GOAL_WEIGHT}
        checked={answers.includes(OPTION_VALUES.REACHING_GOAL_WEIGHT)}
      >
        <AnswerButton
          withCheckbox
        >{t`onboarding.mainGoalNewYear.reachingGoalWeight`}</AnswerButton>
      </Option>

      <Option
        {...optionProps}
        value={OPTION_VALUES.GETTING_SLIM_OR_TONED}
        checked={answers.includes(OPTION_VALUES.GETTING_SLIM_OR_TONED)}
      >
        <AnswerButton
          withCheckbox
        >{t`onboarding.mainGoalNewYear.gettingSlimOrToned`}</AnswerButton>
      </Option>

      <Option
        {...optionProps}
        value={OPTION_VALUES.IMPROVE_HEALTH}
        checked={answers.includes(OPTION_VALUES.IMPROVE_HEALTH)}
      >
        <AnswerButton
          withCheckbox
        >{t`onboarding.mainGoalNewYear.improveHealth`}</AnswerButton>
      </Option>

      <Option
        {...optionProps}
        value={OPTION_VALUES.GETTING_FLEXIBLE}
        checked={answers.includes(OPTION_VALUES.GETTING_FLEXIBLE)}
      >
        <AnswerButton
          withCheckbox
        >{t`onboarding.mainGoalNewYear.gettingFlexible`}</AnswerButton>
      </Option>

      <Option
        {...optionProps}
        value={OPTION_VALUES.MANAGING_ANXIETY}
        checked={answers.includes(OPTION_VALUES.MANAGING_ANXIETY)}
      >
        <AnswerButton
          withCheckbox
        >{t`onboarding.mainGoalNewYear.managingAnxiety`}</AnswerButton>
      </Option>

      <Option
        {...optionProps}
        withoutMargin
        value={OPTION_VALUES.IMPROVE_DANCING}
        checked={answers.includes(OPTION_VALUES.IMPROVE_DANCING)}
      >
        <AnswerButton
          withCheckbox
        >{t`onboarding.mainGoalNewYear.improveDancing`}</AnswerButton>
      </Option>

      <NavigationButton
        type="button"
        disabled={!answers.length}
        onClick={handleClick}
      >
        {t`actions.continue`}
      </NavigationButton>
    </Container>
  )
}
