import { AnimationSegment } from 'lottie-web'

import { GeneralProduct } from 'modules/purchase/constants'

import { AvailableAttempt, Cohort } from 'root-constants'

export const AUDIO_START_POINT = 0
export const GAME_STEP = 1
export const CONFETTI_ANIMATION_SEGMENTS: AnimationSegment = [0, 61]

export const TITLE_PATH = {
  [Cohort.LUCKY_NY_2]: {
    [AvailableAttempt.NO_ATTEMPTS]:
      'purchaseNewYear.fortuneWheel.title.winThirtyPercentDiscount',
    [AvailableAttempt.ONE_ATTEMPT]:
      'purchaseNewYear.fortuneWheel.title.noPrize',
    [AvailableAttempt.TWO_ATTEMPTS]:
      'purchaseNewYear.fortuneWheel.title.winMealPlan',
    [AvailableAttempt.THREE_ATTEMPTS]:
      'purchaseNewYear.fortuneWheel.title.tryLuck',
  },
  [Cohort.LUCKY_NY_3]: {
    [AvailableAttempt.NO_ATTEMPTS]:
      'purchaseNewYear.fortuneWheel.title.winFiftyPercentDiscount',
    [AvailableAttempt.ONE_ATTEMPT]:
      'purchaseNewYear.fortuneWheel.title.winThirtyPercentDiscount',
    [AvailableAttempt.TWO_ATTEMPTS]:
      'purchaseNewYear.fortuneWheel.title.winMealPlan',
    [AvailableAttempt.THREE_ATTEMPTS]:
      'purchaseNewYear.fortuneWheel.title.tryLuck',
  },
}

export const ANALYTIC_PRODUCTS = {
  [Cohort.LUCKY_NY_2]: {
    [AvailableAttempt.THREE_ATTEMPTS]: GeneralProduct.ONE_YEAR,
    [AvailableAttempt.ONE_ATTEMPT]: GeneralProduct.SIX_MONTHS,
  },
  [Cohort.LUCKY_NY_3]: {
    [AvailableAttempt.THREE_ATTEMPTS]: GeneralProduct.ONE_YEAR,
    [AvailableAttempt.TWO_ATTEMPTS]: GeneralProduct.SIX_MONTHS,
    [AvailableAttempt.ONE_ATTEMPT]: GeneralProduct.THREE_MONTHS,
  },
}

export const CONFETTI_AVAILABILITY = {
  [Cohort.LUCKY_NY_2]: {
    [AvailableAttempt.ONE_ATTEMPT]: true,
    [AvailableAttempt.TWO_ATTEMPTS]: false,
    [AvailableAttempt.THREE_ATTEMPTS]: true,
  },
  [Cohort.LUCKY_NY_3]: {
    [AvailableAttempt.ONE_ATTEMPT]: true,
    [AvailableAttempt.TWO_ATTEMPTS]: true,
    [AvailableAttempt.THREE_ATTEMPTS]: true,
  },
}

export const WHEEL_ANIMATION_SEGMENTS = {
  [AvailableAttempt.THREE_ATTEMPTS]: [0, 120],
  [AvailableAttempt.TWO_ATTEMPTS]: [120, 240],
  [AvailableAttempt.ONE_ATTEMPT]: [240, 360],
}

export const WHEEL_ANIMATION_START_POINTS = {
  [AvailableAttempt.THREE_ATTEMPTS]: 0,
  [AvailableAttempt.TWO_ATTEMPTS]: 120,
  [AvailableAttempt.ONE_ATTEMPT]: 240,
  [AvailableAttempt.NO_ATTEMPTS]: 360,
}

export const ATTEMPT_NUMBERS = {
  [AvailableAttempt.THREE_ATTEMPTS]: 1,
  [AvailableAttempt.TWO_ATTEMPTS]: 2,
  [AvailableAttempt.ONE_ATTEMPT]: 3,
}
