import styled from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import planHighlightsBg from 'assets/images/newYearOnboarding/plan-highlights-bg.png'

import { Color } from 'root-constants'

export const StyledPlanHighlightsNewYear = {
  Wrapper: styled.div`
    margin-bottom: 24px;
    padding: 24px 8px;
    border-radius: 25px;
    background: url(${planHighlightsBg}) center center no-repeat;
    background-size: cover;
  `,
  Title: styled.h2`
    padding-bottom: 16px;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  `,
  MealPlan: styled.div`
    margin-bottom: 30px;
    padding: 4px 2px 2px;
    background: linear-gradient(90.55deg, #92e67d 0%, #1cb9ff 100%),
      linear-gradient(87.64deg, #40b123 -2.02%, #c8ffbb 96.04%);
    border-radius: 20px;
  `,
  Bonus: styled.h3`
    padding-bottom: 6px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: ${Color.WHITE};
    text-align: center;
  `,
  MealPlanCard: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background: #f8f8f8;
    border-radius: 20px;
  `,
  MealPlanImage: styled.img`
    max-width: 84px;
    margin-right: 16px;
  `,
  TextContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  `,
  MealPlanTitle: styled.h3`
    padding-bottom: 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
  `,
  MealPlanText: styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${Color.DISABLED};
  `,
  List: styled.ul`
    padding: 0 12px;
  `,
  ListItem: styled.li`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 40px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  `,
  CheckIcon: styled(SvgImage)`
    width: 24px;
    height: 24px;
    margin-right: 14px;
  `,
}
