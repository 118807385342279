import { Cohort } from 'root-constants'

import { PageId } from './pages'

export const PROGRESSBAR_STEPS = [
  [{ id: PageId.LOGIN, text: 'commonComponents.buyPlan' }],
  [{ id: PageId.DOWNLOAD, text: 'commonComponents.createAccount' }],
]

export const CHAT_BOT_PROGRESSBAR_STEPS = [
  [{ id: PageId.LOGIN, text: 'commonComponents.buyPlan' }],
  [{ id: PageId.DOWNLOAD, text: 'commonComponents.createAccount' }],
]

export const UPSELL_PROGRESSBAR_STEPS = [
  [{ id: PageId.UPSELL, text: 'commonComponents.buyPlan' }],
  [{ id: PageId.LOGIN, text: 'commonComponents.premiumBundle' }],
  [{ id: PageId.DOWNLOAD, text: 'commonComponents.createAccount' }],
]

export const PROGRESS_BAR_STEPS_MAP = {
  [Cohort.DANCEBIT_FLOW_1]: PROGRESSBAR_STEPS,
  [Cohort.DANCEBIT_EMAIL_1]: PROGRESSBAR_STEPS,
  [Cohort.DANCEBIT_EMAIL_2]: PROGRESSBAR_STEPS,
  [Cohort.DANCEBIT_EMAIL_3]: PROGRESSBAR_STEPS,
  [Cohort.DANCEBIT_20_30_40]: PROGRESSBAR_STEPS,
  [Cohort.DANCEBIT_CLEAR_FLOW]: PROGRESSBAR_STEPS,
  [Cohort.DB_INTRO]: PROGRESSBAR_STEPS,
  [Cohort.DB_INTRO_FAST]: PROGRESSBAR_STEPS,
  [Cohort.DB_INTRO_2]: PROGRESSBAR_STEPS,
  [Cohort.DB_INTRO_DOP]: PROGRESSBAR_STEPS,
  [Cohort.DB_FLOW_3D]: PROGRESSBAR_STEPS,
  [Cohort.DB_FLOW_7D]: PROGRESSBAR_STEPS,
  [Cohort.DANCEBIT_SHORT]: CHAT_BOT_PROGRESSBAR_STEPS,
  [Cohort.LUCKY_NY_2]: PROGRESSBAR_STEPS,
  [Cohort.LUCKY_NY_3]: PROGRESSBAR_STEPS,
  [Cohort.DANCEBIT_CHAT_UPGRADE_6]: CHAT_BOT_PROGRESSBAR_STEPS,
  [Cohort.DANCEBIT_CHAT_UPGRADE_9]: CHAT_BOT_PROGRESSBAR_STEPS,
  [Cohort.DANCEBIT_CHAT_UPGRADE_12]: CHAT_BOT_PROGRESSBAR_STEPS,
  [Cohort.DANCEBIT_CHAT_UPGRADE_6_PDF]: CHAT_BOT_PROGRESSBAR_STEPS,
  [Cohort.DANCEBIT_CHAT_UPGRADE_9_PDF]: CHAT_BOT_PROGRESSBAR_STEPS,
  [Cohort.DANCEBIT_CHAT_UPGRADE_12_PDF]: CHAT_BOT_PROGRESSBAR_STEPS,
  [Cohort.DANCEBIT_CHAT_UPGRADE_9_MEAL]: CHAT_BOT_PROGRESSBAR_STEPS,
  [Cohort.DANCEBIT_CHAT_UPGRADE_12_MEAL]: CHAT_BOT_PROGRESSBAR_STEPS,
  [Cohort.DB_FLOW_2]: PROGRESSBAR_STEPS,
  [Cohort.DB_CLEAR_INTRO_FAST]: PROGRESSBAR_STEPS,
  [Cohort.DB_INTRO_FAST_18]: PROGRESSBAR_STEPS,
  [Cohort.DB_INTRO_FAST_40]: PROGRESSBAR_STEPS,
  [Cohort.DB_ADULT]: PROGRESSBAR_STEPS,
  [Cohort.DB_ADULT_18]: PROGRESSBAR_STEPS,
  [Cohort.DB_BELLY]: PROGRESSBAR_STEPS,
  [Cohort.DB_LEGS]: PROGRESSBAR_STEPS,
  [Cohort.DB_ADULT_2]: PROGRESSBAR_STEPS,
  [Cohort.DB_ADULT_VIDEO]: PROGRESSBAR_STEPS,
  [Cohort.DB_ADULT_WEEK]: PROGRESSBAR_STEPS,
  [Cohort.GLORIA_INTRO_FAST]: PROGRESSBAR_STEPS,
  [Cohort.YARINAS]: PROGRESSBAR_STEPS,
  [Cohort.EMAIL_INTRO_FAST]: PROGRESSBAR_STEPS,
  [Cohort.CANCEL_INTRO_FAST]: PROGRESSBAR_STEPS,
  [Cohort.COHORT_2024]: PROGRESSBAR_STEPS,
  [Cohort.SOMATIC_GOAL]: PROGRESSBAR_STEPS,
  [Cohort.SOMATIC_AGE]: PROGRESSBAR_STEPS,
  [Cohort.DB_REF]: PROGRESSBAR_STEPS,
}
