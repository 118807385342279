import styled from 'styled-components'

import { SvgImage } from 'components/SvgImage'

type TTextProps = {
  $isReady: boolean
}

export const StyledPreparePlanNewYear = {
  Title: styled.h2`
    padding: 30px 0 48px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  `,
  ProgressContainer: styled.div`
    margin-bottom: 50px;
  `,
  Text: styled.p<TTextProps>`
    position: relative;
    margin-bottom: 10px;
    padding: 0 60px;
    font-size: 16px;
    line-height: 22px;
    transition: opacity 0.2s;
    opacity: ${({ $isReady }) => ($isReady ? 1 : 0.2)};

    svg {
      vertical-align: middle;
    }
  `,
  CheckIcon: styled(SvgImage)`
    position: absolute;
    top: 50%;
    left: 25px;
    transform: translateY(-50%);
  `,
}
