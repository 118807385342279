import React from 'react'
import { useTranslation } from 'react-i18next'

import { TermsOfUseLink } from 'components/TermsOfUseLink'

import badge from 'assets/images/sprite/badge-ny.svg'

import { PagesSource } from 'root-constants'

import { StyledMoneyBackNewYear as S } from './MoneyBackNewYear.styles'

export const MoneyBackNewYear: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>{t`purchase1.moneyBackGuarantee.title`}</S.Title>
        <S.Text>
          {t`purchase1.moneyBackGuarantee.text`}{' '}
          <TermsOfUseLink source={PagesSource.PAYWALL} />
        </S.Text>
      </S.Content>
      <S.Badge svg={badge} />
    </S.Wrapper>
  )
}
