import React, { FC, useCallback, useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import lottie from 'lottie-web/build/player/lottie_light'

import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { PageTitle } from 'components/PageTitle'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import socialProofAnimation from 'assets/animation/SocialProof.json'

import { StyledDesignNewLife as S } from './DesignNewLife.styles'
import { QUESTION } from './constants'

export const DesignNewLife: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { t } = useTranslation()

  const animationRef = useRef<HTMLDivElement>(null)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  useEffect(() => {
    if (animationRef.current) {
      lottie.loadAnimation({
        container: animationRef.current,
        animationData: socialProofAnimation,
        loop: true,
        name: 'arrowAnimation',
      })
    }

    return () => lottie.destroy('arrowAnimation')
  }, [])

  const handleClick = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])

  return (
    <>
      <ContainerBeforePinnedButton>
        <S.Container isLarge>
          <PageTitle marginBottom={8}>
            <Trans i18nKey="onboarding.upgradeLife.title" />
          </PageTitle>
          <S.Subtitle>{t('onboarding.upgradeLife.subtitle')}</S.Subtitle>
          <S.Animation ref={animationRef} />
        </S.Container>
      </ContainerBeforePinnedButton>
      <NavigationButtonBelly
        type="button"
        onClick={handleClick}
      >{t`onboarding.upgradeLife.great`}</NavigationButtonBelly>
    </>
  )
}
