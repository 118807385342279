import React, { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectIsStayFitFlow,
} from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import { OfferCard } from 'modules/purchase/components/OfferCard'

import { Cohort, GROWTHBOOK_EXPERIMENT, MAIN_GOALS } from 'root-constants'

import { StyledReceivedPlan as S } from './ReceivedPlan.styles'

export const ReceivedPlan: FC = () => {
  const { t } = useTranslation()

  const isStayFitGoal = useSelector(selectIsStayFitFlow)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const cohortToUse = parentCohort || cohort

  const { benefits, benefitsBlockTitle } = useDynamicPaywallConfig()

  const hasPaywallTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_734)
  const hasPaywallTestBelly = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_802)

  const hasTestOnPaywall = hasPaywallTest || hasPaywallTestBelly

  const isFlowTwoCohort = useMemo(() => cohortToUse === Cohort.DB_FLOW_2, [
    cohortToUse,
  ])

  const isStayFitFlow = useMemo(() => isStayFitGoal && !isFlowTwoCohort, [
    isFlowTwoCohort,
    isStayFitGoal,
  ])

  const title = useMemo(() => {
    if (benefitsBlockTitle) return benefitsBlockTitle

    if (isFlowTwoCohort) {
      return <Trans i18nKey="purchaseCancelOfferMealPlan.products.title" />
    }

    return (
      <Trans
        i18nKey="purchase1.programDescriptionTitle"
        values={{
          context: isStayFitFlow ? MAIN_GOALS.STAY_FIT : MAIN_GOALS.LOSE_WEIGHT,
        }}
      />
    )
  }, [benefitsBlockTitle, isFlowTwoCohort, isStayFitFlow])

  return (
    <S.ProgramPlanContainer>
      {hasTestOnPaywall ? (
        <S.GrabPlanTitle>
          <Trans
            i18nKey="purchaseWithoutIntro.grabYourPlan"
            values={{
              context: isStayFitFlow
                ? MAIN_GOALS.STAY_FIT
                : MAIN_GOALS.LOSE_WEIGHT,
            }}
          />
        </S.GrabPlanTitle>
      ) : (
        <S.ProgramPlanTitle>{title}</S.ProgramPlanTitle>
      )}
      <ul>
        {benefits.length > 0 ? (
          benefits.map(({ id, text }) => (
            <S.ListItem key={id} $hasPaywallTest={hasTestOnPaywall}>
              {text}
            </S.ListItem>
          ))
        ) : (
          <>
            <S.ListItem $hasPaywallTest={hasTestOnPaywall}>
              <Trans
                i18nKey="purchaseWithoutIntro.whatYouGet.list.mostPopularWeight"
                values={{
                  context: isStayFitFlow
                    ? MAIN_GOALS.STAY_FIT
                    : MAIN_GOALS.LOSE_WEIGHT,
                }}
                components={[<strong />]}
              />
            </S.ListItem>
            <S.ListItem $hasPaywallTest={hasTestOnPaywall}>
              <Trans
                i18nKey="purchaseWithoutIntro.whatYouGet.list.effectiveWorkouts"
                values={{
                  context: isStayFitFlow
                    ? MAIN_GOALS.STAY_FIT
                    : MAIN_GOALS.LOSE_WEIGHT,
                }}
              />
            </S.ListItem>
            <S.ListItem $hasPaywallTest={hasTestOnPaywall}>
              <Trans i18nKey="purchase1.specialPrograms" />
            </S.ListItem>
            {!hasTestOnPaywall && (
              <>
                <S.ListItem>{t`purchase3.planHighlights.noEquipment`}</S.ListItem>
                <S.ListItem>{t`purchaseWithoutIntro.whatYouGet.list.mostPopularDance`}</S.ListItem>
              </>
            )}
          </>
        )}
      </ul>

      {hasTestOnPaywall && <OfferCard />}
    </S.ProgramPlanContainer>
  )
}
