import styled from 'styled-components'

import { Container } from 'components/Container'

import bgImageLegs from 'assets/images/flow-belly/times-per-week-legs.png'
import bgImage from 'assets/images/flow-belly/times-per-week.png'

export const StyledTimesPerWeek = {
  Container: styled(Container)<{ $isLegsFlow: boolean }>`
    background-image: url(${({ $isLegsFlow }) =>
      $isLegsFlow ? bgImageLegs : bgImage});
    background-repeat: no-repeat;
    min-height: 560px;
    background-size: 375px 500px;
    background-position: bottom;
  `,
}
