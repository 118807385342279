import { PageId } from 'page-constants'
import { Cohort } from 'root-constants'

export const QUESTION_IMAGE_PATH = '/images/cancel-offer-question'

export const OPTION_VALUES = {
  YES: 'yes',
  NO: 'no',
}

export const CANCEL_OFFER_PAYWALLS: Partial<Record<Cohort, PageId>> = {
  [Cohort.DANCEBIT_FLOW_1]: PageId.PURCHASE_CANCEL_OFFER_MEAL_PLAN,
  [Cohort.DANCEBIT_EMAIL_1]: PageId.PURCHASE_CANCEL_OFFER_MEAL_PLAN,
  [Cohort.DANCEBIT_EMAIL_2]: PageId.PURCHASE_CANCEL_OFFER_MEAL_PLAN,
  [Cohort.DANCEBIT_EMAIL_3]: PageId.PURCHASE_CANCEL_OFFER_MEAL_PLAN,
  [Cohort.DANCEBIT_20_30_40]: PageId.PURCHASE_CANCEL_OFFER_MEAL_PLAN,
  [Cohort.DANCEBIT_CLEAR_FLOW]: PageId.PURCHASE_CANCEL_OFFER_MEAL_PLAN,
  [Cohort.DB_INTRO]: PageId.PURCHASE_CANCEL_OFFER,
  [Cohort.DB_INTRO_FAST]: PageId.PURCHASE_CANCEL_OFFER,
  [Cohort.DB_INTRO_2]: PageId.PURCHASE_CANCEL_OFFER,
  [Cohort.DB_INTRO_DOP]: PageId.PURCHASE_CANCEL_OFFER,
  [Cohort.DB_FLOW_3D]: PageId.PURCHASE_CANCEL_OFFER_MEAL_PLAN,
  [Cohort.DB_FLOW_7D]: PageId.PURCHASE_CANCEL_OFFER_MEAL_PLAN,
  [Cohort.DANCEBIT_SHORT]: PageId.PURCHASE_CANCEL_OFFER,
  [Cohort.LUCKY_NY_2]: PageId.PURCHASE_CANCEL_OFFER_MEAL_PLAN,
  [Cohort.LUCKY_NY_3]: PageId.PURCHASE_CANCEL_OFFER_MEAL_PLAN,
  [Cohort.DB_CLEAR_INTRO_FAST]: PageId.PURCHASE_CANCEL_OFFER,
  [Cohort.DB_INTRO_FAST_18]: PageId.PURCHASE_CANCEL_OFFER,
  [Cohort.DB_INTRO_FAST_40]: PageId.PURCHASE_CANCEL_OFFER,
  [Cohort.DB_ADULT]: PageId.PURCHASE_CANCEL_OFFER,
  [Cohort.DB_ADULT_18]: PageId.PURCHASE_CANCEL_OFFER,
  [Cohort.DB_ADULT_2]: PageId.PURCHASE_CANCEL_OFFER,
  [Cohort.DB_ADULT_VIDEO]: PageId.PURCHASE_CANCEL_OFFER,
  [Cohort.DB_ADULT_WEEK]: PageId.PURCHASE_CANCEL_OFFER,
  [Cohort.GLORIA_INTRO_FAST]: PageId.PURCHASE_CANCEL_OFFER,
  [Cohort.YARINAS]: PageId.PURCHASE_CANCEL_OFFER,
  [Cohort.COHORT_2024]: PageId.PURCHASE_CANCEL_OFFER,
  [Cohort.SOMATIC_GOAL]: PageId.PURCHASE_CANCEL_OFFER,
  [Cohort.SOMATIC_AGE]: PageId.PURCHASE_CANCEL_OFFER,
  [Cohort.DB_BELLY]: PageId.PURCHASE_CANCEL_OFFER,
  [Cohort.DB_LEGS]: PageId.PURCHASE_CANCEL_OFFER,
  [Cohort.DB_REF]: PageId.PURCHASE_CANCEL_OFFER_REF,
}
