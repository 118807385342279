import styled from 'styled-components'

import questionIcon from 'assets/images/newYearOnboarding/question-icon.svg'

import { Color } from 'root-constants'

export const StyledFAQListNewYear = {
  Title: styled.h2`
    padding-bottom: 30px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  `,
  ListItem: styled.li`
    position: relative;
    padding-left: 42px;

    &::before {
      content: '';
      position: absolute;
      top: -3px;
      left: 0;
      width: 28px;
      height: 28px;
      background-image: url(${questionIcon});
      background-size: contain;
      background-repeat: no-repeat;
    }
  `,
  ListItemTitle: styled.h3`
    padding-bottom: 8px;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
  `,
  ListItemText: styled.p`
    padding-bottom: 40px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.GRAY_80};
  `,
}
