import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Option, OptionType } from 'components/Option'
import { SvgImage } from 'components/SvgImage'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectSubscriptionList,
} from 'root-redux/selects/common'
import { selectUserAvailableSpins } from 'root-redux/selects/user'

import { SubscriptionItemNewYear } from 'modules/purchase/components/SubscriptionItemNewYear'
import {
  SUBSCRIPTION_ACCESS_POINT,
  SUBSCRIPTION_ORDER,
} from 'modules/purchase/components/SubscriptionsBlockNewYear/constants'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import { selectSubscriptionId } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import questionMark from 'assets/images/sprite/question-mark.svg'

import { AvailableAttempt, Cohort } from 'root-constants'

import { StyledSubscriptionsBlockNewYear as S } from './SubscriptionsBlockNewYear.styles'

type TProps = {
  children?: React.ReactNode
}

export const SubscriptionsBlockNewYear: React.FC<TProps> = ({ children }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const subscriptions = useSelector(selectSubscriptionList)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)
  const availableSpins = useSelector(selectUserAvailableSpins)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort

  const hasOnlyTwoPlans = useMemo(() => cohortToUse === Cohort.LUCKY_NY_2, [
    cohortToUse,
  ])

  const isNoPrizeShown = useMemo(
    () => hasOnlyTwoPlans && availableSpins <= AvailableAttempt.ONE_ATTEMPT,
    [hasOnlyTwoPlans, availableSpins],
  )

  const isCardShirtShown = useMemo(
    () => hasOnlyTwoPlans && availableSpins > AvailableAttempt.ONE_ATTEMPT,
    [hasOnlyTwoPlans, availableSpins],
  )

  const handleChange = useCallback(
    (value) => {
      const checkedSubscription = subscriptions.find(({ id }) => id === value)
      dispatch(
        setSelectedSubscriptionAction(checkedSubscription as ISubscription),
      )
    },
    [dispatch, subscriptions],
  )

  return (
    <S.Wrapper>
      <S.SubscriptionContainer>
        {subscriptions.map((subscription) => (
          <Option
            type={OptionType.RADIO}
            name="subscription"
            value={subscription.id}
            key={subscription.id}
            onChange={handleChange}
            checked={subscription.id === selectedSubscriptionId}
            disabled={
              SUBSCRIPTION_ACCESS_POINT[cohortToUse as Cohort][
                subscription.product
              ] < availableSpins
            }
            flexOrder={
              SUBSCRIPTION_ORDER[cohortToUse as Cohort][subscription.product]
            }
            withoutMargin
          >
            {SUBSCRIPTION_ACCESS_POINT[cohortToUse as Cohort][
              subscription.product
            ] >= availableSpins ? (
              <SubscriptionItemNewYear
                subscription={subscription}
                isSelected={subscription.id === selectedSubscriptionId}
              />
            ) : (
              <S.CardShirt>
                <SvgImage svg={questionMark} width={31} />
              </S.CardShirt>
            )}
          </Option>
        ))}
        {isNoPrizeShown && (
          <S.NoPrize>
            <S.FailText>{t`purchaseNewYear.subscription.noPrize`}</S.FailText>
            <S.Disclaimer>{t`purchaseNewYear.subscription.tryAgain`}</S.Disclaimer>
          </S.NoPrize>
        )}

        {isCardShirtShown && (
          <S.CardShirt>
            <SvgImage svg={questionMark} width={31} />
          </S.CardShirt>
        )}
      </S.SubscriptionContainer>
      {children}
    </S.Wrapper>
  )
}
