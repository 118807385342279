import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

const circleProgress = (isBackground = false) => css<{
  $value: number
  $radius?: number
}>`
  ${({ $value, $radius = 45 }) => {
    const length = $radius * Math.PI * 2
    return css`
      stroke-dasharray: 0 ${length};
      stroke-width: ${$radius / 5};
      stroke-dasharray: ${isBackground ? length : length * ($value / 100)}
        ${length};
    `
  }};
  stroke-linecap: round;
  fill: transparent;
  transition: stroke-dasharray 0.2s;
`

export const StyledProgressRounded = {
  Container: styled.div`
    position: relative;
    height: 220px;
    margin: 0 auto;
  `,
  Svg: styled.svg<{ $value: number; $radius?: number }>`
    width: 100%;
    height: 100%;
    opacity: 0.5;
    transform: rotate(-90deg);
    circle {
      ${circleProgress(true)};
      stroke: #ececf9;
    }
    circle + circle {
      ${circleProgress()};
      stroke: ${Color.PRIMARY};
    }
  `,
  Label: styled.div`
    font-size: 60px;
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    strong {
      font-size: 32px;
      font-weight: 700;
    }
  `,
}
