import { PaymentMethod, PaymentSystem } from 'modules/purchase/constants'

import { eventLogger } from 'services/eventLogger.service'

import { ScreenId, ScreenName } from 'root-constants'

export const logStartedPayPalPayment = ({
  productId,
  price,
  paymentMethod,
  screenName,
  screenId,
  currency,
  isTrialActive,
  paymentSystem,
  goal,
}: {
  productId: string
  price: number
  paymentMethod: PaymentMethod
  screenName: ScreenName
  screenId: ScreenId
  currency: string
  isTrialActive: boolean
  paymentSystem: PaymentSystem
  goal: string
}): void => {
  eventLogger.logPurchaseStarted({
    productId,
    priceDetails: {
      price,
      currency,
      trial: isTrialActive,
    },
    paymentMethod,
    screenName,
    screenId,
    paymentSystem,
    goal,
  })
}
