import {
  selectScreenId,
  selectScreenName,
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'
import { selectUUID, selectUserPaymentSystem } from 'root-redux/selects/user'

import { createIntroOfferProductId } from 'helpers/createIntroOfferProductId'
import { createIntroOfferProductName } from 'helpers/createIntroOfferProductName'
import { createProductId } from 'helpers/createProductId'

import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionLookupKey,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialLookupKey,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/purchase/redux/selects/common'

import { IAppState } from 'models/store.model'

export const getStripePurchaseCommonEventParams = (state: IAppState) => {
  const uuid = selectUUID(state)
  const stripePriceId = selectSubscriptionLookupKey(state)
  const fullPrice = selectSubscriptionFullPrice(state)
  const trialPriceId = selectSubscriptionTrialLookupKey(state)
  const trialPrice = selectSubscriptionTrialPeriodPrice(state)
  const trialPeriodQuantity = selectSubscriptionTrialPeriodDays(state)
  const periodName = selectSubscriptionPeriodName(state)
  const periodQuantity = selectSubscriptionPeriodQuantity(state)
  const currency = selectCurrency(state)
  const screenName = selectScreenName(state)
  const screenId = selectScreenId(state)
  const accountId = selectStripeAccountId(state)
  const accountName = selectStripeAccountName(state)
  const paymentSystem = selectUserPaymentSystem(state)

  const productId = trialPriceId
    ? createIntroOfferProductId({
        priceId: stripePriceId,
        trialPriceId,
        trialPeriodQuantity,
      })
    : createProductId({
        periodName,
        periodQuantity,
        price: fullPrice,
      })

  const productName = trialPriceId
    ? createIntroOfferProductName({
        price: fullPrice,
        trialPrice,
        trialPeriodQuantity,
        periodName,
        periodQuantity,
      })
    : createProductId({
        periodName,
        periodQuantity,
        price: fullPrice,
      })

  return {
    uuid,
    trialPriceId,
    periodName,
    periodQuantity,
    currency,
    screenName,
    productId,
    stripePriceId,
    fullPrice,
    accountId,
    accountName,
    paymentSystem,
    productName,
    screenId,
  }
}
