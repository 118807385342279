import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SvgImage } from 'components/SvgImage'

import {
  selectAnswers,
  selectCurrentUserMeasurementSystem,
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectIsStayFitFlow,
  selectLanguage,
  selectUserAge,
} from 'root-redux/selects/common'

import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useIsNewYearCohort } from 'hooks/useIsNewYearCohort'

import { getMergedFocusAreas } from 'helpers/getMergedFocusAreas'

import emoji from 'assets/images/sprite/star-emoji.svg'

import { PageId } from 'page-constants'
import {
  ADULT_COHORT,
  Cohort,
  GenderStepOption,
  LOST_WEIGHT,
  Locale,
  MAIN_GOALS,
} from 'root-constants'

import { StyledMotivationStats as S } from './ResultMotivationStats.styles'

export const ResultMotivationStats: React.FC = () => {
  const { t } = useTranslation()

  const answers = useSelector(selectAnswers)
  const age = useSelector(selectUserAge)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const language = useSelector(selectLanguage)
  const currentMeasurementSystem = useSelector(
    selectCurrentUserMeasurementSystem,
  )
  const isStayFitGoal = useSelector(selectIsStayFitFlow)

  const cohortToUse = parentCohort || cohort

  const { currentPageId } = useGetPageInfo()

  const isNewYearCohort = useIsNewYearCohort()

  const isStayFitFlow = useMemo(
    () => cohortToUse !== Cohort.DB_FLOW_2 && isStayFitGoal,
    [cohortToUse, isStayFitGoal],
  )

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const text = useMemo(() => {
    if (isNewYearCohort) return 'result.motivationTextNewYear'

    if (isAgeFlow || currentPageId === PageId.PREPAYWALL) {
      return 'result.motivationTextAge'
    }

    return 'result.motivationText'
  }, [currentPageId, isAgeFlow, isNewYearCohort])

  const [genderText, areasText] = useMemo(() => {
    const gender =
      answers?.gender === GenderStepOption.FEMALE
        ? t`result.personalizedProgram.women`
        : t`result.personalizedProgram.men`

    const areas = ((answers?.improveAreas as string[]) || []).map((area) =>
      t(`onboarding.improveAreas.${area}`),
    )

    return [gender, getMergedFocusAreas(areas)]
  }, [t, answers?.gender, answers?.improveAreas])

  const isGerman = useMemo(() => language === Locale.DEUTSCH, [language])

  return (
    <S.Wrapper>
      <S.Title>
        {isNewYearCohort ? (
          <Trans
            i18nKey="result.motivationTitleNewYear"
            components={{ emoji: <SvgImage svg={emoji} width={24} /> }}
          />
        ) : (
          t`result.motivationTitle`
        )}
      </S.Title>
      <S.Content>
        <S.Percent>
          <S.PercentValue>86</S.PercentValue> <S.PercentSign>%</S.PercentSign>
        </S.Percent>
        <S.Text $isGerman={isGerman}>
          <Trans
            i18nKey={text}
            values={{
              genderText,
              areasText,
              age,
              lostWeight: LOST_WEIGHT[currentMeasurementSystem as string],
              weightUnit: t(`commonComponents.${currentMeasurementSystem}`),
              context: isStayFitFlow
                ? MAIN_GOALS.STAY_FIT
                : MAIN_GOALS.LOSE_WEIGHT,
            }}
          />
        </S.Text>
      </S.Content>
    </S.Wrapper>
  )
}
