import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import {
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { PaymentRequest } from '@stripe/stripe-js/types/stripe-js/payment-request'

import { resetErrorAction, stopFetching } from 'root-redux/actions/common'
import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import { Separator } from 'modules/purchase/components/Separator'
import {
  PaymentMethod,
  PremiumProduct,
  TRIAL_ONE_MONTH_DURATION,
  WEEK_DURATION,
} from 'modules/purchase/constants'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  purchaseAction,
  setPaymentMethodAction,
} from 'modules/purchase/redux/actions/common'
import {
  selectCurrency,
  selectProductId,
  selectSubscriptionFullPrice,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/purchase/redux/selects/common'
import { TPaymentRequestButton } from 'modules/purchase/types'

import { eventLogger } from 'services/eventLogger.service'

import {
  CENTS_IN_DOLLAR,
  Cohort,
  SEVEN_DAY_TRIAL_DURATION,
} from 'root-constants'

import { StyledPaymentRequestButton as S } from './PaymentRequestButton.styles'

type TProps = {
  isSeparatorVisible?: boolean
}

export const PaymentRequestButton: React.FC<TProps> = ({
  isSeparatorVisible = true,
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const { pathname } = useLocation()

  const trialCurrentPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const selectedPrice = useSelector(selectSubscriptionFullPrice)
  const currency = useSelector(selectCurrency)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const product = useSelector(selectProductId)

  const cohortToUse = parentCohort || cohort

  const isBellyFlow = useMemo(
    () => cohortToUse === Cohort.DB_BELLY || cohortToUse === Cohort.DB_LEGS,
    [cohortToUse],
  )

  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
    null,
  )
  const [buttonTypes, setButtonTypes] = useState<TPaymentRequestButton | null>(
    null,
  )

  const isPremium = useMemo(() => product === PremiumProduct.PREMIUM, [product])

  const isTwoPlansCohort = useMemo(() => cohortToUse === Cohort.DB_TWO_PLANS, [
    cohortToUse,
  ])

  const planPeriodDescription = useMemo(
    () =>
      trialPeriodDays.toString() === SEVEN_DAY_TRIAL_DURATION
        ? t('purchase1.paymentForm.trialPlanDescription')
        : t('purchaseIntroOffer.monthlyPeriod', {
            count: trialPeriodDays
              ? Math.round(trialPeriodDays / TRIAL_ONE_MONTH_DURATION)
              : periodQuantity,
          }),
    [trialPeriodDays, t, periodQuantity],
  )

  const planPeriodDescriptionWeek = useMemo(
    () =>
      isTwoPlansCohort
        ? t('purchaseTwoPlans.subscriptions.planTitle', {
            context: isPremium ? PremiumProduct.PREMIUM : PremiumProduct.BASIC,
          })
        : t('purchaseWeeklyOffer.paymentForm.planDescription', {
            count: Math.round(trialPeriodDays / WEEK_DURATION),
          }),
    [isPremium, isTwoPlansCohort, t, trialPeriodDays],
  )

  const planDescription = useMemo(
    () =>
      cohortToUse === Cohort.DB_ADULT_WEEK ||
      cohortToUse === Cohort.DB_TWO_PLANS
        ? planPeriodDescriptionWeek
        : planPeriodDescription,
    [cohortToUse, planPeriodDescription, planPeriodDescriptionWeek],
  )

  const calculatedPrice = useMemo(
    () => +((trialCurrentPrice || selectedPrice) * CENTS_IN_DOLLAR).toFixed(),
    [trialCurrentPrice, selectedPrice],
  )

  const currentPageId = useMemo(() => getPageIdFromPathName(pathname), [
    pathname,
  ])

  useEffect(() => {
    if (!stripe || !elements) {
      return
    }

    const pr = stripe?.paymentRequest({
      currency,
      country: 'GB',
      requestPayerEmail: true,
      requestPayerName: true,
      total: {
        label: planDescription,
        amount: calculatedPrice,
      },
    })

    pr?.canMakePayment().then((result) => {
      if (result) {
        setButtonTypes(result as TPaymentRequestButton)
        setPaymentRequest(pr)
      }
      dispatch(stopFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    })

    pr?.on('paymentmethod', (event) => {
      dispatch(resetErrorAction())
      dispatch(
        purchaseAction({
          stripe,
          paymentPageId: currentPageId,
          createPaymentResFromDigitalWallet: event,
        }),
      )
    })
  }, [
    dispatch,
    currency,
    stripe,
    elements,
    planDescription,
    calculatedPrice,
    currentPageId,
  ])

  const handleButtonClick = useCallback(() => {
    const shownButtonType = buttonTypes?.applePay
      ? PaymentMethod.APPLE_PAY
      : PaymentMethod.GOOGLE_PAY

    dispatch(setPaymentMethodAction(shownButtonType))
    eventLogger.logPaymentMethodSelected(shownButtonType)
  }, [buttonTypes, dispatch])

  if (paymentRequest) {
    return (
      <S.Wrapper $isBellyFlow={isBellyFlow}>
        {isSeparatorVisible && <Separator />}
        <PaymentRequestButtonElement
          onClick={handleButtonClick}
          options={{
            paymentRequest,
            style: {
              paymentRequestButton: {
                height: '56px',
              },
            },
          }}
        />
      </S.Wrapper>
    )
  }

  return null
}
