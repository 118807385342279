import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAnswers } from 'root-redux/selects/common'

import resultGenderFemalePng from 'assets/images/result-gender-female-ny.png'
import resultGenderFemaleWebp from 'assets/images/result-gender-female-ny.webp'
import resultGenderMalePng from 'assets/images/result-gender-male-ny.png'
import resultGenderMaleWebp from 'assets/images/result-gender-male-ny.webp'

import { PageId } from 'page-constants'
import { Gender } from 'root-constants'

import { StyledIntroMediaNewYear as S } from './IntroMediaNewYear.styles'

export const IntroMediaNewYear: React.FC = () => {
  const userAnswers = useSelector(selectAnswers)

  const isMale = useMemo(() => userAnswers?.[PageId.GENDER] === Gender.MALE, [
    userAnswers,
  ])

  return (
    <S.Wrapper>
      <S.Title>
        <Trans
          i18nKey="purchaseNewYear.introMedia.title"
          components={[<br />]}
        />
      </S.Title>
      <S.MainImageContainer>
        <picture>
          <source
            srcSet={isMale ? resultGenderMaleWebp : resultGenderFemaleWebp}
            type="image/webp"
          />
          <img
            src={isMale ? resultGenderMalePng : resultGenderFemalePng}
            alt="body"
          />
        </picture>
      </S.MainImageContainer>
    </S.Wrapper>
  )
}
