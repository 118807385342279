export const QUESTION = `First, let's find out what would make you happy in 2023?`

export const OPTION_VALUES = {
  REACHING_GOAL_WEIGHT: 'reachingGoalWeight',
  GETTING_SLIM_OR_TONED: 'gettingSlimOrToned',
  IMPROVE_HEALTH: 'improveHealth',
  GETTING_FLEXIBLE: 'gettingFlexible',
  MANAGING_ANXIETY: 'managingAnxiety',
  IMPROVE_DANCING: 'improveDancing',
}
