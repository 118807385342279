import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useDynamicPage } from 'hooks/useDynamicPage'

import { TPageProps } from 'models/common.model'

import { ADULT_COHORT, Cohort } from 'root-constants'

import { StyledDynamicPageWithImage as S } from './styles'

// todo
export const PageWithImage: React.FC<TPageProps> = (props) => {
  const {
    title,
    subtitle,
    buttonText,
    optionData,
    handleContinue,
  } = useDynamicPage(props)

  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const handleNextClick = useCallback(() => handleContinue(''), [
    handleContinue,
  ])

  return (
    <div>
      {!subtitle && (
        <S.PictureContainer>
          <img src={optionData.imageUrl} alt={title} />
        </S.PictureContainer>
      )}
      <S.Container $isAgeFlow={isAgeFlow}>
        {!subtitle && <S.PageSubtitle>{title}</S.PageSubtitle>}

        {subtitle && (
          <>
            <PageTitle marginBottom={isAgeFlow ? 16 : 10} isAgeFlow={isAgeFlow}>
              {title}
            </PageTitle>

            <S.AnimationContainer>
              <S.AnimationTitle>{subtitle}</S.AnimationTitle>
              <S.Animation
                src={optionData.imageUrl}
                alt={title}
                $isAgeFlow={isAgeFlow}
              />
            </S.AnimationContainer>
          </>
        )}

        <S.ButtonContainer>
          <Button type="button" onClick={handleNextClick}>
            {buttonText}
          </Button>
        </S.ButtonContainer>
      </S.Container>
    </div>
  )
}
