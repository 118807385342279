import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

type TUpsellSubscriptionItemV2Props = {
  $isChecked: boolean
}

export const StyledUpsellSubscriptionItem = {
  Wrapper: styled.div<TUpsellSubscriptionItemV2Props>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    padding: 12px 16px;
    margin-bottom: 16px;

    border-radius: 16px;
    border: ${({ $isChecked }) =>
      $isChecked ? `2px solid ${Color.PRIMARY}` : '2px solid #e3e4e8'};

    transition: border 0.2s ease-out;
  `,
  ToggleContainer: styled.div`
    min-width: 220px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
  `,
  PlanName: styled.span<TUpsellSubscriptionItemV2Props>`
    max-width: 120px;

    margin-left: 16px;

    font-size: 18px;
    font-weight: 800;
    line-height: 22px;
    color: ${({ $isChecked }) => ($isChecked ? Color.DARK : Color.DISABLED)};

    transition: color 0.2s ease-out;

    @media (min-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      max-width: 140px;
    }
  `,

  PriceBlock: styled.span<TUpsellSubscriptionItemV2Props>`
    max-width: 76px;

    font-size: 18px;
    font-weight: 800;
    line-height: 22px;
    color: ${({ $isChecked }) => ($isChecked ? Color.DARK : Color.DISABLED)};

    transition: color 0.2s ease-out;
  `,
}
