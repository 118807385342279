import { TSlide } from 'modules/purchase/constants'

import image1 from 'assets/images/result-photo1-v3.jpg'
import image2 from 'assets/images/result-photo2-v3.jpg'
import image3 from 'assets/images/result-photo3-v3.jpg'
import image4 from 'assets/images/result-photo4-v3.jpg'
import image5 from 'assets/images/result-photo5-v3.jpg'

export const SLIDES_FEMALE: TSlide[] = [
  {
    id: '1',
    imageUrl: image1,
    author: 'purchaseWithoutIntro.photoResult.author1.author',
    review: 'purchaseWithoutIntro.photoResult.author1.review',
    weight: 'purchaseWithoutIntro.photoResult.author1.weight',
  },
  {
    id: '2',
    imageUrl: image2,
    author: 'purchaseWithoutIntro.photoResult.author2.author',
    review: 'purchaseWithoutIntro.photoResult.author2.review',
    weight: 'purchaseWithoutIntro.photoResult.author2.weight',
  },
  {
    id: '3',
    imageUrl: image3,
    author: 'purchaseWithoutIntro.photoResult.author3.author',
    review: 'purchaseWithoutIntro.photoResult.author3.review',
    weight: 'purchaseWithoutIntro.photoResult.author3.weight',
  },
]

export const SLIDES_MALE: TSlide[] = [
  {
    id: '1',
    imageUrl: image4,
    author: 'purchaseWithoutIntro.photoResult.author4.author',
    review: 'purchaseWithoutIntro.photoResult.author4.review',
    weight: 'purchaseWithoutIntro.photoResult.author4.weight',
  },
  {
    id: '2',
    imageUrl: image5,
    author: 'purchaseWithoutIntro.photoResult.author5.author',
    review: 'purchaseWithoutIntro.photoResult.author5.review',
    weight: 'purchaseWithoutIntro.photoResult.author5.weight',
  },
]
