import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentUserGoalWeight,
  selectCurrentUserMeasurementSystem,
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectIsStayFitFlow,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import stayFitAnimation from 'assets/animation/stayFitAnimation.json'
import loseWeightAnimation from 'assets/animation/weightLossAnimation.json'

import { ADULT_COHORT, Cohort, MAIN_GOALS, WEIGHT_UNITS } from 'root-constants'

import { StyledBoostUnlocked as S } from './BoostUnlocked.styles'
import { QUESTION_LOSE_WEIGHT, QUESTION_STAY_FIT } from './constants'

export const BoostUnlocked: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { t } = useTranslation()

  const isStayFitGoal = useSelector(selectIsStayFitFlow)
  const goalWeight = useSelector(selectCurrentUserGoalWeight)
  const measurementSystem = useSelector(selectCurrentUserMeasurementSystem)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const cohortToUse = parentCohort || cohort

  const animationRef = useRef<HTMLDivElement>(null)

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const isStayFitFlow = isAgeFlow && isStayFitGoal

  const pageQuestion = isStayFitFlow ? QUESTION_STAY_FIT : QUESTION_LOSE_WEIGHT

  useEffect(() => {
    if (animationRef.current) {
      lottie.loadAnimation({
        container: animationRef.current,
        animationData: isStayFitFlow ? stayFitAnimation : loseWeightAnimation,
        loop: false,
        name: 'graphAnimation',
      })
    }

    return () => lottie.destroy('graphAnimation')
  }, [isStayFitFlow])

  const handleContinue = useNextStep({
    pageId,
    question: pageQuestion,
    nextPagePath,
  })

  const handleClick = useCallback(() => handleContinue(pageQuestion), [
    pageQuestion,
    handleContinue,
  ])

  return (
    <>
      <S.ContainerBeforePinnedButton $isAgeFlow={isAgeFlow}>
        <S.Container $isAgeFlow={isAgeFlow} isLarge={!isAgeFlow}>
          <PageTitle isAgeFlow={isAgeFlow} marginBottom={16}>
            <Trans
              i18nKey="onboarding.boostUnlocked.title"
              values={{
                context: isStayFitFlow
                  ? MAIN_GOALS.STAY_FIT
                  : MAIN_GOALS.LOSE_WEIGHT,
              }}
            />
          </PageTitle>

          <S.Graph ref={animationRef} />

          <S.Text>
            <Trans
              i18nKey="onboarding.boostUnlocked.textFirst"
              values={{
                context: isStayFitFlow
                  ? MAIN_GOALS.STAY_FIT
                  : MAIN_GOALS.LOSE_WEIGHT,
              }}
            />
          </S.Text>
          <S.Text>
            <Trans
              i18nKey="onboarding.boostUnlocked.textSecond"
              values={{
                goalWeight: `${goalWeight} ${WEIGHT_UNITS[measurementSystem]}`,
                context: isStayFitFlow
                  ? MAIN_GOALS.STAY_FIT
                  : MAIN_GOALS.LOSE_WEIGHT,
              }}
            />
          </S.Text>
        </S.Container>
      </S.ContainerBeforePinnedButton>

      <S.ButtonContainer $isAgeFlow={isAgeFlow}>
        <S.NavigationButton
          type="button"
          isAgeFlow={isAgeFlow}
          onClick={handleClick}
        >
          {t('onboarding.boostUnlocked.great')}
        </S.NavigationButton>
      </S.ButtonContainer>
    </>
  )
}
