import emoji from 'assets/images/flow-belly/icons/emoji.svg'
import goblet from 'assets/images/flow-belly/icons/goblet.svg'
import muscle from 'assets/images/flow-belly/icons/muscles.svg'

export const QUESTION =
  'How confident are you in reaching goal weight kg by event?'

export const OPTION_VALUES = [
  {
    title: 'onboarding.confident.canDoIt',
    value: 'canDoIt',
    icon: goblet,
  },
  {
    title: 'onboarding.confident.willTry',
    value: 'willTry',
    icon: muscle,
  },
  {
    title: 'onboarding.confident.notSure',
    value: 'notSure',
    icon: emoji,
  },
]
