import { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectUUID } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'

import { FBConsent } from 'root-constants'

export const useFacebookPixelInitialization = (
  facebookPixelIds: string[],
): void => {
  const uuid = useSelector(selectUUID)

  const {
    isEUUser,
    hasAdvertizingAndMarketingCookie,
    userCookieConsentAnswer,
  } = useCookieConsentAnswer()

  useLayoutEffect(() => {
    !!facebookPixelIds.length &&
      facebookPixelIds.forEach((facebookPixelId) => {
        window.fbq('init', facebookPixelId, { external_id: uuid })
      })

    isEUUser &&
      (!userCookieConsentAnswer?.length || !hasAdvertizingAndMarketingCookie) &&
      window.fbq('consent', FBConsent.REVOKE)

    isEUUser &&
      userCookieConsentAnswer?.length &&
      hasAdvertizingAndMarketingCookie &&
      window.fbq('consent', FBConsent.GRANT)

    window.fbq('track', 'PageView')
  }, [
    facebookPixelIds,
    hasAdvertizingAndMarketingCookie,
    isEUUser,
    userCookieConsentAnswer?.length,
    uuid,
  ])
}
