import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

type TDynamicProps = {
  title?: string
  subtitle?: string
  buttonText?: string
  stickerText?: string
}

export const useDynamicOBConfig = (): TDynamicProps => {
  const language = useSelector(selectLanguage)
  const { currentPageIndex, steps } = useGetPageInfo()

  return useMemo(() => {
    const dynamicOBConfig = steps[currentPageIndex].onboardingDynamicConfig

    return {
      title: dynamicOBConfig?.[language]?.title,
      subtitle: dynamicOBConfig?.[language]?.subtitle,
      buttonText: dynamicOBConfig?.[language]?.buttonText,
      stickerText: dynamicOBConfig?.[language]?.stickerText,
    }
  }, [currentPageIndex, language, steps])
}
