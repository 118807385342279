import styled from 'styled-components'

import { Button } from 'components/Button'
import { SvgImage } from 'components/SvgImage'

import { Color, MediaBreakpoint } from 'root-constants'

type TResultProps = {
  $hasNewIllustrations: boolean
}

export const StyledResult = {
  Root: styled.div`
    width: 100%;
    max-width: 100%;
    padding-bottom: 160px;
    margin: 0 auto;
  `,
  Container: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 16px;
  `,
  MainImageContainer: styled.div`
    padding-top: 73.888888%;
    position: relative;
    background-color: #80daff;
  `,
  MainImage: styled.picture`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
    z-index: 1;
  `,
  Section: styled.div`
    width: 100%;
    margin: 24px auto;
    overflow: hidden;
    text-align: center;
    color: ${Color.DARK};

    &:last-child {
      margin-bottom: 0;
    }
  `,
  Title: styled.h1`
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    padding: 16px;
    background-color: ${Color.PRIMARY};
    color: #fff;
    text-align: center;
  `,
  Subtitle: styled.h2`
    margin-bottom: 10px;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.DARK};

    & strong {
      color: ${Color.PRIMARY};
    }
  `,
  Text: styled.p`
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  `,
  TimerTitle: styled.h2`
    margin-bottom: 30px;
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;

    strong {
      color: ${Color.PRIMARY};
    }
  `,
  ProgramAdvantage: styled.h3`
    text-align: center;
    margin-bottom: 24px;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
  `,
  ProgramDescription: styled.h3`
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  `,
  IntroMediaContainer: styled.div<TResultProps>`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin-bottom: ${({ $hasNewIllustrations }) =>
      $hasNewIllustrations ? '24px' : '70px'};

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
      margin: ${({ $hasNewIllustrations }) =>
        $hasNewIllustrations ? '0 auto 24px' : '0 auto 60px'};
    }
  `,
  ImageContainer: styled.div`
    width: 100%;
    margin-bottom: 16px;
  `,
  Image: styled.img`
    vertical-align: top;
  `,
  Svg: styled(SvgImage)`
    margin: 0 auto 30px;
  `,
  ResultButton: styled(Button)`
    max-width: none;
  `,
}
