import React, { FC, useCallback, useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'components/Button'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectIsStayFitFlow,
  selectSubscriptionList,
} from 'root-redux/selects/common'

import { getUpsellPlans } from 'modules/purchase/helpers/rootHelpers'
import { PLANS_FLOW } from 'modules/purchase/pages/UpsellV2/constant'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'

import { Cohort } from 'root-constants'

type TProps = {
  makeUpsell: () => void
  text: string
}

export const AcceptAllButton: FC<TProps> = ({ makeUpsell, text }) => {
  const dispatch = useDispatch()

  const isStayFitGoal = useSelector(selectIsStayFitFlow)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohort = useSelector(selectCurrentVariantCohort)
  const subscriptions = useSelector(selectSubscriptionList)

  const cohortToUse = parentCohort || cohort

  const isStayFitFlow = useMemo(
    () => cohortToUse !== Cohort.DB_FLOW_2 && isStayFitGoal,
    [cohortToUse, isStayFitGoal],
  )

  const { bothPlan } = getUpsellPlans(
    subscriptions,
    isStayFitFlow ? PLANS_FLOW.STAY_FIT : PLANS_FLOW.LOSE_WEIGHT,
  )

  const handleContinueWithAll = useCallback(() => {
    bothPlan && dispatch(setSelectedSubscriptionAction(bothPlan))

    makeUpsell()
  }, [dispatch, bothPlan, makeUpsell])

  return (
    <Button onClick={handleContinueWithAll}>
      <Trans i18nKey={text} />
    </Button>
  )
}
