import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledIntroMediaNewYear = {
  Wrapper: styled.div`
    margin: 0 auto;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
  Title: styled.h2`
    padding: 8px 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: ${Color.DARK};

    strong {
      color: ${Color.PRIMARY};
    }
  `,
  MainImageContainer: styled.div`
    aspect-ratio: 360/310;
  `,
}
