import { GeneralProduct } from 'modules/purchase/constants'

import { AvailableAttempt, Cohort } from 'root-constants'

export const PROGRAM_DESCRIPTION_POINTS = [
  { id: 1, path: 'purchaseNewYear.programDescription.weightLossPlan' },
  { id: 2, path: 'purchaseNewYear.programDescription.effectiveWorkouts' },
  { id: 3, path: 'purchaseNewYear.programDescription.specialPrograms' },
  { id: 4, path: 'purchaseNewYear.programDescription.practiceAnywhere' },
  { id: 5, path: 'purchaseNewYear.programDescription.popularDanceStyles' },
]

export const PRESELECTED_PRODUCT = {
  [Cohort.LUCKY_NY_2]: {
    [AvailableAttempt.TWO_ATTEMPTS]: GeneralProduct.ONE_YEAR,
    [AvailableAttempt.ONE_ATTEMPT]: GeneralProduct.ONE_YEAR,
    [AvailableAttempt.NO_ATTEMPTS]: GeneralProduct.SIX_MONTHS,
  },
  [Cohort.LUCKY_NY_3]: {
    [AvailableAttempt.TWO_ATTEMPTS]: GeneralProduct.ONE_YEAR,
    [AvailableAttempt.ONE_ATTEMPT]: GeneralProduct.SIX_MONTHS,
    [AvailableAttempt.NO_ATTEMPTS]: GeneralProduct.THREE_MONTHS,
  },
}

export const DISCLAIMER_TEXTS = {
  [GeneralProduct.ONE_YEAR]: 'purchaseNewYear.disclaimer.annually',
  [GeneralProduct.SIX_MONTHS]: 'purchaseNewYear.disclaimer.semiAnnually',
  [GeneralProduct.THREE_MONTHS]: 'purchaseNewYear.disclaimer.quarterly',
}
