import styled from 'styled-components'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import { FAQList } from 'modules/purchase/components/FAQList'
import { IntroOfferDisclaimer } from 'modules/purchase/components/IntroOfferDisclaimer'
import { PurchaseDisclaimer } from 'modules/purchase/components/PurchaseDisclaimer'
import { SubheaderWithTimer } from 'modules/purchase/components/SubheaderWithTimer'

import cardBgSmall from 'assets/images/image-with-wallet-small.png'
import cardBg from 'assets/images/image-with-wallet.png'

import { Color } from 'root-constants'

export const StyledPurchaseWithoutIntro = {
  Wrapper: styled(Container)`
    padding-bottom: 16px;
  `,
  Container: styled.div`
    margin-bottom: 50px;
  `,
  SubscriptionDisclaimer: styled(PurchaseDisclaimer)`
    margin-bottom: 50px;
  `,
  SubscriptionIntroDisclaimer: styled(IntroOfferDisclaimer)`
    margin-bottom: 50px;
  `,
  FAQList: styled(FAQList)`
    p {
      color: ${Color.LIGHT_GREY};
      line-height: 18px;
      padding-right: 16px;
      margin-bottom: 40px;
    }
  `,
  PurchaseButton: styled(Button)`
    margin: 0 auto 24px !important;
  `,
  TimerSubheaderWithTimer: styled(SubheaderWithTimer)<{
    $isBellyFlow?: boolean
  }>`
    margin: 18px auto 50px;
    padding: 20px 16px;

    & span:first-child {
      display: flex;
    }

    button {
      width: 94px;
      height: 40px;
      font-size: ${({ $isBellyFlow }) => ($isBellyFlow ? `15px` : '16px')};
      font-weight: 700;
      line-height: 16px;
      padding: 5px 10px;
    }
  `,
  Button: styled(Button)`
    max-width: 328px;
  `,
  RefundCard: styled.div`
    color: ${Color.WHITE};
    text-align: center;
    text-shadow: 0 1px 6px rgba(0, 0, 0, 0.14);
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    background-image: url(${cardBg});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 184px;
    padding: 94px 12px 16px;
    margin-bottom: 16px;

    strong {
      font-weight: 700;
    }
  `,
  RefundCardSmall: styled.div`
    color: ${Color.WHITE};
    text-shadow: 0 1px 6px rgba(0, 0, 0, 0.14);
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    background-image: url(${cardBgSmall});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 120px;
    padding: 24px 16px 16px 96px;
    margin-bottom: 50px;

    strong {
      font-weight: 700;
    }
  `,
  CheckoutContainer: styled.div<{ $isVisible: boolean }>`
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  `,
}
