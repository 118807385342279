import styled from 'styled-components'

import { Button } from 'components/Button'

import { Color } from 'root-constants'

export const StyledUpsellV2 = {
  Wrapper: styled.div`
    max-width: 360px;

    margin: 0 auto;
    padding: 0 16px 20px;
  `,
  Title: styled.h2`
    margin-bottom: 16px;
    margin-top: 40px;

    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: ${Color.DARK};
  `,
  Subtitle: styled.p`
    margin-bottom: 24px;

    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: ${Color.DARK};
  `,
  Description: styled.p`
    margin-bottom: 40px;

    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.DISABLED};
  `,
  Text: styled.p`
    max-width: 300px;

    margin-bottom: 40px;

    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  `,
  Disclaimer: styled.p`
    padding: 24px;

    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 8px 20px 0px rgba(190, 195, 200, 0.2);

    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: ${Color.DISABLED};

    & a {
      color: ${Color.PRIMARY};
      text-decoration: underline;
      font-weight: 600;
    }
  `,
  LinkContainer: styled.div`
    margin: 24px 0;

    text-align: center;
  `,
  Link: styled.a`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-decoration: underline;
    color: ${Color.DISABLED};

    cursor: pointer;
  `,
  UpsellButton: styled(Button)`
    width: 100%;
  `,
}
