import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { TReview } from 'components/CustomerReviews/constants'
import { SvgImage } from 'components/SvgImage'

import commentWithHeartPng from 'assets/images/comment-with-heart.png'
import commentWithHeartWebp from 'assets/images/comment-with-heart.webp'
import starSvg from 'assets/images/sprite/star.svg'

import { CUSTOMER_REVIEWS } from 'root-constants'

import { StyledCustomerReviewsNewYear as S } from './CustomerReviewsNewYear.styles'

type TProps = {
  customerReviews?: TReview[]
}

export const CustomerReviewsNewYear: React.FC<TProps> = ({
  customerReviews = CUSTOMER_REVIEWS,
}) => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <S.Header>
        <S.Title>
          <Trans
            i18nKey="purchaseCancelOffer.customerReviews.title"
            components={[<strong />]}
          />
        </S.Title>
        <S.Picture data-testid="customer-img">
          <source srcSet={commentWithHeartWebp} type="image/webp" />
          <img src={commentWithHeartPng} alt="comment-with-heart" />
        </S.Picture>
      </S.Header>

      <S.Comments>
        {customerReviews.map(({ author, review }) => (
          <S.Comment key={author}>
            <S.Author>{t(author)}</S.Author>
            <S.Rating>
              <SvgImage svg={starSvg} data-testid="svg-image-test-id" />
              <SvgImage svg={starSvg} data-testid="svg-image-test-id" />
              <SvgImage svg={starSvg} data-testid="svg-image-test-id" />
              <SvgImage svg={starSvg} data-testid="svg-image-test-id" />
              <SvgImage svg={starSvg} data-testid="svg-image-test-id" />
            </S.Rating>
            <S.Text>{t(review)}</S.Text>
          </S.Comment>
        ))}
      </S.Comments>
    </S.Wrapper>
  )
}
