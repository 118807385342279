import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Container } from 'components/Container'
import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import tenMillionPeopleBellyImage from 'assets/images/flow-belly/ten-million-people-belly.png'
import tenMillionPeopleLegsImage from 'assets/images/flow-belly/ten-million-people-legs.png'

import { Cohort } from 'root-constants'

import { StyledTenMillionPeople as S } from './TenMillionPeopleBelly.styles'
import { QUESTION } from './constants'

export const TenMillionPeopleBelly: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])

  return (
    <>
      <ContainerBeforePinnedButton>
        <S.PictureContainer>
          <img
            src={
              cohortToUse === Cohort.DB_LEGS
                ? tenMillionPeopleLegsImage
                : tenMillionPeopleBellyImage
            }
            alt="10 million people have chosen dancebit"
          />
        </S.PictureContainer>
        <Container>
          <S.Title>{t('onboarding.tenMillionPeople.question')}</S.Title>
          <S.Subtitle>
            <Trans i18nKey="onboarding.tenMillionPeople.subtitleBelly" />
          </S.Subtitle>
        </Container>
      </ContainerBeforePinnedButton>

      <NavigationButtonBelly type="button" onClick={handleClick}>
        {t`actions.continue`}
      </NavigationButtonBelly>
    </>
  )
}
