import midSizedLegs from 'assets/images/mid-sized-legs.png'
import midSized from 'assets/images/mid-sized.png'
import overweightLegs from 'assets/images/overweight-legs.png'
import overweight from 'assets/images/overweight.png'
import plusSizedLegs from 'assets/images/plus-sized-legs.png'
import plusSized from 'assets/images/plus-sized.png'
import slimLegs from 'assets/images/slim-legs.png'
import slim from 'assets/images/slim.png'

export const QUESTION = 'What image describes your physical build and belly?'

export const OPTION_VALUES = [
  {
    value: 'Slim',
    title: 'onboarding.physicalBuild.slim',
    img: slim,
  },
  {
    value: 'midSized',
    title: 'onboarding.physicalBuild.midSized',
    img: midSized,
  },
  {
    value: 'plusSized',
    title: 'onboarding.physicalBuild.plusSized',
    img: plusSized,
  },
  {
    value: 'overweight',
    title: 'onboarding.physicalBuild.overweight',
    img: overweight,
  },
]
export const OPTION_VALUES_LEGS = [
  {
    value: 'slim',
    title: 'onboarding.physicalBuild.slim',
    img: slimLegs,
  },
  {
    value: 'midSized',
    title: 'onboarding.physicalBuild.midSized',
    img: midSizedLegs,
  },
  {
    value: 'plusSized',
    title: 'onboarding.physicalBuild.plusSized',
    img: plusSizedLegs,
  },
  {
    value: 'overweight',
    title: 'onboarding.physicalBuild.overweight',
    img: overweightLegs,
  },
]
