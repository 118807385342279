import React, { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Answer, answerTheme } from 'storybook-ui'

import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Cohort } from 'root-constants'

import { WeightFluctuationsStyles as S } from './WeightFluctuations.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const WeightFluctuations: FC<TPageProps> = ({
  nextPagePath,
  pageId,
}) => {
  const { t } = useTranslation()
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <S.Container isLarge $isLegsFlow={cohortToUse === Cohort.DB_LEGS}>
      <PageTitle marginBottom={24}>
        <Trans i18nKey="onboarding.weightFluctuations.title" />
      </PageTitle>

      {OPTION_VALUES.map(({ value, title }) => (
        <Answer
          {...optionProps}
          theme={answerTheme.DANCEBIT}
          maxWidth="180px"
          margin="0 0 16px 0"
          disabled={isAnswersDisabled}
          value={value}
          key={value}
        >
          {t(title)}
        </Answer>
      ))}
    </S.Container>
  )
}
