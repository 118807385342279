import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  PayPalButtons,
  PayPalScriptProvider,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectPayPalClientId,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { PaymentStatusModal } from 'modules/purchase/components/PaymentStatusModal'
import { usePayPalButton } from 'modules/purchase/hooks/usePayPalButton'
import { selectPayPalPlanId } from 'modules/purchase/redux/selects/common'

import { Cohort, SNAPCHAT_QUERY_PARAM } from 'root-constants'

import { StyledPayPalButton as S } from './PayPalButton.styles'

export const PayPalButton: React.FC = () => {
  const uuid = useSelector(selectUUID)
  const paypalPlanId = useSelector(selectPayPalPlanId)
  const paypalClientId = useSelector(selectPayPalClientId)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const isBellyFlow = useMemo(() => {
    const cohortToUse = parentCohort || cohort

    return cohortToUse === Cohort.DB_BELLY || cohortToUse === Cohort.DB_LEGS
  }, [cohort, parentCohort])

  const {
    isButtonTouched,
    handlePaymentApprove,
    handlePaymentError,
    handlePaymentCancel,
    handleButtonClick,
  } = usePayPalButton()

  const FakePaypalButton = () => {
    const [{ isPending: isPaypalButtonLoading }] = usePayPalScriptReducer()
    return (
      <>
        {!isPaypalButtonLoading && (
          <S.FakePaypalButton
            $isBellyFlow={isBellyFlow}
            $isTouched={isButtonTouched}
            type="button"
          />
        )}
      </>
    )
  }

  return (
    <S.Root>
      <S.ButtonsContainer $isBellyFlow={isBellyFlow}>
        {paypalPlanId && paypalClientId && (
          <PayPalScriptProvider
            options={{
              'client-id': paypalClientId,
              vault: true,
              'disable-funding': 'credit',
            }}
          >
            <PayPalButtons
              style={{
                label: 'paypal',
                tagline: false,
                layout: 'horizontal',
                height: 55,
              }}
              forceReRender={[paypalPlanId]}
              createSubscription={(data, actions) =>
                actions.subscription.create({
                  plan_id: paypalPlanId, // Creates the subscription
                  custom_id: uuid,
                  application_context: {
                    return_url: `${window.location.origin}/result${window.location.search}&${SNAPCHAT_QUERY_PARAM}`,
                  },
                })
              }
              onApprove={handlePaymentApprove}
              onError={handlePaymentError}
              onCancel={handlePaymentCancel}
              onClick={handleButtonClick}
            />
            <FakePaypalButton />
          </PayPalScriptProvider>
        )}
      </S.ButtonsContainer>
      <PaymentStatusModal hasDisclaimer />
    </S.Root>
  )
}
