import busyLife from 'assets/images/flow-belly/icons/busy-life.svg'
import covid from 'assets/images/flow-belly/icons/covid.svg'
import divorce from 'assets/images/flow-belly/icons/divorce.svg'
import financialChallenges from 'assets/images/flow-belly/icons/financial-challenges.svg'
import injury from 'assets/images/flow-belly/icons/injury.svg'
import none from 'assets/images/flow-belly/icons/none.svg'
import other from 'assets/images/flow-belly/icons/other-events.svg'
import pregnancy from 'assets/images/flow-belly/icons/pregnancy.svg'
import slowMetabolism from 'assets/images/flow-belly/icons/slow-metabolism.svg'
import workPressure from 'assets/images/flow-belly/icons/work-pressure.svg'

export const QUESTION =
  'Have any of the following events led to weight gain in the last few years?'

export const SELECT_NONE = 'none'

export const OPTION_VALUES = [
  {
    title: 'onboarding.weightGainEvents.workPressure',
    value: 'workPressure',
    icon: workPressure,
  },
  {
    title: 'onboarding.weightGainEvents.busyLife',
    value: 'busyLife',
    icon: busyLife,
  },
  {
    title: 'onboarding.weightGainEvents.divorce',
    value: 'divorce',
    icon: divorce,
  },
  {
    title: 'onboarding.weightGainEvents.slowMetabolism',
    value: 'slowMetabolism',
    icon: slowMetabolism,
  },
  {
    title: 'onboarding.weightGainEvents.pregnancy',
    value: 'pregnancy',
    icon: pregnancy,
  },
  {
    title: 'onboarding.weightGainEvents.financialChallenges',
    value: 'financialChallenges',
    icon: financialChallenges,
  },
  {
    title: 'onboarding.weightGainEvents.covid',
    value: 'covid',
    icon: covid,
  },
  {
    title: 'onboarding.weightGainEvents.injury',
    value: 'injury',
    icon: injury,
  },
  {
    title: 'onboarding.weightGainEvents.other',
    value: 'other',
    icon: other,
  },
  {
    title: 'onboarding.weightGainEvents.none',
    value: SELECT_NONE,
    icon: none,
  },
]
