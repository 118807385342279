import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectIsStayFitFlow,
  selectSubscriptionList,
} from 'root-redux/selects/common'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { getUpsellPlans } from 'modules/purchase/helpers/rootHelpers'
import { PLANS_FLOW } from 'modules/purchase/pages/UpsellV2/constant'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import { selectCurrency } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import { Cohort } from 'root-constants'

import { UpsellSubscriptionItem } from '../UpsellSubscriptionItem'
import { StyledUpsellSubscriptionBlock as S } from './UpsellSubscriptionBlock.styles'
import {
  MAX_NUMBER_OF_SELECTED_SUBSCRIPTIONS,
  NUMBER_OF_SINGLE_SUBSCRIPTION,
} from './constants'

type TProps = {
  makeUpsell: () => void
  children?: React.ReactNode
}

export const UpsellSubscriptionBlock: FC<TProps> = ({
  makeUpsell,
  children,
}) => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const { search } = useLocation()

  const [isToggleOn, setIsToggleOn] = useState(false)
  const [selectedPrices, setSelectedPrices] = useState<string[]>([])

  const subscriptions = useSelector(selectSubscriptionList)
  const isStayFitGoal = useSelector(selectIsStayFitFlow)
  const currency = useSelector(selectCurrency)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohort = useSelector(selectCurrentVariantCohort)

  const cohortToUse = parentCohort || cohort

  const isSecondaryButtonVisible = useMemo(
    () => selectedPrices.length < MAX_NUMBER_OF_SELECTED_SUBSCRIPTIONS,
    [selectedPrices],
  )

  const isStayFitFlow = useMemo(
    () => cohortToUse !== Cohort.DB_FLOW_2 && isStayFitGoal,
    [cohortToUse, isStayFitGoal],
  )

  const { firstPlan, secondPlan, bothPlan } = getUpsellPlans(
    subscriptions,
    isStayFitFlow ? PLANS_FLOW.STAY_FIT : PLANS_FLOW.LOSE_WEIGHT,
  )

  const isOnePlanChosen = useMemo(
    () => selectedPrices.length === NUMBER_OF_SINGLE_SUBSCRIPTION,
    [selectedPrices.length],
  )

  useEffect(() => {
    if (firstPlan) {
      dispatch(setSelectedSubscriptionAction(firstPlan))
      setSelectedPrices((current) => [...current, firstPlan?.id])
    }
  }, [dispatch, firstPlan])

  useEffect(() => {
    const isSingleSubscriptionSelected =
      selectedPrices.length === NUMBER_OF_SINGLE_SUBSCRIPTION

    if (isSingleSubscriptionSelected) {
      const [selectedPriceId] = selectedPrices
      const selectedSubscription = subscriptions.find(
        (subscription) => subscription.id === selectedPriceId,
      )

      dispatch(
        setSelectedSubscriptionAction(selectedSubscription as ISubscription),
      )

      return
    }
    bothPlan && dispatch(setSelectedSubscriptionAction(bothPlan))
  }, [bothPlan, dispatch, selectedPrices, subscriptions])

  const handleChange = useCallback(
    ({ target: { value } }) => {
      const isPriceSelected = selectedPrices.includes(value)
      const selectedSubscription = subscriptions.find(
        (subscription) => subscription.id === value,
      )
      if (!isPriceSelected) {
        selectedSubscription &&
          eventLogger.logUpsellSubscriptionEnable(selectedSubscription?.product)
        setSelectedPrices((current) => [...current, value])

        return
      }
      selectedSubscription &&
        eventLogger.logUpsellSubscriptionDisable(selectedSubscription?.product)
      setSelectedPrices((current) => [...current.filter((id) => id !== value)])
    },
    [selectedPrices, subscriptions],
  )
  const handleContinue = useCallback(() => {
    goTo({
      pathname: PageId.LOGIN,
      search,
    })
  }, [search])

  const handleContinueWithAll = useCallback(() => {
    setIsToggleOn(true)

    bothPlan && dispatch(setSelectedSubscriptionAction(bothPlan))

    makeUpsell()
  }, [dispatch, bothPlan, makeUpsell])

  return (
    <S.Wrapper>
      <S.Discount>
        <S.DiscountBadge>
          <Trans
            i18nKey="upsellV2.discount.badge"
            components={{ discount: <div /> }}
          />
        </S.DiscountBadge>
        <S.DiscountText>
          <Trans
            i18nKey="upsellV2.discount.text"
            values={{
              currency: CURRENCY_SYMBOLS[currency],
              price: bothPlan?.mainPrices.fullPrice,
            }}
            components={[<br />]}
          />
        </S.DiscountText>
      </S.Discount>

      {firstPlan && (
        <UpsellSubscriptionItem
          key={firstPlan.product}
          subscription={firstPlan}
          onChange={handleChange}
          isSelected={selectedPrices.includes(firstPlan.id) || isToggleOn}
          value={firstPlan.id}
        />
      )}

      {secondPlan && (
        <UpsellSubscriptionItem
          key={secondPlan.product}
          subscription={secondPlan}
          onChange={handleChange}
          isSelected={selectedPrices.includes(secondPlan.id) || isToggleOn}
          value={secondPlan.id}
        />
      )}

      <S.ButtonContainer>
        <S.UpsellButton onClick={handleContinueWithAll}>
          <Trans i18nKey="upsellV2.acceptAll" />
        </S.UpsellButton>
      </S.ButtonContainer>

      {isSecondaryButtonVisible && (
        <S.ButtonContainer>
          <S.SecondaryButton
            onClick={!selectedPrices.length ? handleContinue : makeUpsell}
          >
            {!selectedPrices.length
              ? t('upsellV2.continueWithout')
              : t('upsellV2.acceptSelected')}
          </S.SecondaryButton>
        </S.ButtonContainer>
      )}
      {children}
      <S.UpsellToggleBenefitsContainer>
        <S.UpsellToggleBenefits title="upsellV2.upsellBenefitsTitle" />

        {isOnePlanChosen ? (
          <S.UpsellButton onClick={makeUpsell}>
            <Trans i18nKey="upsellV2.acceptOne" />
          </S.UpsellButton>
        ) : (
          <S.UpsellAcceptAllButton
            makeUpsell={makeUpsell}
            text="upsellV2.acceptAllLink"
          />
        )}
      </S.UpsellToggleBenefitsContainer>
    </S.Wrapper>
  )
}
