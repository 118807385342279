import React, { ButtonHTMLAttributes, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Button as StorybookButton, buttonTheme } from 'storybook-ui'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { Cohort } from 'root-constants'

import { StyledButton } from './Button.styles'

export interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonRef?: React.Ref<HTMLButtonElement>
  disabledBackgroundColor?: string
  margin?: string
  className?: string
}

export const Button: React.FC<IProps> = ({
  children,
  buttonRef = null,
  disabledBackgroundColor,
  margin,
  ...props
}) => {
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const isBellyFlow = useMemo(() => {
    const cohortToUse = parentCohort || cohort

    return cohortToUse === Cohort.DB_BELLY || cohortToUse === Cohort.DB_LEGS
  }, [cohort, parentCohort])

  if (isBellyFlow) {
    return (
      <StorybookButton {...props} margin={margin} theme={buttonTheme.DANCEBIT}>
        {children}
      </StorybookButton>
    )
  }

  return (
    <StyledButton
      ref={buttonRef}
      $disabledBackgroundColor={disabledBackgroundColor}
      {...props}
    >
      {children}
    </StyledButton>
  )
}
