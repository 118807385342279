import styled from 'styled-components'

import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { PageTitleDescription } from 'components/PageTitleDescription'
import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

export const StyledGoalWeightBelly = {
  Form: styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  WeightLabel: styled.div`
    width: 72px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 16px;
    border-radius: 12px;
    padding: 8px 24px;
    background-color: ${Color.PRIMARY};
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    text-transform: uppercase;
    color: ${Color.WHITE};
    letter-spacing: 0.5px;
  `,
  Subtitle: styled(PageTitleDescription)`
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.DARK};
    text-align: center;
  `,
  WeightDisclaimer: styled.div<{ $bgColor: string }>`
    height: 106px;
    width: 100%;
    border-radius: 12px;
    background: ${({ $bgColor }) => $bgColor};
    padding: 12px 16px;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 16px;
  `,
  Description: styled.p`
    opacity: 0.5;
    margin-top: 2px;
    font-size: 14px;
  `,
  Title: styled.p<{ $color: string }>`
    color: ${({ $color }) => $color};
    font-weight: 900;
    line-height: 20px;
    margin-bottom: 6px;
  `,
  Icon: styled(SvgImage)`
    margin-right: 4px;
    margin-bottom: -1px;
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)`
    border-top: none;
    padding-top: 24px;
  `,
}
