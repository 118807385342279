import styled from 'styled-components'

import { Color } from 'root-constants'

type TWrapperProps = {
  $isSelected: boolean
}

type TPlanNameProps = {
  $backgroundColor: string
}

type TCurrentPriceProps = {
  $paddingBottom: number
}

export const StyledSubscriptionItemNewYear = {
  Wrapper: styled.div<TWrapperProps>`
    max-width: 106px;
    min-height: 148px;
    padding: 10px 6px;
    border-radius: 16px;
    background: ${Color.LAVENDER_LIGHT};
    border: 3px solid #eff2f5;
    border: ${({ $isSelected }) =>
      $isSelected ? '3px solid #03aaf5' : '3px solid #eff2f5'};
  `,
  PlanPeriod: styled.p`
    padding-bottom: 4px;
    font-weight: 800;
    font-size: 12px;
    line-height: 14px;
  `,
  OldPrice: styled.p`
    padding-bottom: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-decoration-line: line-through;
    color: #ed3423;
  `,
  CurrentPrice: styled.p<TCurrentPriceProps>`
    padding-bottom: ${({ $paddingBottom }) => `${$paddingBottom}px`};
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  `,
  PlanName: styled.p<TPlanNameProps>`
    min-width: 88px;
    margin-bottom: 14px;
    padding: 4px 0;
    background-color: ${({ $backgroundColor }) => $backgroundColor};
    border-radius: 4px;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: ${Color.WHITE};
  `,
  Disclaimer: styled.p`
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #a7adb6;
  `,
}
