import { useLayoutEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { sendUserTTCLID } from 'root-redux/actions/user'
import { selectTiktokPixelId } from 'root-redux/selects/common'
import {
  selectUUID,
  selectUserCountryCode,
  selectUserOnboardingEmail,
} from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'

export const useTiktokPixelInitialization = (): void => {
  const dispatch = useDispatch()
  const tiktokPixelId = useSelector(selectTiktokPixelId)
  const email = useSelector(selectUserOnboardingEmail)
  const uuid = useSelector(selectUUID)
  const userCountryCode = useSelector(selectUserCountryCode)

  const {
    isEUUser,
    hasAdvertizingAndMarketingCookie,
    hasFunctionalCookie,
    userCookieConsentAnswer,
  } = useCookieConsentAnswer()

  const shouldTiktokPixelBePaused = useMemo(
    () =>
      (isEUUser && !userCookieConsentAnswer?.length) ||
      (isEUUser && !hasAdvertizingAndMarketingCookie),
    [isEUUser, userCookieConsentAnswer, hasAdvertizingAndMarketingCookie],
  )

  const isPersonalDataAllowed = useMemo(
    () => (isEUUser && hasFunctionalCookie) || !isEUUser,
    [isEUUser, hasFunctionalCookie],
  )

  useLayoutEffect(() => {
    if (!tiktokPixelId || !userCountryCode || shouldTiktokPixelBePaused) return

    window.ttq.load(tiktokPixelId)
    window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
    window.ttq.page({ event_id: uuid })

    const searchParams = new URLSearchParams(document.location.search)
    const ttclid = searchParams.get('ttclid')

    if (ttclid) dispatch(sendUserTTCLID(ttclid))
  }, [
    uuid,
    email,
    tiktokPixelId,
    dispatch,
    userCountryCode,
    shouldTiktokPixelBePaused,
    isPersonalDataAllowed,
  ])
}
