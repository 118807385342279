/* eslint-disable max-lines */
import { Download } from 'pages/download'
import { Email, EmailBelly, EmailNoOnboarding } from 'pages/email'
import { EmailConsent, EmailConsentBelly } from 'pages/emailConsent'
import { IntroNewYear } from 'pages/intro'
import { Prepaywall } from 'pages/prepaywall'
import {
  ActivityLevel,
  AdditionalGoals,
  Age,
  AgeBelly,
  BestShape,
  BoostUnlocked,
  Confident,
  CurrentHeight,
  CurrentHeightBelly,
  CurrentWeight,
  CurrentWeightBelly,
  DailyWater,
  DancingLevel,
  DancingLevelBelly,
  DancingStyle,
  DancingStyleBelly,
  DancingStylesIntro,
  DancingVsExercises,
  DancingVsExercisesBelly,
  DesignNewLife,
  Diet,
  DreamBody,
  Energy,
  EnergyBelly,
  EventDate,
  FitnessProfile,
  ForgetPlanks,
  Gender,
  GoalWeight,
  GoalWeightBelly,
  HowLongTrain,
  HowMotivated,
  HowQuickly,
  ImproveAreas,
  InfluencerWelcome,
  LessonLength,
  Lifestyle,
  MainGoal,
  MainGoal2024,
  MainGoalAgeFlow,
  MainGoalBelly,
  MainGoalInNewYear,
  MainGoalSomatic,
  MainGoalVideo,
  MotivatingEvent,
  Motivation,
  MotivationBelly,
  Name,
  NameBelly,
  NiceToMeet,
  OptionsLeftToRight,
  PersonalHistory,
  PhysicalBuild,
  PlanPageIsReady,
  PreparePlan,
  PreparePlanBelly,
  PreparePlanNewYear,
  Reach,
  Sleep,
  SocialProof,
  TenMillionPeople,
  TenMillionPeopleBelly,
  TimesPerWeek,
  TryWorkouts,
  UpgradeLife,
  WeightFluctuations,
  WeightGainEvents,
  Welcome,
  WelcomeBelly,
  WelcomeWeightLoss,
  WhatsHoldYou,
  WorkSchedule,
  WorkScheduleBelly,
  WorldwideAchieving,
  WorldwideAchievingBelly,
} from 'pages/questions'
import { PageWithImage } from 'pages/questions/Dynamic'
import { HealthCondition } from 'pages/questions/HealthCondition'
import { HealthConditionAgeFlow } from 'pages/questions/HealthConditionAgeFlow'
import { Result } from 'pages/result'

import { Login } from 'modules/login'
import { CancelOfferQuestion } from 'modules/purchase/pages/CancelOfferQuestion'
import { CheckoutNoOnboarding } from 'modules/purchase/pages/CheckoutNoOnboarding'
import { ChooseTrialPrice } from 'modules/purchase/pages/ChooseTrialPrice'
import { PurchaseCancelOffer } from 'modules/purchase/pages/PurchaseCancelOffer'
import { PurchaseCancelOfferMealPlan } from 'modules/purchase/pages/PurchaseCancelOfferMealPlan'
import { PurchaseCancelOfferRef } from 'modules/purchase/pages/PurchaseCancelOfferRef'
import { PurchaseDop } from 'modules/purchase/pages/PurchaseDop'
import { PurchaseIntroOffer } from 'modules/purchase/pages/PurchaseIntroOffer'
import { PurchaseNewYear } from 'modules/purchase/pages/PurchaseNewYear'
import { PurchaseNoOnboarding } from 'modules/purchase/pages/PurchaseNoOnboarding'
import { PurchaseProvider } from 'modules/purchase/pages/PurchaseProvider'
import { PurchaseRef } from 'modules/purchase/pages/PurchaseRef'
import { PurchaseTrial } from 'modules/purchase/pages/PurchaseTrial'
import { PurchaseTwoPlans } from 'modules/purchase/pages/PurchaseTwoPlans'
import { PurchaseUpgrade } from 'modules/purchase/pages/PurchaseUpgrade'
import { PurchaseUpgradeMealplan } from 'modules/purchase/pages/PurchaseUpgradeMealplan'
import { PurchaseVariant1 } from 'modules/purchase/pages/PurchaseVariant1'
import { PurchaseVariant2 } from 'modules/purchase/pages/PurchaseVariant2'
import { PurchaseVariantEmail } from 'modules/purchase/pages/PurchaseVariantEmail'
import { PurchaseWeeklyOffer } from 'modules/purchase/pages/PurchaseWeeklyOffer'
import { PurchaseWithoutIntro } from 'modules/purchase/pages/PurchaseWithoutIntro'
import { PurchaseWithoutIntro2 } from 'modules/purchase/pages/PurchaseWithoutIntro2'
import { Upsell } from 'modules/purchase/pages/Upsell'
import { UpsellV2 } from 'modules/purchase/pages/UpsellV2'

import { TIdToPageMap } from 'models/common.model'

import { GROWTHBOOK_EXPERIMENT } from 'root-constants'

export const enum CustomPageId {}

export const enum PageId {
  MAIN_GOAL_AGE_FLOW = 'mainGoalAgeFlow',
  WELCOME = 'welcome',
  PERSONAL_HISTORY = 'personalHistory',
  INFLUENCER = 'influencerWelcome',
  INTRO_NEW_YEAR = 'introNewYear',
  MAIN_GOAL = 'mainGoal',
  MAIN_GOAL_2024 = 'mainGoal2024',
  MAIN_GOAL_SOMATIC = 'mainGoalSomatic',
  MAIN_GOAL_VIDEO = 'mainGoalVideo',
  MAIN_GOAL_IN_NEW_YEAR = 'mainGoalInNewYear',
  GENDER = 'gender',
  IMPROVE_AREAS = 'improveAreas',
  NAME = 'name',
  NICE_TO_MEET = 'niceToMeet',
  CURRENT_WEIGHT = 'currentWeight',
  CURRENT_HEIGHT = 'currentHeight',
  GOAL_WEIGHT = 'goalWeight',
  AGE = 'age',
  DANCING_LEVEL = 'dancingLevel',
  ENERGY = 'energy',
  DIET = 'diet',
  DANCING_STYLES_INTRO = 'dancingStylesIntro',
  DANCING_STYLE = 'dancingStyle',
  DANCE_VS_EXERCISES = 'dancingVsExercises',
  LESSON_LENGTH = 'lessonLength',
  MOTIVATION = 'motivation',
  PREPARE_PLAN = 'preparePlan',
  PREPARE_PLAN_NEW_YEAR = 'preparePlanNewYear',
  ENTER_EMAIL = 'enterEmail',
  ENTER_EMAIL_NO_ONBOARDING = 'enterEmailNoOnboarding',
  TEN_MILLION_PEOPLE = 'tenMillionPeople',
  ACTIVITY_LEVEL = 'activityLevel',
  BONES_PROBLEMS = 'bonesProblems',
  HEALTH_CONDITION = 'healthCondition',
  HOW_QUICKLY = 'howQuickly',
  SOCIAL_PROOF = 'socialProof',
  ADDITIONAL_GOALS = 'additionalGoals',
  WHATS_HOLD_YOU = 'whatsHoldYou',
  WORLDWIDE_ACHIEVING = 'worldwideAchieving',
  WORK_SCHEDULE = 'workSchedule',
  RESULT = 'result',
  PURCHASE = 'purchase',
  PURCHASE_TRIAL = 'purchaseTrial',
  PURCHASE_NO_ONBOARDING = 'purchaseNoOnboarding',
  PURCHASE_NEW_YEAR = 'purchaseNewYear',
  PURCHASE_EMAIL = 'purchaseEmail',
  PURCHASE_CANCEL_OFFER = 'purchaseCancelOffer',
  PURCHASE_CANCEL_OFFER_REF = 'purchaseCancelOfferRef',
  PURCHASE_CANCEL_OFFER_MEAL_PLAN = 'purchaseCancelOfferMealPlan',
  PURCHASE_INTRO_OFFER = 'purchaseIntroOffer',
  PURCHASE_PROVIDER = 'purchaseProvider',
  PURCHASE_DOP = 'purchaseDop',
  PURCHASE_REF = 'purchaseRef',
  PURCHASE_WITHOUT_INTRO = 'purchaseWithoutIntro',
  PURCHASE_WITHOUT_INTRO2 = 'purchaseWithoutIntro2',
  PURCHASE_WEEKLY_OFFER = 'purchaseWeeklyOffer',
  PURCHASEV2 = 'purchaseV2',
  PURCHASE_TWO_PLANS = 'purchaseTwoPlans',
  PURCHASE_UPGRADE_PDF = 'purchaseUpgradePdf',
  PURCHASE_UPGRADE = 'purchaseUpgrade',
  PURCHASE_UPGRADE_MEAL_PLAN = 'purchaseUpgradeMealplan',
  UPGRADE_LIFE = 'upgradeLife',
  CHOOSE_TRIAL_PRICE = 'choose_trial_price',
  CANCEL_OFFER_QUESTION = 'cancelOfferQuestion',
  CHECKOUT_NO_ONBOARDING = 'checkoutNoOnboarding',
  UPSELL = 'upsell',
  UPSELLV2 = 'upsellV2',
  UPSELL_CANCEL_OFFER = 'upsellCancelOffer',
  UPSELL_LONG = 'upsellLong',
  LOGIN = 'login',
  EMAIL_CONSENT = 'emailConsent',
  DOWNLOAD = 'download',
  PROXY_TO_START = 'proxyToStart',
  PREPAYWALL = 'prepaywall',
  WELCOME_WEIGHT_LOSS = 'welcomeWeightLoss',
  PHYSICAL_BUILD = 'physicalBuild',
  TRY_WORKOUTS = 'tryWorkouts',
  FORGET_PLANKS = 'forgetPlanks',
  DREAM_BODY = 'dreamBody',
  BEST_SHAPE = 'bestShape',
  WEIGHT_FLUCTUATIONS = 'weightFluctuations',
  HOW_LONG_TRAIN = 'howLongTrain',
  WORK_SCHEDULE_BELLY = 'workSchedule',
  TIMES_PER_WEEK = 'timesPerWeek',
  LIFE_STYLE = 'lifestyle',
  ENERGY_BELLY = 'energyBelly',
  DAILY_WATER = 'dailyWater',
  SLEEP = 'sleep',
  DESIGN_NEW_LIFE = 'designNewLife',
  WEIGHT_GAIN_EVENTS = 'weightGainEvents',
  AGE_BELLY = 'ageBelly',
  PLAN_IS_READY = 'yourPlanIsReady',
  EVENT_DATE = 'eventDate',
  REACH = 'reach',
  CONFIDENT = 'confident',
  FITNESS_PROFILE = 'fitnessProfile',
  HOW_MOTIVATED = 'howMotivated',
  BOOST_UNLOCKED = 'boostUnlocked',
  MOTIVATING_EVENT = 'motivatingEvent',
}

export const ID_TO_PAGE_MAP: TIdToPageMap = {
  dynamic_options: OptionsLeftToRight,
  dynamic_image: PageWithImage,
  introNewYear: IntroNewYear,
  mainGoal: MainGoal,
  mainGoal2024: MainGoal2024,
  mainGoalInNewYear: MainGoalInNewYear,
  mainGoalSomatic: MainGoalSomatic,
  mainGoalVideo: MainGoalVideo,
  gender: Gender,
  improveAreas: ImproveAreas,
  name: Name,
  niceToMeet: NiceToMeet,
  currentWeight: CurrentWeight,
  currentHeight: CurrentHeight,
  goalWeight: GoalWeight,
  age: Age,
  dancingLevel: DancingLevel,
  energy: Energy,
  diet: Diet,
  dancingStylesIntro: DancingStylesIntro,
  dancingStyle: DancingStyle,
  dancingVsExercises: DancingVsExercises,
  lessonLength: LessonLength,
  motivation: Motivation,
  preparePlan: PreparePlan,
  preparePlanNewYear: PreparePlanNewYear,
  enterEmail: Email,
  enterEmailNoOnboarding: EmailNoOnboarding,
  result: Result,
  prepaywall: Prepaywall,
  choose_trial_price: ChooseTrialPrice,
  purchase: PurchaseVariant1,
  purchaseTrial: PurchaseTrial,
  purchaseNoOnboarding: PurchaseNoOnboarding,
  purchaseNewYear: PurchaseNewYear,
  purchaseEmail: PurchaseVariantEmail,
  cancelOfferQuestion: CancelOfferQuestion,
  purchaseCancelOffer: PurchaseCancelOffer,
  purchaseCancelOfferRef: PurchaseCancelOfferRef,
  purchaseCancelOfferMealPlan: PurchaseCancelOfferMealPlan,
  purchaseIntroOffer: PurchaseIntroOffer,
  checkoutNoOnboarding: CheckoutNoOnboarding,
  upsell: Upsell,
  upsellV2: UpsellV2,
  login: Login,
  emailConsent: EmailConsent,
  download: Download,
  purchaseUpgrade: PurchaseUpgrade,
  purchaseUpgradePdf: PurchaseUpgrade,
  purchaseWithoutIntro: PurchaseWithoutIntro,
  purchaseWithoutIntro2: PurchaseWithoutIntro2,
  purchaseWeeklyOffer: PurchaseWeeklyOffer,
  mainGoalAgeFlow: MainGoalAgeFlow,
  welcome: Welcome,
  purchaseProvider: PurchaseProvider,
  purchaseDop: PurchaseDop,
  personalHistory: PersonalHistory,
  tenMillionPeople: TenMillionPeople,
  activityLevel: ActivityLevel,
  healthCondition: HealthConditionAgeFlow,
  howQuickly: HowQuickly,
  influencerWelcome: InfluencerWelcome,
  socialProof: SocialProof,
  purchaseV2: PurchaseVariant2,
  additionalGoals: AdditionalGoals,
  whatsHoldYou: WhatsHoldYou,
  worldwideAchieving: WorldwideAchieving,
  purchaseUpgradeMealplan: PurchaseUpgradeMealplan,
  workSchedule: WorkSchedule,
  upgradeLife: UpgradeLife,
  purchaseTwoPlans: PurchaseTwoPlans,
  welcomeWeightLoss: WelcomeWeightLoss,
  purchaseRef: PurchaseRef,
  howMotivated: HowMotivated,
  boostUnlocked: BoostUnlocked,
}
export const ID_TO_PAGE_MAP_V_2: TIdToPageMap = {
  dynamic_options: OptionsLeftToRight,
  dynamic_image: PageWithImage,
  welcome: WelcomeBelly,
  tenMillionPeople: TenMillionPeopleBelly,
  mainGoal: MainGoalBelly,
  physicalBuild: PhysicalBuild,
  dreamBody: DreamBody,
  bestShape: BestShape,
  weightFluctuations: WeightFluctuations,
  tryWorkouts: TryWorkouts,
  forgetPlanks: ForgetPlanks,
  timesPerWeek: TimesPerWeek,
  howLongTrain: HowLongTrain,
  healthCondition: HealthCondition,
  workSchedule: WorkScheduleBelly,
  lifestyle: Lifestyle,
  energy: EnergyBelly,
  dailyWater: DailyWater,
  sleep: Sleep,
  upgradeLife: DesignNewLife,
  dancingLevel: DancingLevelBelly,
  dancingStyle: DancingStyleBelly,
  dancingVsExercises: DancingVsExercisesBelly,
  weightGainEvents: WeightGainEvents,
  motivation: MotivationBelly,
  worldwideAchieving: WorldwideAchievingBelly,
  currentHeight: CurrentHeightBelly,
  currentWeight: CurrentWeightBelly,
  goalWeight: GoalWeightBelly,
  age: AgeBelly,
  fitnessProfile: FitnessProfile,
  motivatingEvent: MotivatingEvent,
  eventDate: EventDate,
  reach: Reach,
  confident: Confident,
  introNewYear: IntroNewYear,
  danceStyles: DancingStyle,
  gender: Gender,
  improveAreas: ImproveAreas,
  yourPlanIsReady: PlanPageIsReady,
  niceToMeet: NiceToMeet,
  diet: Diet,
  dancingStylesIntro: DancingStylesIntro,
  lessonLength: LessonLength,
  preparePlan: PreparePlanBelly,
  boostUnlocked: BoostUnlocked,
  enterEmail: EmailBelly,
  name: NameBelly,
  result: Result,
  prepaywall: Prepaywall,
  cancelOfferQuestion: CancelOfferQuestion,
  purchaseCancelOffer: PurchaseCancelOffer,
  purchaseCancelOfferMealPlan: PurchaseCancelOfferMealPlan,
  purchaseIntroOffer: PurchaseIntroOffer,
  upsell: Upsell,
  upsellV2: UpsellV2,
  login: Login,
  emailConsent: EmailConsentBelly,
  download: Download,
  purchaseUpgrade: PurchaseUpgrade,
  purchaseUpgradePdf: PurchaseUpgrade,
  purchaseWithoutIntro: PurchaseWithoutIntro,
}

export const DYNAMIC_PAGES_SET_VARIANT_1_C: PageId[] = [
  PageId.MAIN_GOAL,
  PageId.ADDITIONAL_GOALS,
  PageId.WHATS_HOLD_YOU,
  PageId.WORLDWIDE_ACHIEVING,
  PageId.GENDER,
  PageId.IMPROVE_AREAS,
  PageId.NAME,
  PageId.NICE_TO_MEET,
  PageId.CURRENT_HEIGHT,
  PageId.CURRENT_WEIGHT,
  PageId.GOAL_WEIGHT,
  PageId.AGE,
  PageId.SOCIAL_PROOF,
  PageId.DANCING_LEVEL,
  PageId.DANCING_STYLES_INTRO,
  PageId.DANCING_STYLE,
  PageId.DANCE_VS_EXERCISES,
  PageId.ACTIVITY_LEVEL,
  PageId.WORK_SCHEDULE,
  PageId.ENERGY,
  PageId.UPGRADE_LIFE,
  PageId.LESSON_LENGTH,
  PageId.MOTIVATION,
  PageId.PREPARE_PLAN,
  PageId.ENTER_EMAIL,
  PageId.EMAIL_CONSENT,
  PageId.PREPAYWALL,
  PageId.PURCHASE_WITHOUT_INTRO,
  PageId.CANCEL_OFFER_QUESTION,
  PageId.PURCHASE_CANCEL_OFFER,
]
export const DYNAMIC_PAGES_SET_VARIANT_1_D: PageId[] = [
  PageId.WELCOME,
  PageId.GENDER,
  PageId.TEN_MILLION_PEOPLE,
  PageId.MAIN_GOAL_AGE_FLOW,
  PageId.IMPROVE_AREAS,
  PageId.CURRENT_HEIGHT,
  PageId.CURRENT_WEIGHT,
  PageId.GOAL_WEIGHT,
  PageId.AGE,
  PageId.NAME,
  PageId.NICE_TO_MEET,
  PageId.ACTIVITY_LEVEL,
  PageId.HEALTH_CONDITION,
  PageId.DANCING_LEVEL,
  PageId.ENERGY,
  PageId.DANCING_STYLES_INTRO,
  PageId.DANCING_STYLE,
  PageId.DANCE_VS_EXERCISES,
  PageId.LESSON_LENGTH,
  PageId.HOW_QUICKLY,
  PageId.HOW_MOTIVATED,
  PageId.BOOST_UNLOCKED,
  PageId.PREPARE_PLAN,
  PageId.ENTER_EMAIL,
  PageId.EMAIL_CONSENT,
  PageId.RESULT,
  PageId.PURCHASE_WITHOUT_INTRO,
  PageId.CANCEL_OFFER_QUESTION,
  PageId.PURCHASE_CANCEL_OFFER,
]
export const DYNAMIC_PAGES_SET_VARIANT_1: PageId[] = [
  PageId.MAIN_GOAL,
  PageId.ADDITIONAL_GOALS,
  PageId.WHATS_HOLD_YOU,
  PageId.WORLDWIDE_ACHIEVING,
  PageId.GENDER,
  PageId.IMPROVE_AREAS,
  PageId.NAME,
  PageId.NICE_TO_MEET,
  PageId.CURRENT_HEIGHT,
  PageId.CURRENT_WEIGHT,
  PageId.GOAL_WEIGHT,
  PageId.AGE,
  PageId.SOCIAL_PROOF,
  PageId.DANCING_LEVEL,
  PageId.ENERGY,
  PageId.DANCING_STYLES_INTRO,
  PageId.DANCING_STYLE,
  PageId.DANCE_VS_EXERCISES,
  PageId.LESSON_LENGTH,
  PageId.MOTIVATION,
  PageId.PREPARE_PLAN,
  PageId.ENTER_EMAIL,
  PageId.EMAIL_CONSENT,
  PageId.PREPAYWALL,
  PageId.PURCHASE_WITHOUT_INTRO,
  PageId.CANCEL_OFFER_QUESTION,
  PageId.PURCHASE_CANCEL_OFFER,
]
export const DYNAMIC_PAGES_SET_VARIANT_2: PageId[] = [
  PageId.WELCOME,
  PageId.GENDER,
  PageId.TEN_MILLION_PEOPLE,
  PageId.MAIN_GOAL_AGE_FLOW,
  PageId.IMPROVE_AREAS,
  PageId.CURRENT_HEIGHT,
  PageId.CURRENT_WEIGHT,
  PageId.GOAL_WEIGHT,
  PageId.AGE,
  PageId.NAME,
  PageId.NICE_TO_MEET,
  PageId.ACTIVITY_LEVEL,
  PageId.HEALTH_CONDITION,
  PageId.DANCING_LEVEL,
  PageId.ENERGY,
  PageId.DANCING_STYLES_INTRO,
  PageId.DANCING_STYLE,
  PageId.DANCE_VS_EXERCISES,
  PageId.LESSON_LENGTH,
  PageId.HOW_QUICKLY,
  PageId.PREPARE_PLAN,
  PageId.ENTER_EMAIL,
  PageId.EMAIL_CONSENT,
  PageId.RESULT,
  PageId.PURCHASE_WITHOUT_INTRO,
  PageId.CANCEL_OFFER_QUESTION,
  PageId.PURCHASE_CANCEL_OFFER,
]

export const DYNAMIC_PAGES_SET_VARIANT_3: PageId[] = [
  PageId.WELCOME,
  PageId.TEN_MILLION_PEOPLE,
  PageId.MAIN_GOAL,
  PageId.PHYSICAL_BUILD,
  PageId.DREAM_BODY,
  PageId.BEST_SHAPE,
  PageId.WEIGHT_FLUCTUATIONS,
  PageId.TRY_WORKOUTS,
  PageId.FORGET_PLANKS,
  PageId.TIMES_PER_WEEK,
  PageId.HOW_LONG_TRAIN,
  PageId.HEALTH_CONDITION,
  PageId.WORK_SCHEDULE,
  PageId.LIFE_STYLE,
  PageId.ENERGY,
  PageId.DAILY_WATER,
  PageId.SLEEP,
  PageId.UPGRADE_LIFE,
  PageId.DANCING_LEVEL,
  PageId.DANCING_STYLE,
  PageId.DANCE_VS_EXERCISES,
  PageId.WEIGHT_GAIN_EVENTS,
  PageId.MOTIVATION,
  PageId.WORLDWIDE_ACHIEVING,
  PageId.CURRENT_HEIGHT,
  PageId.CURRENT_WEIGHT,
  PageId.GOAL_WEIGHT,
  PageId.AGE,
  PageId.FITNESS_PROFILE,
  PageId.MOTIVATING_EVENT,
  PageId.EVENT_DATE,
  PageId.REACH,
  PageId.CONFIDENT,
  PageId.PREPARE_PLAN,
  PageId.ENTER_EMAIL,
  PageId.EMAIL_CONSENT,
  PageId.NAME,
  PageId.PLAN_IS_READY,
  PageId.PURCHASE_WITHOUT_INTRO,
  PageId.CANCEL_OFFER_QUESTION,
  PageId.PURCHASE_CANCEL_OFFER,
]

export const DYNAMIC_PAGES_SET_VARIANT_3_B: PageId[] = [
  PageId.WELCOME,
  PageId.TEN_MILLION_PEOPLE,
  PageId.MAIN_GOAL,
  PageId.PHYSICAL_BUILD,
  PageId.DREAM_BODY,
  PageId.BEST_SHAPE,
  PageId.WEIGHT_FLUCTUATIONS,
  PageId.TRY_WORKOUTS,
  PageId.FORGET_PLANKS,
  PageId.TIMES_PER_WEEK,
  PageId.HOW_LONG_TRAIN,
  PageId.HEALTH_CONDITION,
  PageId.WORK_SCHEDULE,
  PageId.LIFE_STYLE,
  PageId.ENERGY,
  PageId.DAILY_WATER,
  PageId.SLEEP,
  PageId.UPGRADE_LIFE,
  PageId.DANCING_LEVEL,
  PageId.DANCING_STYLE,
  PageId.DANCE_VS_EXERCISES,
  PageId.WEIGHT_GAIN_EVENTS,
  PageId.MOTIVATION,
  PageId.WORLDWIDE_ACHIEVING,
  PageId.CURRENT_HEIGHT,
  PageId.CURRENT_WEIGHT,
  PageId.GOAL_WEIGHT,
  PageId.AGE,
  PageId.FITNESS_PROFILE,
  PageId.MOTIVATING_EVENT,
  PageId.EVENT_DATE,
  PageId.REACH,
  PageId.CONFIDENT,
  PageId.BOOST_UNLOCKED,
  PageId.PREPARE_PLAN,
  PageId.ENTER_EMAIL,
  PageId.EMAIL_CONSENT,
  PageId.NAME,
  PageId.PLAN_IS_READY,
  PageId.PURCHASE_WITHOUT_INTRO,
  PageId.CANCEL_OFFER_QUESTION,
  PageId.PURCHASE_CANCEL_OFFER,
]

export const FEATURE_ID_TO_PAGES_MAP: Record<string, PageId[]> = {
  [GROWTHBOOK_EXPERIMENT.DAN_340]: DYNAMIC_PAGES_SET_VARIANT_1_C,
  [GROWTHBOOK_EXPERIMENT.DAN_734]: DYNAMIC_PAGES_SET_VARIANT_1_D,
  [GROWTHBOOK_EXPERIMENT.DAN_802]: DYNAMIC_PAGES_SET_VARIANT_3_B,
}

export const FEATURE_ID_TO_DEFAULT_PAGES_MAP: Record<string, PageId[]> = {
  [GROWTHBOOK_EXPERIMENT.DAN_340]: DYNAMIC_PAGES_SET_VARIANT_1,
  [GROWTHBOOK_EXPERIMENT.DAN_734]: DYNAMIC_PAGES_SET_VARIANT_2,
  [GROWTHBOOK_EXPERIMENT.DAN_802]: DYNAMIC_PAGES_SET_VARIANT_3,
}
