import { FC } from 'react'

import { PaymentInstrumentType } from '@primer-io/checkout-web'

import { SubscriptionItem } from 'modules/purchase/components/SubscriptionItem'
import { SubscriptionItemIntroOffer } from 'modules/purchase/components/SubscriptionItemIntroOffer'
import { SubscriptionItemIntroOfferV2 } from 'modules/purchase/components/SubscriptionItemIntroOfferV2'
import { SubscriptionItemVariant2 } from 'modules/purchase/components/SubscriptionItemVariant2'
import { TSubscriptionItemProps } from 'modules/purchase/types'

import { Cohort } from 'root-constants'

export const FIFTEEN_MINUTES_IN_SECONDS = 900
export const ONE_HUNDRED_PERCENTS = 100
export const DEFAULT_DYNAMIC_DISCOUNT = 50
export const DEFAULT_CANCEL_OFFER_DYNAMIC_DISCOUNT = 60
export const DECORATING_DISCOUNT = 0.01
export const CANCEL_OFFER_ADDITIONAL_DISCOUNT = 5

export const enum AnimationState {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export const EMPTY_FIELD_ERROR = `can't be blank`

export const DEFAULT_CARDHOLDER_NAME = ' '

export const DEFAULT_ERROR_DESCRIPTION =
  'Please check your payment information or contact your bank and try again, or try a new payment method.'

export const enum StripeErrorCode {
  CARD_DECLINED = 'card_declined',
}

export const enum PaymentMethod {
  CREDIT_CARD = 'credit_card',
  APPLE_PAY = 'apple_pay',
  GOOGLE_PAY = 'google_pay',
  PAYPAL = 'pay_pal',
}

export const PrimerPaymentMethodsMap = {
  [PaymentInstrumentType.CARD]: PaymentMethod.CREDIT_CARD,
  [PaymentInstrumentType.APPLE_PAY]: PaymentMethod.APPLE_PAY,
  [PaymentInstrumentType.GOOGLE_PAY]: PaymentMethod.GOOGLE_PAY,
  [PaymentInstrumentType.PAYPAL]: PaymentMethod.PAYPAL,
  [PaymentInstrumentType.PAYPAL_VAULTED]: PaymentMethod.PAYPAL,
}

export const enum PaymentSystem {
  STRIPE = 'stripe',
  PRIMER = 'primer',
  PAYPAL = 'paypal',
}

export type TCardPaymentSystem = PaymentSystem.PRIMER | PaymentSystem.STRIPE

export const THREEDS_REDIRECT_SEARCH_PARAM = 'threeds'

export const STRIPE_IMAGE_PATH = '/images/stripe'

export const enum StripeSoftDeclineReason {
  AUTHENTICATION_FAILURE = 'payment_intent_authentication_failure',
  DO_NOT_HONOR = 'do_not_honor',
  INSUFFICIENT_FUNDS = 'insufficient_funds',
  INVALID_AMOUNT = 'invalid_amount',
  PROCESSING_ERROR = 'processing_error',
  REENTER_TRANSACTION = 'reenter_transaction',
  SERVICE_NOT_ALLOWED = 'service_not_allowed',
  TRANSACTION_NOT_ALLOWED = 'transaction_not_allowed',
  CARD_VELOCITY_EXCEEDED = 'card_velocity_exceeded',
}

export const enum Currency {
  USD = 'usd',
  EUR = 'eur',
  GBP = 'gbp',
  NZD = 'nzd',
  AUD = 'aud',
  CAD = 'cad',
  MXN = 'mxn',
}

export const enum GeneralProduct {
  SEVEN_DAY = 'sevenDay',
  ONE_MONTH = 'oneMonth',
  THREE_MONTHS = 'threeMonths',
  SIX_MONTHS = 'sixMonths',
  ONE_YEAR = 'oneYear',
}

export const enum PremiumProduct {
  BASIC = 'basicPlan',
  PREMIUM = 'premiumPlan',
}

export const CURRENCY_SYMBOLS = {
  [Currency.USD]: '$',
  [Currency.EUR]: '€',
  [Currency.GBP]: '£',
  [Currency.NZD]: 'NZ$',
  [Currency.AUD]: 'AU$',
  [Currency.CAD]: 'C$',
  [Currency.MXN]: 'MXN',
}

export const STRIPE_SOFT_DECLINE_REASONS = [
  StripeSoftDeclineReason.AUTHENTICATION_FAILURE,
  StripeSoftDeclineReason.DO_NOT_HONOR,
  StripeSoftDeclineReason.INSUFFICIENT_FUNDS,
  StripeSoftDeclineReason.INVALID_AMOUNT,
  StripeSoftDeclineReason.PROCESSING_ERROR,
  StripeSoftDeclineReason.REENTER_TRANSACTION,
  StripeSoftDeclineReason.SERVICE_NOT_ALLOWED,
  StripeSoftDeclineReason.TRANSACTION_NOT_ALLOWED,
  StripeSoftDeclineReason.CARD_VELOCITY_EXCEEDED,
]

export const PDF_COHORTS: Cohort[] = [
  Cohort.DANCEBIT_CHAT_UPGRADE_6_PDF,
  Cohort.DANCEBIT_CHAT_UPGRADE_9_PDF,
  Cohort.DANCEBIT_CHAT_UPGRADE_12_PDF,
]

export const countriesListIncludedVat: string[] = ['US', 'CA', 'AU', 'NZ']

export const countriesListWithExcludedVat: string[] = [
  'AT',
  'BE',
  'CY',
  'DE',
  'EE',
  'FI',
  'FR',
  'GR',
  'HR',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PT',
  'SK',
  'SI',
  'ES',
  'GB',
  'MX',
]

export const TRIAL_ONE_MONTH_DURATION = 30
export const WEEK_DURATION = 7

export type TSlide = {
  id: string
  imageUrl: string
  author: string
  review: string
  weight: string
}

export const enum PrimerErrorCode {
  CARD_REQUIRED = 'cardRequired',
  CARD_IS_INCOMPLETE = 'cardIncomplete',
  CARD_IS_INVALID = 'cardInvalid',
  EXPIRY_REQUIRED = 'expiryRequired',
  CARD_IS_EXPIRED = 'cardExpired',
  CARD_EXPIRY_YEAR_INVALID = 'expiryYearInvalid',
  CARD_EXPIRY_MONTH_INVALID = 'expiryMonthInvalid',
  CVV_IS_INVALID = 'cvvInvalid3',
  CVV_IS_INCOMPLETE = 'cvvIncomplete',
}

export const SUBSCRIPTION_ITEMS: Record<string, FC<TSubscriptionItemProps>> = {
  variant_1: SubscriptionItem,
  variant_2: SubscriptionItemVariant2,
  variant_3: SubscriptionItemIntroOffer,
  variant_4: SubscriptionItemIntroOfferV2,
}
