import styled from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

export const StyledMoneyBackNewYear = {
  Wrapper: styled.div`
    position: relative;
    margin-bottom: 64px;
    border: 2px solid #b3e5fc;
    border-radius: 24px;
  `,
  Title: styled.h2`
    padding-bottom: 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
  `,
  Content: styled.div`
    padding: 24px;
  `,
  Text: styled.p`
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.DISABLED};

    a {
      color: ${Color.DISABLED};
      text-decoration: underline;
    }
  `,
  Badge: styled(SvgImage)`
    position: absolute;
    bottom: -35px;
    right: -4px;
    width: 92px;
  `,
}
