import React, { FC, useCallback, useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

import { selectLanguage } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import branchSvg from 'assets/images/branch-blue.svg'
import mapImg from 'assets/images/world-map-belly.png'

import { CDN_FOLDER_LINK } from 'root-constants'

import { StyledWorldwideAchievingBelly as S } from './WorldwideAchievingBelly.styles'
import { QUESTION } from './constants'

export const WorldwideAchievingBelly: FC<TPageProps> = ({
  nextPagePath,
  pageId,
}) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)

  const animationRef = useRef<HTMLDivElement>(null)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  useEffect(() => {
    if (animationRef.current) {
      lottie.loadAnimation({
        container: animationRef.current,
        path: `${CDN_FOLDER_LINK}/animations/numbersAnimation_${language}.json`,
        loop: false,
        name: 'numbersAnimation',
      })
    }

    return () => lottie.destroy('numbersAnimation')
  }, [language])

  const handleClick = useCallback(() => handleContinue(''), [handleContinue])

  return (
    <>
      <ContainerBeforePinnedButton>
        <S.Root>
          <S.Container isLarge>
            <S.MapImg src={mapImg} />
            <S.UsersBlock ref={animationRef} />

            <S.Description>
              <Trans i18nKey="onboarding.worldwideAchieving.description" />
            </S.Description>

            <S.ReachedGoals>
              <S.Branch src={branchSvg} />
              <p>
                <Trans i18nKey="onboarding.worldwideAchieving.reachedGoals" />
              </p>
              <S.Branch src={branchSvg} />
            </S.ReachedGoals>
          </S.Container>
        </S.Root>
      </ContainerBeforePinnedButton>

      <NavigationButtonBelly
        type="button"
        onClick={handleClick}
      >{t`actions.soundsGood`}</NavigationButtonBelly>
    </>
  )
}
