import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAnswers } from 'root-redux/selects/common'

import {
  PLAN_HIGHLIGHTS_FULL,
  PLAN_HIGHLIGHTS_SHORT,
} from 'modules/purchase/components/PlanHighlightsNewYear/constants'
import { GeneralProduct } from 'modules/purchase/constants'
import { selectProductId } from 'modules/purchase/redux/selects/common'

import mealPlan from 'assets/images/newYearOnboarding/meal-plan.png'
import checkIcon from 'assets/images/sprite/check-icon-with-stroke.svg'

import { StyledPlanHighlightsNewYear as S } from './PlanHighlightsNewYear.styles'

export const PlanHighlightsNewYear: React.FC = () => {
  const { t } = useTranslation()
  const selectedProduct = useSelector(selectProductId)
  const userAnswers = useSelector(selectAnswers)

  const planHighlights = useMemo(
    () =>
      selectedProduct === GeneralProduct.THREE_MONTHS
        ? PLAN_HIGHLIGHTS_SHORT
        : PLAN_HIGHLIGHTS_FULL,
    [selectedProduct],
  )

  const weight = useMemo(() => {
    const goalWeight = userAnswers?.goalWeight
    const unit = t(`commonComponents.${userAnswers?.measurementSystem}`)

    return `${goalWeight} ${unit}`
  }, [t, userAnswers?.goalWeight, userAnswers?.measurementSystem])

  const hasMealPlan = useMemo(
    () => selectedProduct === GeneralProduct.ONE_YEAR,
    [selectedProduct],
  )

  if (!selectedProduct) return null

  return (
    <S.Wrapper>
      <S.Title>{t`purchaseNewYear.planHighlights.title`}</S.Title>

      {hasMealPlan && (
        <S.MealPlan>
          <S.Bonus>{t`purchaseNewYear.planHighlights.bonus`}</S.Bonus>
          <S.MealPlanCard>
            <S.MealPlanImage src={mealPlan} alt="meal-plan" />
            <S.TextContainer>
              <S.MealPlanTitle>{t`purchaseNewYear.planHighlights.mealPlanTitle`}</S.MealPlanTitle>
              <S.MealPlanText>{t`purchaseNewYear.planHighlights.mealPlanText`}</S.MealPlanText>
            </S.TextContainer>
          </S.MealPlanCard>
        </S.MealPlan>
      )}

      <S.List>
        <S.ListItem>
          <S.CheckIcon svg={checkIcon} />
          {t('purchaseNewYear.planHighlights.reachTarget', { weight })}
        </S.ListItem>
        {planHighlights.map(({ id, textPath }) => (
          <S.ListItem key={id}>
            <S.CheckIcon svg={checkIcon} />
            {t(textPath)}
          </S.ListItem>
        ))}
      </S.List>
    </S.Wrapper>
  )
}
