import styled from 'styled-components'

import { Button } from 'components/Button'

import background from 'assets/images/result-personal-card-bg.jpg'
import discountBadge from 'assets/images/upsell-discount-badge.png'

import { Color } from 'root-constants'

import { AcceptAllButton } from '../AcceptAllButton'
import { UpsellBenefits } from '../UpsellBenefits'

export const StyledUpsellSubscriptionBlock = {
  Wrapper: styled.div`
    margin-bottom: 24px;
  `,
  Discount: styled.div`
    position: relative;

    min-height: 68px;

    display: flex;
    align-items: center;

    margin-bottom: 16px;
    padding: 12px 20px 12px 95px;

    border-radius: 14px;
    border: 2px solid ${Color.PRIMARY};

    background-color: #f4faff;
  `,
  DiscountBadge: styled.div`
    position: absolute;
    left: 17px;
    top: -8px;

    max-width: 52px;
    min-height: 70px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 7px 3px 19px;

    background-image: url(${discountBadge});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    font-size: 20px;
    font-weight: 800;
    line-height: 22px;
    text-align: center;
    color: ${Color.WHITE};
  `,
  DiscountText: styled.p`
    font-size: 18px;
    font-weight: 800;
    line-height: 22px;
    color: ${Color.PRIMARY};
  `,
  ButtonContainer: styled.div`
    margin-bottom: 16px;
  `,
  UpsellButton: styled(Button)`
    max-width: none;
  `,
  SecondaryButton: styled(Button)`
    max-width: none;
    background: #e3e4e8;
  `,
  UpsellAcceptAllButton: styled(AcceptAllButton)`
    width: 100%;
  `,
  UpsellToggleBenefitsContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-image: url(${background});
    padding: 40px 16px;
    background-repeat: no-repeat;
    background-size: cover;

    border-radius: 24px;
  `,
  UpsellToggleBenefits: styled(UpsellBenefits)`
    margin: 0 auto 24px;
  `,
}
