import { GeneralProduct } from 'modules/purchase/constants'

import { AvailableAttempt, Cohort } from 'root-constants'

export const SUBSCRIPTION_ACCESS_POINT = {
  [Cohort.LUCKY_NY_2]: {
    [GeneralProduct.ONE_YEAR]: AvailableAttempt.TWO_ATTEMPTS,
    [GeneralProduct.SIX_MONTHS]: AvailableAttempt.NO_ATTEMPTS,
  },
  [Cohort.LUCKY_NY_3]: {
    [GeneralProduct.ONE_YEAR]: AvailableAttempt.TWO_ATTEMPTS,
    [GeneralProduct.SIX_MONTHS]: AvailableAttempt.ONE_ATTEMPT,
    [GeneralProduct.THREE_MONTHS]: AvailableAttempt.NO_ATTEMPTS,
  },
}

export const SUBSCRIPTION_ORDER = {
  [Cohort.LUCKY_NY_2]: {
    [GeneralProduct.ONE_YEAR]: 0,
    [GeneralProduct.SIX_MONTHS]: 3,
  },
  [Cohort.LUCKY_NY_3]: {
    [GeneralProduct.ONE_YEAR]: 1,
    [GeneralProduct.SIX_MONTHS]: 2,
    [GeneralProduct.THREE_MONTHS]: 3,
  },
}
