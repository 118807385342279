import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { CircleProgress } from 'storybook-ui'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import {
  selectCurrentUserMeasurementSystem,
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import ratingImage from 'assets/images/five-stars.png'
import firstReviewImg from 'assets/images/flow-belly/first-review.png'
import secondReviewImg from 'assets/images/flow-belly/second-review.png'
import thirdReviewImg from 'assets/images/flow-belly/third-review.png'

import { Cohort, Color } from 'root-constants'

import { StyledPreparePlanBelly as S } from './PreparePlanBelly.styles'
import { QUESTION } from './constants'

export const PreparePlanBelly: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const measurementSystem = useSelector(selectCurrentUserMeasurementSystem)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort

  const isLegsFlow = useMemo(() => cohortToUse === Cohort.DB_LEGS, [
    cohortToUse,
  ])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const goToNextPage = useCallback(() => {
    handleContinue(QUESTION)
  }, [handleContinue])

  const { counter } = useCounter(true, {
    duration: 6000,
    callback: goToNextPage,
  })

  return (
    <S.Container>
      <S.ProgressContainer>
        <CircleProgress
          value={counter}
          height="120px"
          margin="16px"
          strokeColorEmpty={Color.GRAY_10}
          strokeColorFilled={Color.PRIMARY}
          fontSize="24px"
          percentageFontSize="17px"
          fontWeight="700"
          percentageFontWeight="600"
        />
        <p>
          <Trans
            i18nKey={
              isLegsFlow
                ? 'onboarding.preparePlanV2.progressSubtitleLegs'
                : 'onboarding.preparePlanV2.progressSubtitle'
            }
          />
        </p>
      </S.ProgressContainer>

      <S.PeopleChooseTitle>
        <strong>{t`onboarding.tenMillionPeople.question`}</strong>
        <br />
        {t`onboarding.tenMillionPeople.subtitle`}
      </S.PeopleChooseTitle>

      <Carousel
        slidesPerView={1}
        loop
        autoplay={{ delay: 2000, disableOnInteraction: false }}
        spaceBetween={8}
      >
        <SwiperSlide>
          <S.Card>
            <S.ReviewTitle>
              <S.CardReviewerImage src={firstReviewImg} width={48} />
              <S.CardReviewer>
                {t`onboarding.preparePlanBelly.firstReviewer`}
                <S.CardRating src={ratingImage} alt="five stars rating" />
              </S.CardReviewer>
            </S.ReviewTitle>
            <S.CardReview>
              {t('onboarding.preparePlanV2.review', {
                context: measurementSystem,
              })}
            </S.CardReview>
          </S.Card>
        </SwiperSlide>
        <SwiperSlide>
          <S.Card>
            <S.ReviewTitle>
              <S.CardReviewerImage src={secondReviewImg} width={48} />
              <S.CardReviewer>
                {t`onboarding.preparePlanBelly.secondReviewer`}
                <S.CardRating src={ratingImage} alt="five stars rating" />
              </S.CardReviewer>
            </S.ReviewTitle>
            <S.CardReview>{t`onboarding.preparePlanBelly.secondReview`}</S.CardReview>
          </S.Card>
        </SwiperSlide>
        <SwiperSlide>
          <S.Card>
            <S.ReviewTitle>
              <S.CardReviewerImage src={thirdReviewImg} width={48} />
              <S.CardReviewer>
                {t`onboarding.preparePlanBelly.thirdReviewer`}
                <S.CardRating src={ratingImage} alt="five stars rating" />
              </S.CardReviewer>
            </S.ReviewTitle>
            <S.CardReview>{t`onboarding.preparePlanBelly.thirdReview`}</S.CardReview>
          </S.Card>
        </SwiperSlide>
      </Carousel>
    </S.Container>
  )
}
