import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { PrimerPaymentForm } from 'components/PrimerPaymentForm'
import { Spinner } from 'components/Spinner'

import {
  resetErrorAction,
  setSubscriptionListAction,
} from 'root-redux/actions/common'
import { selectCurrentVariantParentCohort } from 'root-redux/selects/common'

import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useHasPayPalButton } from 'hooks/useHasPayPalButton'
import { useVatInfo } from 'hooks/useVatInfo'

import { createProductId } from 'helpers/createProductId'
import { getCalculatedPrice } from 'helpers/getCalculatedPrice'

import { CreditCardFormCancelOffer } from 'modules/purchase/components/CreditCardFormCancelOffer'
import { PayPalButton } from 'modules/purchase/components/PayPalButton'
import { PaymentPrimerWaitingModal } from 'modules/purchase/components/PaymentPrimerWaitingModal'
import { PaymentRequestButton } from 'modules/purchase/components/PaymentRequestButton'
import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { Separator } from 'modules/purchase/components/Separator'
import { CURRENCY_SYMBOLS, GeneralProduct } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  SET_PAYMENT_FORM_IS_LOADING,
  setPrimerClientSessionTokenAction,
} from 'modules/purchase/redux/actions/common'
import { selectTaxAmount } from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import securitySystems from 'assets/images/security-systems.png'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  Cohort,
  GROWTHBOOK_EXPERIMENT,
  NEW_YEAR_COHORTS,
  SEVEN_DAY_TRIAL_DURATION,
  ScreenName,
} from 'root-constants'

import { StyledCheckoutIntroOffer as S } from './CheckoutIntroOffer.styles'

type TProps = {
  setIsCheckoutShown: (value: boolean) => void
  isCheckoutShown: boolean
  isCancelOfferPage?: boolean
}

export const CheckoutIntroOffer: React.FC<TProps> = ({
  setIsCheckoutShown,
  isCheckoutShown,
  isCancelOfferPage = false,
}) => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const dispatch = useDispatch()

  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const taxAmount = useSelector(selectTaxAmount)

  const creditCardPaymentRef = useRef<HTMLDivElement>(null)

  const {
    uuid,
    cohort,
    currency,
    fetchingActionsList,
    hasCancelOffer,
    oldTrialPriceBeforeCustomPercentDiscount,
    oldPriceBeforeCustomPercentDiscount,
    periodQuantity,
    periodName,
    fullPrice,
    fullPriceTax,
    selectedProductId,
    screenName,
    screenId,
    subscriptions,
    trialPrice,
    trialPeriodDays,
    trialPriceTax,
    threeDSecureIframeUrl,
    discountAmount,
    discountPercentage,
    countryCode,
    stripeAccountName,
    stripeAccountId,
    selectedSubscriptionId,
    selectedSubscription,
  } = usePurchaseStore()

  const cohortToUse = parentCohort || cohort
  const hasExcludedVat = useVatInfo(true)
  const hasVatInfo = useVatInfo()
  const hasPayPalButton = useHasPayPalButton()

  const isPrimerActive = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_8)

  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState(false)

  const productId = useMemo(
    () =>
      createProductId({
        periodName,
        periodQuantity,
        price: trialPrice || fullPrice,
      }),
    [periodName, periodQuantity, trialPrice, fullPrice],
  )

  const isCheckoutReady = useMemo(
    () =>
      !fetchingActionsList.includes(CHECK_PAYMENT_REQUEST_BUTTON) &&
      !fetchingActionsList.includes(SET_PAYMENT_FORM_IS_LOADING),
    [fetchingActionsList],
  )

  const hasSevenDayPlan = useMemo(
    () => periodQuantity === Number(SEVEN_DAY_TRIAL_DURATION),
    [periodQuantity],
  )

  const hasMealPlan = useMemo(
    () =>
      NEW_YEAR_COHORTS.includes(cohortToUse as Cohort) &&
      selectedProductId === GeneralProduct.ONE_YEAR,
    [cohortToUse, selectedProductId],
  )

  const hasDiscount = useMemo(() => {
    if (hasSevenDayPlan) {
      return false
    }

    return (
      !NEW_YEAR_COHORTS.includes(cohortToUse as Cohort) ||
      selectedProductId !== GeneralProduct.ONE_YEAR
    )
  }, [cohortToUse, hasSevenDayPlan, selectedProductId])

  const hasTotal = useMemo(() => {
    if (periodQuantity === Number(SEVEN_DAY_TRIAL_DURATION)) {
      return hasExcludedVat
    }

    return true
  }, [hasExcludedVat, periodQuantity])

  const oldTotalPrice = useMemo(() => {
    if (trialPeriodDays === Number(SEVEN_DAY_TRIAL_DURATION)) {
      return oldTrialPriceBeforeCustomPercentDiscount?.fullPrice.toFixed(2)
    }

    return fullPrice.toFixed(2)
  }, [trialPeriodDays, fullPrice, oldTrialPriceBeforeCustomPercentDiscount])

  useAmplitudeInitialization(cohort as Cohort, ScreenName.CHECKOUT)

  useEffect(() => {
    if (productId && isCheckoutShown && selectedSubscription) {
      eventLogger.logPurchaseShown({
        accountName: stripeAccountName,
        accountId: stripeAccountId,
        productId,
        screenName,
        screenId,
      })

      window.fbq('track', 'InitiateCheckout', {}, { eventID: uuid })
      googleAnalyticsLogger.logCheckoutStarted(subscriptions)
      window.obApi && window.obApi('track', 'Checkout')
      window._tfa &&
        window._tfa.push({
          notify: 'event',
          name: 'start_checkout',
        })
    }
  }, [
    uuid,
    subscriptions,
    selectedSubscription,
    stripeAccountId,
    stripeAccountName,
    productId,
    screenId,
    screenName,
    isCheckoutShown,
  ])

  useEffect(() => {
    const { current: elem } = creditCardPaymentRef

    if (elem && isCheckoutShown && isCheckoutReady) {
      elem.scrollIntoView(true)
    }
  }, [isCheckoutReady, isCheckoutShown])

  const handleCloseCheckout = useCallback(() => {
    dispatch(resetErrorAction())
    eventLogger.logPurchaseScreenClosed({
      productId,
      screenName,
      screenId,
    })

    dispatch(setPrimerClientSessionTokenAction(''))

    if (isCancelOfferPage || !hasCancelOffer) {
      setIsCheckoutShown(false)
      googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
      return
    }

    dispatch(setSubscriptionListAction([]))
    goTo({
      pathname: PageId.CANCEL_OFFER_QUESTION,
      search,
    })
  }, [
    dispatch,
    productId,
    screenName,
    screenId,
    isCancelOfferPage,
    hasCancelOffer,
    search,
    setIsCheckoutShown,
    pathname,
    cohort,
  ])

  const getCurrentPrice = useCallback(
    (value) =>
      hasVatInfo ? getCalculatedPrice(value, taxAmount, countryCode) : value,
    [countryCode, hasVatInfo, taxAmount],
  )

  return (
    <>
      {threeDSecureIframeUrl ? (
        <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
      ) : (
        <>
          <S.Wrapper $isVisible={isCheckoutReady}>
            <S.Title>{t`purchase1.checkout.title`}</S.Title>
            <S.CloseButton
              data-testid="close-btn"
              onClick={handleCloseCheckout}
            />
            <div>
              {hasMealPlan && (
                <S.DescriptionContainer>
                  <S.PersonalizedPlan>
                    {t`purchaseCancelOfferMealPlan.checkout.mealPlan`}
                  </S.PersonalizedPlan>
                  <S.MealPlanPrice>
                    <Trans
                      i18nKey="purchase1.checkout.mealPlanPrice"
                      values={{
                        mealPlanPrice: 0,
                        currency: CURRENCY_SYMBOLS[currency],
                      }}
                    />
                  </S.MealPlanPrice>
                </S.DescriptionContainer>
              )}

              <S.DescriptionContainer>
                <S.PersonalizedPlan>
                  <Trans
                    i18nKey="purchaseCancelOffer.checkout.personalizedPlan"
                    components={[<br />]}
                  />
                </S.PersonalizedPlan>
                <S.PlanPrice>
                  <Trans
                    i18nKey="purchaseIntroOffer.checkout.price"
                    values={{
                      price: getCurrentPrice(
                        hasMealPlan
                          ? oldPriceBeforeCustomPercentDiscount?.fullPrice
                          : oldTotalPrice,
                      ),
                      currency: CURRENCY_SYMBOLS[currency],
                    }}
                  />
                </S.PlanPrice>
              </S.DescriptionContainer>

              {hasDiscount && (
                <S.DescriptionContainer>
                  <S.PersonalizedPlan>
                    <Trans
                      i18nKey="purchaseIntroOffer.checkout.offerDiscount"
                      values={{ percentDiscount: discountPercentage }}
                      components={[<br />]}
                    />
                  </S.PersonalizedPlan>
                  <S.Discount>
                    -
                    <Trans
                      i18nKey="purchase1.checkout.discountAmount"
                      values={{
                        discountAmount: getCurrentPrice(discountAmount),
                        currency: CURRENCY_SYMBOLS[currency],
                      }}
                    />
                  </S.Discount>
                </S.DescriptionContainer>
              )}

              {hasExcludedVat && (
                <S.DescriptionContainer>
                  <S.PersonalizedPlan>
                    {t(`purchase1.valueAddedTax`, { context: countryCode })}
                  </S.PersonalizedPlan>
                  <S.PlanPrice>
                    <Trans
                      i18nKey="purchaseIntroOffer.checkout.price"
                      values={{
                        price:
                          hasMealPlan || hasSevenDayPlan
                            ? fullPriceTax
                            : trialPriceTax,
                        currency: CURRENCY_SYMBOLS[currency],
                      }}
                    />
                  </S.PlanPrice>
                </S.DescriptionContainer>
              )}
            </div>
            <S.TotalAmountContainer>
              {hasTotal && (
                <>
                  <span>{t`purchaseCancelOffer.checkout.total`}</span>
                  <span>
                    <Trans
                      i18nKey="purchaseIntroOffer.checkout.price"
                      values={{
                        price:
                          hasMealPlan || hasSevenDayPlan
                            ? fullPrice
                            : trialPrice,
                        currency: CURRENCY_SYMBOLS[currency],
                      }}
                    />
                  </span>
                </>
              )}
            </S.TotalAmountContainer>
            {!hasMealPlan && !hasSevenDayPlan && (
              <S.DiscountDescription>
                {t('purchaseIntroOffer.checkout.discountDescription', {
                  percentDiscount: discountPercentage,
                  discountAmount: getCurrentPrice(discountAmount),
                  currency: CURRENCY_SYMBOLS[currency],
                })}
              </S.DiscountDescription>
            )}
            <S.PaymentContainer $hasTotal={hasTotal}>
              <div ref={creditCardPaymentRef}>
                {isPrimerActive ? (
                  <>
                    {isCheckoutShown && (
                      <PrimerPaymentForm key={selectedSubscriptionId} />
                    )}
                  </>
                ) : (
                  <CreditCardFormCancelOffer />
                )}
                {hasPayPalButton && (
                  <>
                    <Separator />
                    <PayPalButton />
                  </>
                )}
                <S.ButtonContainer>
                  <PaymentRequestButton />
                </S.ButtonContainer>
              </div>

              <S.Image src={securitySystems} alt="security-systems" />
              <S.Text>{t`purchase1.checkout.moneyBackGuarantee`}</S.Text>
            </S.PaymentContainer>
          </S.Wrapper>
          {isPrimerActive ? (
            <PaymentPrimerWaitingModal
              isPaymentWaitingShown={isPaymentWaitingShown}
              setIsPaymentWaitingShown={setIsPaymentWaitingShown}
            />
          ) : (
            <PaymentWaitingModal
              isPaymentWaitingShown={isPaymentWaitingShown}
              setIsPaymentWaitingShown={setIsPaymentWaitingShown}
            />
          )}

          {!isCheckoutReady && <Spinner />}
        </>
      )}
    </>
  )
}
