import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ContactWithSupport } from 'components/ContactWithSupport'
import { CustomerReviewsNewYear } from 'components/CustomerReviewsNewYear'
import { Spinner } from 'components/Spinner'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import {
  GET_SUBSCRIPTION_LIST,
  getSubscriptionListAction,
  setScreenNameAction,
  setSubscriptionListAction,
  startFetching,
} from 'root-redux/actions/common'
import { selectCurrentVariantParentCohort } from 'root-redux/selects/common'

import { useViewportValue } from 'hooks/useViewportValue'

import { FaqListNewYear } from 'modules/purchase/components/FAQListNewYear'
import { FeaturedBlockNewYear } from 'modules/purchase/components/FeaturedBlockNewYear'
import { FortuneWheel } from 'modules/purchase/components/FortuneWheel'
import { IntroMediaNewYear } from 'modules/purchase/components/IntroMediaNewYear'
import { MoneyBackNewYear } from 'modules/purchase/components/MoneyBackNewYear'
import { PhotoResult } from 'modules/purchase/components/PhotoResult'
import { PlanHighlightsNewYear } from 'modules/purchase/components/PlanHighlightsNewYear'
import { SecurityInfoNewYear } from 'modules/purchase/components/SecurityInfoNewYear'
import { SubscriptionsBlockNewYear } from 'modules/purchase/components/SubscriptionsBlockNewYear'
import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { usePurchaseAnalytics } from 'modules/purchase/hooks/usePurchaseAnalytics'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { CheckoutIntroOffer } from 'modules/purchase/pages/CheckoutIntroOffer'
import {
  DISCLAIMER_TEXTS,
  PRESELECTED_PRODUCT,
  PROGRAM_DESCRIPTION_POINTS,
} from 'modules/purchase/pages/PurchaseNewYear/constants'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  setSelectedSubscriptionAction,
} from 'modules/purchase/redux/actions/common'

import { ISubscription } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'

import {
  Cohort,
  PagesSource,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { StyledPurchaseNewYear as S } from './PurchaseNewYear.styles'

export const PurchaseNewYear: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {
    availableSpins,
    cohort,
    currency,
    fetchingActionsList,
    fullPrice,
    selectedSubscription,
    selectedProductId,
    subscriptions,
  } = usePurchaseStore()

  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const cohortToUse = parentCohort || cohort

  usePurchaseAnalytics({
    screenName: ScreenName.ONBOARDING,
  })

  const [isCheckoutShown, setIsCheckoutShown] = useState(false)

  useViewportValue(ScreenName.ONBOARDING, !isCheckoutShown)

  const hasPrices = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.ONBOARDING))
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        SubscriptionTags.NO_TAX,
      ),
    )

    return () => {
      dispatch(setSubscriptionListAction([]))
      dispatch(setSelectedSubscriptionAction(null))
    }
  }, [dispatch])

  useEffect(() => {
    if (!subscriptions.length) return

    const productId = PRESELECTED_PRODUCT[cohortToUse as Cohort][availableSpins]
    const preselectedSubscription = subscriptions.find(
      ({ product }) => product === productId,
    )

    dispatch(
      setSelectedSubscriptionAction(preselectedSubscription as ISubscription),
    )
  }, [dispatch, cohortToUse, availableSpins, subscriptions])

  const handleShowCheckout = useCallback((event) => {
    eventLogger.logPlansPageButtonTap({
      screenName: ScreenName.ONBOARDING,
      buttonNumber: event.target.getAttribute('data-order'),
      buttonText: event.target.getAttribute('data-text'),
    })

    setIsCheckoutShown(true)
  }, [])

  return !hasPrices ? (
    <Spinner />
  ) : (
    <>
      {!isCheckoutShown && (
        <>
          <IntroMediaNewYear />

          <S.Wrapper>
            <S.ProgramPlanContainer>
              <S.ProgramPlanTitle>
                {t('purchaseNewYear.programDescription.title')}
              </S.ProgramPlanTitle>
              <S.List>
                {PROGRAM_DESCRIPTION_POINTS.map(({ id, path }) => (
                  <S.ListItem key={id}>{t(path)}</S.ListItem>
                ))}
              </S.List>
            </S.ProgramPlanContainer>

            <FortuneWheel />
            <SubscriptionsBlockNewYear>
              <S.Button
                type="button"
                data-order="1"
                data-text="take_prize"
                disabled={!selectedSubscription}
                onClick={handleShowCheckout}
              >{t`actions.takePrize`}</S.Button>
            </SubscriptionsBlockNewYear>

            <PlanHighlightsNewYear />
            <FeaturedBlockNewYear />
            <MoneyBackNewYear />
            <SecurityInfoNewYear />
            <PhotoResult />

            <S.Button
              type="button"
              data-order="2"
              data-text="take_prize"
              disabled={!selectedSubscription}
              onClick={handleShowCheckout}
            >{t`actions.takePrize`}</S.Button>

            <FaqListNewYear />
            <CustomerReviewsNewYear />

            <S.Button
              type="button"
              data-order="3"
              data-text="take_prize"
              disabled={!selectedSubscription}
              onClick={handleShowCheckout}
            >{t`actions.takePrize`}</S.Button>

            {!!selectedSubscription && (
              <S.Disclaimer>
                {t(DISCLAIMER_TEXTS[selectedProductId], {
                  fullPrice,
                  currency: CURRENCY_SYMBOLS[currency],
                })}
                <TermsOfUseLink source={PagesSource.PAYWALL} />{' '}
                {t`commonComponents.contactUs`}{' '}
                <ContactWithSupport
                  source={PagesSource.PAYWALL}
                  text={t('commonComponents.contactSupport')}
                />
              </S.Disclaimer>
            )}
          </S.Wrapper>
        </>
      )}
      <S.CheckoutContainer $isVisible={isCheckoutShown}>
        <CheckoutIntroOffer
          isCheckoutShown={isCheckoutShown}
          setIsCheckoutShown={setIsCheckoutShown}
        />
      </S.CheckoutContainer>
    </>
  )
}
