import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { eventLogger } from 'services/eventLogger.service'

import whiteLogo from 'assets/images/dancebit-logo-white.png'

import { PagesSource, SUPPORT_LINK } from 'root-constants'

import { StyledHeader as S } from './HeaderV2.styles'

export const HeaderV2: React.FC = () => {
  const { t } = useTranslation()

  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false)

  const toggleMenuVisibility = useCallback(() => {
    setIsMenuVisible(!isMenuVisible)
  }, [isMenuVisible])

  return (
    <S.Root>
      <S.WhiteLogo src={whiteLogo} alt="" />
      <S.HeaderButtons>
        <S.SupportAction
          href={SUPPORT_LINK}
          onClick={() => eventLogger.logNeedHelpClicked()}
        >
          {t`actions.needHelp`}
        </S.SupportAction>

        <S.BurgerWhite type="button" onClick={toggleMenuVisibility}>
          <span />
          <span />
          <span />
        </S.BurgerWhite>
      </S.HeaderButtons>
      <S.Backdrop
        $isMenuVisible={isMenuVisible}
        onClick={toggleMenuVisibility}
      />
      <S.Menu $isMenuVisible={isMenuVisible}>
        <S.MenuCloseButton type="button" onClick={toggleMenuVisibility} />
        <S.StyledMenuLinkContainer>
          <TermsOfUseLink source={PagesSource.LANDING} />
        </S.StyledMenuLinkContainer>
        <S.StyledMenuLinkContainer>
          <PrivacyPolicyLink source={PagesSource.LANDING} />
        </S.StyledMenuLinkContainer>
      </S.Menu>
    </S.Root>
  )
}
