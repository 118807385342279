import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledFAQListNewYear as S } from './FAQListNewYear.styles'

export const FaqListNewYear: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <S.Title>{t`purchase1.faqList.title`}</S.Title>
      <ul>
        <S.ListItem>
          <S.ListItemTitle>{t`purchase1.faqList.questionFirst`}</S.ListItemTitle>
          <S.ListItemText>{t`purchase1.faqList.answerFirst`}</S.ListItemText>
        </S.ListItem>
        <S.ListItem>
          <S.ListItemTitle>{t`purchase1.faqList.questionSecond`}</S.ListItemTitle>
          <S.ListItemText>{t`purchase1.faqList.answerSecond`}</S.ListItemText>
        </S.ListItem>
        <S.ListItem>
          <S.ListItemTitle>{t`purchase1.faqList.questionThird`}</S.ListItemTitle>
          <S.ListItemText>{t`purchase1.faqList.answerThird`}</S.ListItemText>
        </S.ListItem>
      </ul>
    </>
  )
}
