import React, { FC, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { NavigationButton } from 'components/NavigationButton'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Gender } from 'root-constants'

import { StyledHealthCondition as S } from './HealthConditionAgeFlow.styles'
import {
  OPTION_VALUES_FEMALE,
  OPTION_VALUES_MALE,
  QUESTION,
  SELECT_NONE,
} from './constants'

export const HealthConditionAgeFlow: FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const gender = useSelector(selectCurrentUserGender)
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.CHECKBOX,
      onChange: (answer: string, isChecked: boolean) => {
        if (isChecked && answer === SELECT_NONE) {
          setAnswers([answer])
          return
        }

        if (isChecked) {
          const newAnswers = answers.filter((item) => item !== SELECT_NONE)
          setAnswers([...newAnswers, answer])
        } else {
          setAnswers(answers.filter((item) => item !== answer))
        }
      },
    }),
    [pageId, answers],
  )

  const handleClick = useCallback(() => handleContinue(answers), [
    answers,
    handleContinue,
  ])

  return (
    <>
      <S.Container>
        <PageTitle isAgeFlow marginBottom={16}>
          <Trans i18nKey="onboarding.bonesProblems.question" />
        </PageTitle>
        <S.Subtitle>
          <Trans i18nKey="onboarding.bonesProblems.subtitle" />
        </S.Subtitle>

        {(gender === Gender.MALE
          ? OPTION_VALUES_MALE
          : OPTION_VALUES_FEMALE
        ).map(({ value, icon }) => (
          <Option
            {...optionProps}
            key={value}
            value={value}
            checked={answers.includes(value)}
          >
            <S.AnswerButton
              isAgeFlow
              withCheckbox
              iconSrc={icon}
              iconHeight={98}
              iconWidth={98}
            >
              {t(`onboarding.injuriesIssues.${value}`)}
            </S.AnswerButton>
          </Option>
        ))}
      </S.Container>
      <NavigationButton
        type="button"
        isAgeFlow
        disabled={!answers.length}
        onClick={handleClick}
      >
        {t`actions.continue`}
      </NavigationButton>
    </>
  )
}
