import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import computerIcon from 'assets/images/computer.svg'
import coffeeIcon from 'assets/images/cup-of-coffee.svg'
import homeIcon from 'assets/images/home.svg'
import moonIcon from 'assets/images/moon.svg'
import palmIcon from 'assets/images/palm.svg'

import { StyledWorkSchedule as S } from './WorkSchedule.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const WorkSchedule: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <Container>
      <PageTitle marginBottom={32}>
        {t('onboarding.workSchedule.question')}
      </PageTitle>

      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.NINE_TO_FIVE}
      >
        <S.AnswerButton
          iconSrc={computerIcon}
        >{t`onboarding.workSchedule.fromNineToFive`}</S.AnswerButton>
      </Option>

      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.FLEXIBLE}
      >
        <S.AnswerButton
          iconSrc={coffeeIcon}
        >{t`onboarding.workSchedule.flexible`}</S.AnswerButton>
      </Option>

      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.NIGHT_SHIFTS}
      >
        <S.AnswerButton
          iconSrc={moonIcon}
        >{t`onboarding.workSchedule.nightShifts`}</S.AnswerButton>
      </Option>

      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.NO_WORK}
      >
        <S.AnswerButton
          iconSrc={palmIcon}
        >{t`onboarding.workSchedule.noWork`}</S.AnswerButton>
      </Option>

      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.AT_HOME_PARENT}
      >
        <S.AnswerButton
          iconSrc={homeIcon}
        >{t`onboarding.workSchedule.atHomeParent`}</S.AnswerButton>
      </Option>
    </Container>
  )
}
