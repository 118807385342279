import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import { HeaderV2 } from 'components/HeaderV2'
import { OptionType } from 'components/Option'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { sendFacebookParamsAction } from 'root-redux/actions/user'
import { selectUUID } from 'root-redux/selects/user'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'
import { useStartSession } from 'hooks/useStartSession'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import arrowAnimation from 'assets/animation/arrowAnimation.json'

import { PageId } from 'page-constants'
import { MAIN_GOALS, PagesSource } from 'root-constants'

import { StyledMainGoalSomatic as S } from './MainGoalSomatic.styles'
import { QUESTION } from './constants'

export const MainGoalSomatic: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)

  const [answer, setAnswer] = useState<string>('')
  const animationRef = useRef<HTMLDivElement>(null)
  const { title, subtitle } = useDynamicOBConfig()

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  useStartSession()

  const handleContinue = useNextStep({
    pageId: PageId.MAIN_GOAL,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        dispatch(sendFacebookParamsAction())
        window.fbq('track', 'CustomizeProduct', {}, { eventID: uuid })
        window.pintrk && window.pintrk('track', 'custom')
        googleAnalyticsLogger.logFirstPageCompleted()
        eventLogger.logFirstPageCompleted({
          question: QUESTION,
          answers: value,
        })

        handleChange([value], handleContinue)
        setAnswer(value)
        setIsAnswersDisabled(true)
      },
    }),
    [
      uuid,
      pageId,
      setIsAnswersDisabled,
      handleContinue,
      handleChange,
      dispatch,
    ],
  )

  useEffect(() => {
    if (animationRef.current) {
      lottie.loadAnimation({
        container: animationRef.current,
        animationData: arrowAnimation,
        loop: true,
        name: 'arrowAnimation',
      })
    }

    return () => lottie.destroy('arrowAnimation')
  }, [])

  return (
    <div>
      <HeaderV2 />
      <S.Root>
        <S.Container>
          <div>
            <S.Title>{title || t`onboarding.mainGoal.titleSomatic`}</S.Title>
            <S.Subtitle>
              {subtitle || t`onboarding.mainGoal.subtitleSomatic`}
            </S.Subtitle>
          </div>

          <div>
            <S.ActionCall>{t`onboarding.mainGoal.question`}</S.ActionCall>
            <S.Arrow ref={animationRef} />
            <S.Actions>
              <S.StyledOption
                {...optionProps}
                withoutMargin
                value={MAIN_GOALS.STAY_FIT}
                disabled={isAnswersDisabled}
                $isChecked={answer === MAIN_GOALS.STAY_FIT}
              >
                <S.Action>{t`onboarding.mainGoal.stayFit`}</S.Action>
              </S.StyledOption>
              <S.StyledOption
                {...optionProps}
                withoutMargin
                value={MAIN_GOALS.LOSE_WEIGHT}
                disabled={isAnswersDisabled}
                $isChecked={answer === MAIN_GOALS.LOSE_WEIGHT}
              >
                <S.Action>{t`onboarding.mainGoal.loseWeight`}</S.Action>
              </S.StyledOption>
            </S.Actions>
          </div>
        </S.Container>
        <S.Terms>
          <p>{t`footer.disclaimer`}</p>
          <TermsOfUseLink source={PagesSource.LANDING} />
          <S.Separator />
          <PrivacyPolicyLink source={PagesSource.LANDING} />
          <p>
            {t('footer.allRightsReserved', {
              year: new Date().getFullYear(),
            })}
          </p>
        </S.Terms>
      </S.Root>
    </div>
  )
}
