import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { NavigationButton } from 'components/NavigationButton'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentUserGender,
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import armsLineSvg from 'assets/images/sprite/arms-line-age-mx-flow.svg'
import bellyLineSvg from 'assets/images/sprite/belly-line-age-mx-flow.svg'
import chestLineSvg from 'assets/images/sprite/chest-line-age-mx-flow.svg'
import hipsLineSvg from 'assets/images/sprite/hips-line-age-mx-flow.svg'
import legsLineSvg from 'assets/images/sprite/legs-line-age-mx-flow.svg'

import { ADULT_COHORT, Cohort } from 'root-constants'

import { StyledImproveAreasMx as S } from './ImproveAreasMx.styles'
import {
  BODY_IMAGE_MX_MAP_AGE_FLOW,
  OPTION_VALUES,
  QUESTION,
} from './constants'

export const ImproveAreasMx: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const gender = useSelector(selectCurrentUserGender)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const cohortToUse = parentCohort || cohort

  const [answers, setAnswers] = useState<string[]>([])
  const countOfAllOptions = OPTION_VALUES.FULL_BODY.split(',').length

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.CHECKBOX,
      onChange: (value, isChecked) => {
        const options = value.split(',')
        if (isChecked) {
          options.length === countOfAllOptions
            ? setAnswers([...options])
            : setAnswers([...answers, ...options])
        } else {
          const newValues =
            options.length === countOfAllOptions
              ? []
              : answers.filter((item) => item !== value)
          setAnswers(newValues)
        }
      },
    }),
    [answers, countOfAllOptions, pageId],
  )

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => handleContinue(answers), [
    answers,
    handleContinue,
  ])

  return (
    <div>
      <S.Container>
        <S.TitleContainer>
          <PageTitle marginBottom={30} isAgeFlow>
            {t`onboarding.improveAreas.question`}
          </PageTitle>
        </S.TitleContainer>
        <Option
          {...optionProps}
          value={OPTION_VALUES.FULL_BODY}
          checked={answers.length === countOfAllOptions}
        >
          <S.OptionContent $isChecked={answers.length === countOfAllOptions}>
            <S.OptionText>{t`onboarding.improveAreas.fullBody`}</S.OptionText>
          </S.OptionContent>
        </Option>
        <S.Row>
          <picture>
            <source
              srcSet={BODY_IMAGE_MX_MAP_AGE_FLOW[gender].WEBP}
              type="image/webp"
            />
            <S.Image
              src={BODY_IMAGE_MX_MAP_AGE_FLOW[gender].PNG}
              width="202"
              alt="body"
            />
          </picture>

          <S.Col>
            <Option
              {...optionProps}
              value={OPTION_VALUES.ARMS}
              checked={answers.includes(OPTION_VALUES.ARMS)}
            >
              <S.OptionContent
                $isChecked={answers.includes(OPTION_VALUES.ARMS)}
              >
                <S.OptionText>{t`onboarding.improveAreas.arms`}</S.OptionText>
                <S.SvgImage svg={armsLineSvg} width={93} $top={30} />
              </S.OptionContent>
            </Option>

            <Option
              {...optionProps}
              value={OPTION_VALUES.CHEST}
              checked={answers.includes(OPTION_VALUES.CHEST)}
            >
              <S.OptionContent
                $isChecked={answers.includes(OPTION_VALUES.CHEST)}
              >
                <S.OptionText>{t`onboarding.improveAreas.chest`}</S.OptionText>
                <S.SvgImage svg={chestLineSvg} width={122} $top={6} />
              </S.OptionContent>
            </Option>

            <Option
              {...optionProps}
              value={OPTION_VALUES.BELLY}
              checked={answers.includes(OPTION_VALUES.BELLY)}
            >
              <S.OptionContent
                $isChecked={answers.includes(OPTION_VALUES.BELLY)}
              >
                <S.OptionText>{t`onboarding.improveAreas.belly`}</S.OptionText>
                <S.SvgImage svg={bellyLineSvg} width={112} $top={-32} />
              </S.OptionContent>
            </Option>

            <Option
              {...optionProps}
              value={OPTION_VALUES.HIPS}
              checked={answers.includes(OPTION_VALUES.HIPS)}
            >
              <S.OptionContent
                $isChecked={answers.includes(OPTION_VALUES.HIPS)}
              >
                <S.OptionText>{t`onboarding.improveAreas.hips`}</S.OptionText>
                <S.SvgImage svg={hipsLineSvg} width={103} $top={-40} />
              </S.OptionContent>
            </Option>

            <Option
              {...optionProps}
              value={OPTION_VALUES.LEGS}
              checked={answers.includes(OPTION_VALUES.LEGS)}
            >
              <S.OptionContent
                $isChecked={answers.includes(OPTION_VALUES.LEGS)}
              >
                <S.OptionText>{t`onboarding.improveAreas.legs`}</S.OptionText>
                <S.SvgImage svg={legsLineSvg} width={89} $top={-42} />
              </S.OptionContent>
            </Option>
          </S.Col>
        </S.Row>
        <S.ProgramDescription>
          <Trans
            i18nKey={
              isAgeFlow
                ? 'onboarding.improveAreas.programDescriptionAgeFlow'
                : 'onboarding.improveAreas.programDescription'
            }
            components={[<br />]}
          />
        </S.ProgramDescription>
      </S.Container>

      <NavigationButton
        type="button"
        isAgeFlow={isAgeFlow}
        disabled={!answers.length}
        onClick={handleNextClick}
      >
        {t`actions.continue`}
      </NavigationButton>
    </div>
  )
}
