import styled, { css } from 'styled-components'

import bgWithGradientStars from 'assets/images/bg-with-gradient-stars.jpg'
import circleLine from 'assets/images/circled-line.svg'
import wideCircleLine from 'assets/images/wide-circled-line.svg'

import { Color, MediaBreakpoint } from 'root-constants'

type TReachTargetProps = {
  $isStayFitFlow: boolean
  $isSpanish?: boolean
  $isFrench?: boolean
  $isItalian?: boolean
  $isGerman?: boolean
  $isPortuguese?: boolean
  $isMetricSystemSelected?: boolean
}

export const StyledReachTarget = {
  Wrapper: styled.div<{ $isStayFitFlow: boolean; $isLarge: boolean }>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: url(${bgWithGradientStars}) no-repeat center;
    background-size: cover;
    margin: 0 auto 8px;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: ${({ $isLarge }) => ($isLarge ? '375px' : '360px')};
    }
  `,
  Text: styled.p<TReachTargetProps>`
    padding-left: 10px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    z-index: 1;
    width: 290px;
    margin-top: 30px;

    strong {
      position: relative;
      color: ${Color.PRIMARY};
      font-size: ${({ $isSpanish, $isStayFitFlow }) =>
        $isSpanish && $isStayFitFlow ? '18px' : '24px'};
      font-weight: 800;
      line-height: 28px;

      &::after {
        content: '';
        position: absolute;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;

        ${({ $isStayFitFlow }) =>
          $isStayFitFlow
            ? css`
                background-image: url(${wideCircleLine});
                top: -12px;
                left: -6px;
                width: 128px;
                height: 48px;
              `
            : css`
                background-image: url(${circleLine});
                top: -15px;
                left: -3px;
                width: 80px;
                height: 55px;
              `};

        ${({ $isSpanish, $isStayFitFlow }) =>
          $isSpanish &&
          $isStayFitFlow &&
          css`
            left: -6px;
            width: 184px;
            height: 48px;
          `}

        ${({ $isPortuguese, $isStayFitFlow }) =>
          $isPortuguese &&
          $isStayFitFlow &&
          css`
            width: 170px;
          `}

        ${({ $isFrench, $isStayFitFlow }) =>
          $isFrench &&
          $isStayFitFlow &&
          css`
            top: -10px;
            left: -6px;
            width: 184px;
            height: 48px;
          `}

        ${({ $isItalian, $isStayFitFlow }) =>
          $isItalian &&
          $isStayFitFlow &&
          css`
            width: 184px;
            height: 48px;
          `}

          ${({
          $isGerman,
          $isStayFitFlow,
          $isMetricSystemSelected,
        }) =>
          $isGerman &&
          !$isStayFitFlow &&
          !$isMetricSystemSelected &&
          css`
            top: -11px;
            background-image: url(${wideCircleLine});
            width: 115px;
            height: 48px;
          `}
      }
    }

    span {
      font-size: ${({ $isSpanish, $isStayFitFlow }) =>
        $isSpanish && $isStayFitFlow ? '18px' : '24px'};
      font-weight: 700;
      line-height: 28px;
    }
  `,
  BgImage: styled.img`
    position: absolute;
    right: 0;
    height: 225px;
  `,
  CarouselContainer: styled.div<{ $hasPaywallTest: boolean }>`
    width: 200px;
    min-height: ${({ $hasPaywallTest }) =>
      $hasPaywallTest ? '207px' : '232px'};

    ${({ $hasPaywallTest }) =>
      $hasPaywallTest &&
      css`
        img {
          height: 207px;
        }
      `}
    .swiper-container {
      padding-bottom: 25px;
    }

    & .swiper-pagination-bullets {
      bottom: 0;
    }

    & .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      margin: 0 6px;
      background: ${Color.PRIMARY};
      opacity: 0.2;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet-active {
      opacity: 0.7;
    }
  `,
  PhotoResultWrapper: styled.div`
    max-width: 200px;
  `,
  Image: styled.img`
    image-rendering: -webkit-optimize-contrast;
  `,
}
