import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { SvgImage } from 'components/SvgImage'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { eventLogger } from 'services/eventLogger.service'

import burgerSvg from 'assets/images/sprite/burger.svg'
import logoSvg from 'assets/images/sprite/new-year-logo.svg'

import { PagesSource, SUPPORT_LINK } from 'root-constants'

import { StyledHeaderNewYear as S } from './HeaderNewYear.styles'

export const HeaderNewYear: React.FC = () => {
  const { t } = useTranslation()
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false)

  const toggleMenuVisibility = useCallback(() => {
    setIsMenuVisible(!isMenuVisible)
  }, [isMenuVisible])

  return (
    <S.Wrapper>
      <S.Logo>
        <SvgImage svg={logoSvg} />
        <span>Dancebit</span>
      </S.Logo>
      <S.ButtonContainer>
        <S.SupportAction
          href={SUPPORT_LINK}
          onClick={() => eventLogger.logNeedHelpClicked()}
        >
          {t`actions.needHelp`}
        </S.SupportAction>
        <S.Burger type="button" onClick={toggleMenuVisibility}>
          <SvgImage svg={burgerSvg} height={14} />
        </S.Burger>
      </S.ButtonContainer>
      <S.Backdrop
        $isMenuVisible={isMenuVisible}
        onClick={toggleMenuVisibility}
      />
      <S.Menu $isMenuVisible={isMenuVisible}>
        <S.MenuCloseButton type="button" onClick={toggleMenuVisibility} />
        <S.StyledMenuLinkContainer>
          <TermsOfUseLink source={PagesSource.LANDING} />
        </S.StyledMenuLinkContainer>
        <S.StyledMenuLinkContainer>
          <PrivacyPolicyLink source={PagesSource.LANDING} />
        </S.StyledMenuLinkContainer>
      </S.Menu>
    </S.Wrapper>
  )
}
