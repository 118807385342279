import armsFemale from 'assets/images/arms-issue-female.png'
import armsMale from 'assets/images/arms-issue-male.png'
import elbowsFemale from 'assets/images/elbows-issue-female.png'
import elbowsMale from 'assets/images/elbows-issue-male.png'
import feetFemale from 'assets/images/feet-issue-female.png'
import feetMale from 'assets/images/feet-issue-male.png'
import kneesFemale from 'assets/images/knees-issue-female.png'
import kneesMale from 'assets/images/knees-issue-male.png'
import legsFemale from 'assets/images/legs-issue-female.png'
import legsMale from 'assets/images/legs-issue-male.png'
import neckFemale from 'assets/images/neck-issue-female.png'
import neckMale from 'assets/images/neck-issue-male.png'
import noneFemale from 'assets/images/none-issue-female.png'
import noneMale from 'assets/images/none-issue-male.png'
import shouldersFemale from 'assets/images/shoulders-issue-female.png'
import shouldersMale from 'assets/images/shoulders-issue-male.png'

export const QUESTION =
  'Do you have problems with your bones, muscles or joints?'

export const SELECT_NONE = 'noIssues'

export const OPTION_VALUES_MALE = [
  {
    value: SELECT_NONE,
    icon: noneMale,
  },
  {
    value: 'knees',
    icon: kneesMale,
  },
  {
    value: 'shoulders',
    icon: shouldersMale,
  },
  {
    value: 'neck',
    icon: neckMale,
  },
  {
    value: 'arms',
    icon: armsMale,
  },
  {
    value: 'elbows',
    icon: elbowsMale,
  },
  {
    value: 'legs',
    icon: legsMale,
  },
  {
    value: 'feet',
    icon: feetMale,
  },
]
export const OPTION_VALUES_FEMALE = [
  {
    value: SELECT_NONE,
    icon: noneFemale,
  },
  {
    value: 'knees',
    icon: kneesFemale,
  },
  {
    value: 'shoulders',
    icon: shouldersFemale,
  },
  {
    value: 'neck',
    icon: neckFemale,
  },
  {
    value: 'arms',
    icon: armsFemale,
  },
  {
    value: 'elbows',
    icon: elbowsFemale,
  },
  {
    value: 'legs',
    icon: legsFemale,
  },
  {
    value: 'feet',
    icon: feetFemale,
  },
]
