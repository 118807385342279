import styled from 'styled-components'

import arrowSvg from 'assets/images/arrow.svg'

import { Color, MediaBreakpoint } from 'root-constants'

type TPhotoResultV3Props = {
  $hasBackground?: boolean
}

export const StyledPhotoResultV3 = {
  Wrapper: styled.div`
    padding-bottom: 37px;

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      margin: 0 0 24px;
      padding: 16px 0;
    }
  `,
  TitleContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `,
  Title: styled.h2`
    font-size: 28px;
    text-align: left;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.DARK};
    margin-bottom: 16px;
  `,
  Description: styled.p`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: ${Color.LIGHT_GREY};
    margin-bottom: 16px;
  `,
  ResultContainer: styled.div<TPhotoResultV3Props>`
    width: 100%;
    display: flex;

    justify-content: ${({ $hasBackground }) =>
      $hasBackground ? 'space-between' : 'space-evenly'};
    align-items: center;
    margin-bottom: 32px;
  `,
  ResultText: styled.span`
    color: ${Color.PRIMARY};
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  `,
  ResultSvgContainer: styled.div`
    width: 40px;
    height: 20px;
    background-image: url(${arrowSvg});
    background-repeat: no-repeat;
  `,
  CardWrapper: styled.div<TPhotoResultV3Props>`
    width: 100%;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 16px;
    text-align: left;
    overflow: hidden;

    background: ${({ $hasBackground }) =>
      $hasBackground &&
      `
      linear-gradient(
        164deg,
        rgba(208, 247, 255, 0.2) 0.95%,
        rgba(165, 215, 255, 0.2) 58.29%,
        rgba(153, 194, 255, 0.2) 90.07%
      )
`};
  `,
  Card: styled.div`
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `,
  Author: styled.h3`
    color: ${Color.DARK};
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  `,
  Rating: styled.div`
    display: flex;
    justify-content: space-between;
    width: 80px;
    padding: 8px 0;

    svg {
      width: 16px;
      height: 16px;
    }
  `,
  Text: styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  `,
  PhotoResultWrapper: styled.div<TPhotoResultV3Props>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    margin-bottom: ${({ $hasBackground }) =>
      !$hasBackground &&
      `
      8px;
`};

    box-shadow: ${({ $hasBackground }) =>
      !$hasBackground &&
      `
      0px 8px 20px 0px #E1E3EC;
`};
  `,
  Image: styled.img`
    width: 100%;
    image-rendering: -webkit-optimize-contrast;
  `,
  CarouselContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    .swiper-container {
      padding-bottom: 25px;
    }

    & .swiper-pagination {
      bottom: 0;
    }

    & .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      bottom: 0;
      margin: 0 6px;
      background: ${Color.PRIMARY};
      opacity: 0.2;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet-active {
      opacity: 0.7;
    }

    .swiper-slide {
      display: flex;
      justify-content: center;
    }
  `,
  CustomTitle: styled.h2`
    font-size: 24px;
    text-align: center;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
    color: ${Color.DARK};
    margin-bottom: 16px;
  `,
}
