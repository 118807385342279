import styled from 'styled-components'

import { LoadingButton } from 'components/LoadingButton'
import { OuterLink } from 'components/OuterLink'
import {
  TMenuProps,
  backdropStyles,
  dotsAnimationStyles,
  menuCloseButtonStyles,
  sidebarStyles,
} from 'components/styles'

import { Color } from 'root-constants'

export const StyledHeaderNewYear = {
  Wrapper: styled.header`
    position: relative;
    padding: 10px 16px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.5);
  `,
  Logo: styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    color: ${Color.DARK};

    svg {
      flex-shrink: 0;
      height: 34px;
      width: 34px;
      margin-right: 12px;
    }
  `,
  ButtonContainer: styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `,

  LoadingButton: styled(LoadingButton)`
    min-width: 102px;
    border: 1px solid #e9e5e5;
    border-radius: 10px;
    padding: 5px 8px;
    font-weight: 700;
    color: #e9e5e5;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    & > div {
      ${dotsAnimationStyles};
      background-color: ${Color.DARK};
    }
  `,
  SupportAction: styled(OuterLink)`
    min-width: 102px;
    border: 1px solid #e9e5e5;
    background-color: transparent;
    border-radius: 10px;
    padding: 5px 8px;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    color: #e9e5e5;
    margin-right: 11px;
    cursor: pointer;
  `,
  Burger: styled.button`
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  `,
  Backdrop: styled.div<TMenuProps>`
    ${backdropStyles}
  `,
  Menu: styled.nav<TMenuProps>`
    ${sidebarStyles}
  `,
  MenuCloseButton: styled.button`
    ${menuCloseButtonStyles}
  `,
  StyledMenuLinkContainer: styled.div`
    font-size: 20px;
    line-height: 24px;
    color: rgb(45, 50, 64);
    margin-bottom: 35px;

    a {
      color: inherit;
    }
  `,
}
