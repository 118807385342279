import styled from 'styled-components'

import { Button } from 'components/Button'

import { OfferCard } from 'modules/purchase/components/OfferCard'

import blueCheck from 'assets/images/check-icon-rounded.svg'

import { Color } from 'root-constants'

export const StyledPurchaseCancelOffer = {
  Wrapper: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 20px;

    &:last-child {
      padding-bottom: 20px;
    }
  `,
  AppDescription: styled.h1`
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;

    strong {
      color: ${Color.PRIMARY};
    }
  `,
  ProgramPlanContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 295px;
    margin: 0 auto 33px;
  `,
  ProgramPlanTitle: styled.h2`
    margin-bottom: 17px;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    text-align: center;
  `,
  ListItem: styled.li`
    position: relative;
    margin-bottom: 20px;
    padding-left: 34px;
    font-size: 16px;
    line-height: 20px;
    text-align: left;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      width: 24px;
      height: 24px;
      color: ${Color.WHITE};
      background: url(${blueCheck}) center center no-repeat;
      background-size: contain;
    }
  `,
  ButtonContainer: styled.div`
    margin: 24px 0 0;
  `,
  Button: styled(Button)`
    max-width: 328px;
  `,
  FeaturedBlockContainer: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 20px 40px;
  `,
  FeaturedBlockTitle: styled.h2`
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: rgba(19, 29, 48, 0.5);
  `,
  OfferCard: styled(OfferCard)`
    margin-bottom: 24px;
  `,
  CheckoutContainer: styled.div<{ $isVisible: boolean }>`
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  `,
}
