import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { ContactWithSupport } from 'components/ContactWithSupport'
import { OuterLink } from 'components/OuterLink'
import { Spinner } from 'components/Spinner'

import {
  GET_SUBSCRIPTION_LIST,
  getPaymentConfigAction,
  getSubscriptionListAction,
  setScreenIdAction,
  setScreenNameAction,
} from 'root-redux/actions/common'
import {
  selectCurrentVariantParentCohort,
  selectIsStayFitFlow,
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'
import { selectUserPaymentCurrency } from 'root-redux/selects/user'

import { UpsellPlansDescription } from 'modules/purchase/components/UpsellPlansDescription'
import { UpsellSubscriptionBlock } from 'modules/purchase/components/UpsellSubscriptionBlock'
import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import {
  MAKE_UPSELL,
  makeUpsellAction,
} from 'modules/purchase/redux/actions/upsell'
import { selectSubscription } from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  Cohort,
  MAIN_GOALS,
  ONBOARDING_GOAL_EVENT,
  PagesSource,
  ScreenId,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
  TERMS_OF_USE_LINK,
} from 'root-constants'

import { StyledUpsellV2 as S } from './UpsellV2.styles'

export const UpsellV2: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { search } = useLocation()

  const isStayFitGoal = useSelector(selectIsStayFitFlow)
  const accountId = useSelector(selectStripeAccountId)
  const accountName = useSelector(selectStripeAccountName)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const paymentCurrency = useSelector(selectUserPaymentCurrency)
  const selectedSubscription = useSelector(selectSubscription)

  const {
    currency,
    fullPrice,
    trialPrice,
    fetchingActionsList,
    subscriptions,
    currentMeasurementSystem,
    cohort,
  } = usePurchaseStore()

  const cohortToUse = parentCohort || cohort

  const [isPricesStartedFetching, setIsPricesStartedFetching] = useState(false)

  const isStayFitFlow = useMemo(
    () => cohortToUse !== Cohort.DB_FLOW_2 && isStayFitGoal,
    [cohortToUse, isStayFitGoal],
  )

  const arePricesReady = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  const tags = useMemo(
    () =>
      `${SubscriptionTags.NO_TAX},${
        isStayFitGoal ? SubscriptionTags.STAY_FIT : SubscriptionTags.LOSE_WEIGHT
      },${SubscriptionTags.NEW_UPSELL}`,
    [isStayFitGoal],
  )

  const isUpsellInProgress = useMemo(
    () => fetchingActionsList?.includes(MAKE_UPSELL),
    [fetchingActionsList],
  )

  const goal = useMemo(
    () =>
      isStayFitGoal
        ? ONBOARDING_GOAL_EVENT.STAY_FIT
        : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
    [isStayFitGoal],
  )

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.UPSELL_TOGGLE))
    dispatch(setScreenIdAction(ScreenId.UPSELL_SWITCHER))
    dispatch(getSubscriptionListAction(SubscriptionListType.UPSELL, tags))
    dispatch(getPaymentConfigAction())
    setIsPricesStartedFetching(true)
  }, [dispatch, tags])

  useEffect(() => {
    if (!isPricesStartedFetching || !arePricesReady) return

    const [upsell] = subscriptions

    if (!subscriptions.length || paymentCurrency !== upsell?.currency) {
      goTo({
        pathname: PageId.LOGIN,
        search,
      })
    }
  }, [
    subscriptions,
    search,
    isPricesStartedFetching,
    arePricesReady,
    paymentCurrency,
  ])

  useEffect(() => {
    if (
      isPricesStartedFetching &&
      arePricesReady &&
      selectedSubscription &&
      selectedSubscription.currency === paymentCurrency
    ) {
      eventLogger.logUpsellPurchaseShown(
        ScreenName.UPSELL_TOGGLE,
        ScreenId.UPSELL_SWITCHER,
        goal,
        accountId,
        accountName,
      )
    }
  }, [
    subscriptions,
    isPricesStartedFetching,
    arePricesReady,
    goal,
    accountId,
    accountName,
    selectedSubscription,
    paymentCurrency,
  ])

  useEffect(() => {
    const [upsellSubscription] = subscriptions

    dispatch(setSelectedSubscriptionAction(upsellSubscription))
  }, [dispatch, subscriptions])

  const makeUpsell = useCallback(() => {
    dispatch(makeUpsellAction())
  }, [dispatch])

  const handleContinue = useCallback(() => {
    goTo({
      pathname: PageId.LOGIN,
      search,
    })
  }, [search])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <S.Title>
        <Trans i18nKey="upsellV2.title" />
      </S.Title>

      <S.Subtitle>
        <Trans
          i18nKey="upsellV2.subtitle"
          values={{
            context: isStayFitFlow
              ? MAIN_GOALS.STAY_FIT
              : MAIN_GOALS.LOSE_WEIGHT,
          }}
        />
      </S.Subtitle>

      <UpsellSubscriptionBlock makeUpsell={makeUpsell}>
        <S.Description>
          <Trans
            i18nKey="upsell.disclaimer"
            values={{
              context: currentMeasurementSystem,
            }}
          />
        </S.Description>

        <UpsellPlansDescription withStayFitFlow />

        <S.Text>
          <Trans
            i18nKey="upsellV2.successfullyCompleted"
            components={[<strong />, <br />]}
          />
        </S.Text>
      </UpsellSubscriptionBlock>

      <S.LinkContainer>
        <S.Link onClick={handleContinue}>
          <Trans i18nKey="upsell.noThanksLink" />
        </S.Link>
      </S.LinkContainer>

      <S.Disclaimer>
        <Trans
          i18nKey="upsell.upsellDescription.subscriptionDescriptionTrial"
          values={{
            trialPrice,
            price: fullPrice,
            currency: CURRENCY_SYMBOLS[currency],
          }}
        />
        <OuterLink
          href={TERMS_OF_USE_LINK}
          onClick={() => eventLogger.logTermsOfUseClicked(PagesSource.PAYWALL)}
        >
          <Trans i18nKey="commonComponents.termsOfUse" />
        </OuterLink>{' '}
        <Trans i18nKey="commonComponents.or" />{' '}
        <ContactWithSupport
          source={PagesSource.PAYWALL}
          text={t('commonComponents.contactSupportDisclaimer')}
        />
        <Trans i18nKey="upsell.upsellDescription.learnMore" />
      </S.Disclaimer>

      {isUpsellInProgress && <Spinner />}
    </S.Wrapper>
  )
}
