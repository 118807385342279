import { GeneralProduct } from 'modules/purchase/constants'

import { Cohort } from 'root-constants'

export const enum PricePadding {
  LOW = 10,
  HIGH = 28,
}

export const PLAN_NAME_BACKGROUND = {
  [Cohort.LUCKY_NY_2]: {
    [GeneralProduct.ONE_YEAR]: '#7ad164',
    [GeneralProduct.SIX_MONTHS]: '#bd4260',
  },
  [Cohort.LUCKY_NY_3]: {
    [GeneralProduct.ONE_YEAR]: '#7ad164',
    [GeneralProduct.SIX_MONTHS]: '#ff9d57',
    [GeneralProduct.THREE_MONTHS]: '#bd4260',
  },
}
