import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { AnimationItem } from 'lottie-web'
import lottie from 'lottie-web/build/player/lottie_light'

import {
  sendUserAnswersAction,
  updateUserConfigAction,
} from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectSubscriptionList,
} from 'root-redux/selects/common'
import { selectUserAvailableSpins } from 'root-redux/selects/user'

import { createProductId } from 'helpers/createProductId'

import {
  ANALYTIC_PRODUCTS,
  ATTEMPT_NUMBERS,
  AUDIO_START_POINT,
  CONFETTI_ANIMATION_SEGMENTS,
  CONFETTI_AVAILABILITY,
  GAME_STEP,
  TITLE_PATH,
  WHEEL_ANIMATION_SEGMENTS,
  WHEEL_ANIMATION_START_POINTS,
} from 'modules/purchase/components/FortuneWheel/constants'

import { eventLogger } from 'services/eventLogger.service'

import confetti from 'assets/animation/confetti.json'
import fortuneWheelFlow1 from 'assets/animation/fortuneWheelFlow1.json'
import fortuneWheelFlow2 from 'assets/animation/fortuneWheelFlow2.json'
import confettiAudio from 'assets/sounds/confetti.mp3'
import failAudio from 'assets/sounds/fail.mp3'
import wheelAudio from 'assets/sounds/wheel-spin.mp3'

import { Cohort } from 'root-constants'

import { StyledFortuneWheel as S } from './FortuneWheel.styles'

export const FortuneWheel: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const cohort = useSelector(selectCurrentVariantCohort)
  const availableSpins = useSelector(selectUserAvailableSpins)
  const subscriptions = useSelector(selectSubscriptionList)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort

  const wheelAnimationRef = useRef<HTMLDivElement>(null)
  const confettiAnimationRef = useRef<HTMLDivElement>(null)
  const counterRef = useRef(availableSpins)

  const [isAnimationInProgress, setIsAnimationInProgress] = useState(false)
  const [wheelAnimation, setWheelAnimation] = useState<AnimationItem | null>(
    null,
  )
  const [
    confettiAnimation,
    setConfettiAnimation,
  ] = useState<AnimationItem | null>(null)

  const wheelSound = useMemo(() => new Audio(wheelAudio), [])
  const confettiSound = useMemo(() => new Audio(confettiAudio), [])
  const failSound = useMemo(() => new Audio(failAudio), [])

  const title = useMemo(
    () => t(TITLE_PATH[cohortToUse as Cohort][availableSpins]),
    [t, cohortToUse, availableSpins],
  )

  const logAmplitudeEvent = useCallback(() => {
    const productId =
      cohortToUse && ANALYTIC_PRODUCTS[cohortToUse][counterRef.current]
    const preselectedSubscription = subscriptions.find(
      ({ product }) => product === productId,
    )

    if (preselectedSubscription) {
      const {
        mainPrices: { periodName, periodQuantity, fullPrice },
        trialPrices: { fullPrice: trialFullPrice },
      } = preselectedSubscription
      const eventProductId = createProductId({
        periodName,
        periodQuantity,
        price: trialFullPrice || fullPrice,
      })

      eventLogger.logPrizeSubscriptionShown(eventProductId)
    }
  }, [cohortToUse, subscriptions])

  const handleCompleteWheelAnimation = useCallback(() => {
    if (CONFETTI_AVAILABILITY[cohortToUse as Cohort][counterRef.current]) {
      confettiSound.play()
      confettiAnimation?.show()
      confettiAnimation?.playSegments(CONFETTI_ANIMATION_SEGMENTS, true)
      logAmplitudeEvent()
    }

    if (!CONFETTI_AVAILABILITY[cohortToUse as Cohort][counterRef.current]) {
      failSound.play()
    }

    counterRef.current -= GAME_STEP
    setIsAnimationInProgress(false)
    dispatch(updateUserConfigAction({ availableSpins: counterRef.current }))
    dispatch(sendUserAnswersAction())
  }, [
    dispatch,
    cohortToUse,
    confettiAnimation,
    confettiSound,
    failSound,
    logAmplitudeEvent,
  ])

  useEffect(() => {
    if (wheelAnimationRef.current) {
      const wheelAnimationInstance = lottie.loadAnimation({
        container: wheelAnimationRef.current,
        animationData:
          cohort === Cohort.LUCKY_NY_2 ? fortuneWheelFlow1 : fortuneWheelFlow2,
        name: 'wheel',
        autoplay: false,
        loop: false,
      })

      setWheelAnimation(wheelAnimationInstance)
      wheelAnimationInstance.goToAndStop(
        WHEEL_ANIMATION_START_POINTS[counterRef.current],
        true,
      )
      wheelAnimationInstance.addEventListener(
        'complete',
        handleCompleteWheelAnimation,
      )
    }

    return () => lottie.destroy('wheel')
  }, [cohort, handleCompleteWheelAnimation])

  useEffect(() => {
    if (confettiAnimationRef.current) {
      const confettiAnimationInstance = lottie.loadAnimation({
        container: confettiAnimationRef.current,
        animationData: confetti,
        name: 'confetti',
        autoplay: false,
        loop: false,
      })

      setConfettiAnimation(confettiAnimationInstance)
      confettiAnimationInstance.hide()
      confettiAnimationInstance.addEventListener('complete', () => {
        confettiAnimationInstance.hide()
      })
    }

    return () => lottie.destroy('confetti')
  }, [confettiSound])

  const resetSounds = useCallback(() => {
    confettiSound.pause()
    failSound.pause()
    confettiSound.currentTime = AUDIO_START_POINT
    failSound.currentTime = AUDIO_START_POINT
  }, [confettiSound, failSound])

  const handleSpin = useCallback(() => {
    setIsAnimationInProgress(true)
    wheelAnimation?.playSegments(WHEEL_ANIMATION_SEGMENTS[availableSpins], true)
    wheelSound.play()
    resetSounds()

    eventLogger.logSpinAndWinClicked(ATTEMPT_NUMBERS[availableSpins])
  }, [availableSpins, wheelAnimation, wheelSound, resetSounds])

  return (
    <S.Wrapper>
      <S.Title>{title}</S.Title>
      <S.AnimationContainer>
        <S.WheelAnimation ref={wheelAnimationRef} />
        <S.SpinButton
          onClick={handleSpin}
          disabled={isAnimationInProgress || !availableSpins}
        >
          <S.SpinButtonText>
            {t`purchaseNewYear.fortuneWheel.spinAndWin`}
          </S.SpinButtonText>
          <br />
          <S.SpinButtonAttempts>
            {t('purchaseNewYear.fortuneWheel.attempts', {
              count: availableSpins,
            })}
          </S.SpinButtonAttempts>
        </S.SpinButton>
        <S.ConfettiAnimation ref={confettiAnimationRef} />
      </S.AnimationContainer>
    </S.Wrapper>
  )
}
