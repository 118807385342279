import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import {
  selectUUID,
  selectUserConfig,
  selectUserOnboardingEmail,
} from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useCreatePaypalSubscription } from 'hooks/useCreatePaypalSubscription'

import { PaymentMethod } from 'modules/purchase/constants'

import { browserHistory } from 'browser-history'
import { SNAPCHAT_QUERY_PARAM } from 'root-constants'

export function usePaypalSubscriptionAfterRedirect(): void {
  const uuid = useSelector(selectUUID)
  const email = useSelector(selectUserOnboardingEmail)
  const subscriptionData = useSelector(selectUserConfig)

  const { search, pathname } = useLocation()
  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const {
    createSubscription,
    handleError,
    handleSuccess,
  } = useCreatePaypalSubscription()

  useEffect(() => {
    const URLParams = new URLSearchParams(search)
    const isSnapchatUser = URLParams.has(SNAPCHAT_QUERY_PARAM)

    if (!isSnapchatUser || !subscriptionData || !uuid) return

    const createPaypalSubscription = async () => {
      const paymentInformation = {
        productId: subscriptionData.price_id,
        productName: subscriptionData.product_name,
        price: subscriptionData.subscription_price,
        trialPrice: subscriptionData.trial_price,
        trialPeriodDays: Number(subscriptionData.trial_period),
        periodName: subscriptionData.period_name,
        periodQuantity: subscriptionData.period_quantity,
        paymentSystem: subscriptionData.payment_system,
        paymentMethod: PaymentMethod.PAYPAL,
        goal: subscriptionData.goal,
        screenName: subscriptionData.screen_name,
        currency: subscriptionData.payment_currency,
        uuid,
        email: isPersonalDataAllowed ? email : '',
      }

      const response = await createSubscription(subscriptionData.price_id)

      if (response.status) {
        URLParams.delete(SNAPCHAT_QUERY_PARAM)
        browserHistory.replace({
          pathname,
          search: URLParams.toString(),
        })

        handleSuccess(paymentInformation)
      } else {
        handleError({
          ...paymentInformation,
          error: response.data?.error,
        })
      }
    }

    createPaypalSubscription()
  }, [
    subscriptionData,
    createSubscription,
    email,
    handleError,
    handleSuccess,
    uuid,
    pathname,
    search,
    isPersonalDataAllowed,
  ])
}
