import React, { useCallback, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { LoseWeightGraphBelly } from 'components/LoseWeightGraphBelly'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectUserName,
} from 'root-redux/selects/common'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import usersRecommended from 'assets/images/recommended-by.png'

import { goTo } from 'browser-history'
import { Cohort } from 'root-constants'

import { StyledDancingVsExercises as S } from './PlanPageIsReady.styles'

export const PlanPageIsReady: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()

  const name = useSelector(selectUserName)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort

  useEffect(() => {
    eventLogger.logResultPageShown()
  }, [])

  const handleNextClick = useCallback(() => {
    eventLogger.logResultPageCompleted()

    goTo(nextPagePath)
  }, [nextPagePath])

  return (
    <>
      <ContainerBeforePinnedButton>
        <S.Container isLarge>
          <PageTitle marginBottom={24}>
            <Trans
              i18nKey={
                cohortToUse === Cohort.DB_LEGS
                  ? 'onboarding.planPageIsReady.titleLegs'
                  : 'onboarding.planPageIsReady.title'
              }
              values={{
                name,
              }}
            />
          </PageTitle>
          <S.AnimationContainer>
            <LoseWeightGraphBelly />
            <S.UserRecommended>
              <S.Img src={usersRecommended} alt="Users recommended" />
              <S.Text>{t('onboarding.planPageIsReady.recommendedBy')}</S.Text>
            </S.UserRecommended>
          </S.AnimationContainer>
        </S.Container>
      </ContainerBeforePinnedButton>

      <NavigationButtonBelly type="button" onClick={handleNextClick}>
        {t`actions.continue`}
      </NavigationButtonBelly>
    </>
  )
}
