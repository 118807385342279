import improveAreasWomanMxPng from 'assets/images/improve-areas-female-mx-age-flow.png'
import improveAreasWomanMxWebp from 'assets/images/improve-areas-female-mx-age-flow.webp'
import improveAreasManMxPng from 'assets/images/improve-areas-male-mx-age-flow.png'
import improveAreasManMxWebp from 'assets/images/improve-areas-male-mx-age-flow.webp'

import { Gender } from 'root-constants'

export const QUESTION = 'Which areas would you like to improve?'

export const BODY_IMAGE_MX_MAP_AGE_FLOW = {
  [Gender.MALE]: {
    PNG: improveAreasManMxPng,
    WEBP: improveAreasManMxWebp,
  },
  [Gender.FEMALE]: {
    PNG: improveAreasWomanMxPng,
    WEBP: improveAreasWomanMxWebp,
  },
}

export const OPTION_VALUES = {
  ARMS: 'arms',
  CHEST: 'chest',
  BELLY: 'belly',
  HIPS: 'hips',
  LEGS: 'legs',
  FULL_BODY: 'arms,chest,belly,hips,legs',
}
