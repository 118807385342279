import { StyledAgeFlowContainer } from 'pages/questions/Gender/Gender.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

export const StyledDancingStylesIntro = {
  ImageContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto 20px auto;
    aspect-ratio: 320/320;

    @media (min-height: 500px) and (max-height: 699px) {
      width: 100%;
      max-width: 225px;
    }
    @media (min-height: 699px) and (max-height: 799px) {
      width: 100%;
      max-width: 275px;
    }
  `,
  Title: styled.h1<{ $isAgeFlow: boolean }>`
    font-size: ${({ $isAgeFlow }) => ($isAgeFlow ? '18px' : '22px')};
    color: ${Color.DARK};
    font-weight: 700;
    line-height: 27px;
    text-align: center;
  `,
  AgeFlowPictureContainer: styled.div`
    width: 100%;
    max-width: 320px;
    min-height: 320px;
    margin: 32px auto 0;
  `,
  Container: styled(Container)<{
    $isAgeFlow: boolean
  }>`
    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        ${StyledAgeFlowContainer};
      `}
  `,
}
