import styled from 'styled-components'

import { Container } from 'components/Container'

import bgImage from 'assets/images/flow-belly/try-workouts-bg.png'
import bgImageLegs from 'assets/images/flow-belly/try-workouts-legs-bg.png'

export const StyledTryWorkouts = {
  Container: styled(Container)<{ $isLegsFlow: boolean }>`
    min-height: 560px;
    background-image: url(${({ $isLegsFlow }) =>
      $isLegsFlow ? bgImageLegs : bgImage});
    background-size: 375px 500px;
    background-repeat: no-repeat;
    background-position: bottom;
  `,
}
