import {
  Currency,
  PaymentMethod,
  PaymentSystem,
  TCardPaymentSystem,
} from 'modules/purchase/constants'

import { IUserSubscriptionsInfo } from 'models/commonApi.model'
import { IAppState } from 'models/store.model'
import { IUserStatus, IUserStatusConfig } from 'models/user.model'
import { IUserInfo } from 'models/userInfo.model'

import { LoginMethod } from 'root-constants'

export const selectUUID = (state: IAppState): string => state.user.uuid
export const selectUserStatus = (state: IAppState): IUserStatus | null =>
  state.user.status
export const selectUserOnboardingEmail = (state: IAppState): string =>
  state.user.status?.email?.onboardingEmail || ''
export const selectUserInfo = (state: IAppState): IUserInfo | null =>
  state.user.userInfo
export const selectUserSubscriptionId = (state: IAppState): string =>
  state.user.userInfo?.subscriptionId || ''
export const selectUserAccountLoginMethod = (
  state: IAppState,
): LoginMethod | null => state.user.status?.account?.loginMethod || null
export const selectUserLoginEmail = (state: IAppState): string =>
  state.user.status?.account?.email || ''
export const selectIsOnboardingSkipped = (state: IAppState): boolean =>
  state.user.status?.isOnboardingSkipped || false
export const selectUserPaymentCurrency = (state: IAppState): string =>
  state.user.status?.config.payment_currency || Currency.USD
export const selectUserPaymentMethod = (state: IAppState): PaymentMethod =>
  state.user.status?.config.payment_method || PaymentMethod.CREDIT_CARD
export const selectGeneralSubscriptionPrice = (state: IAppState): string =>
  state.user.status?.config.subscription_price || ''
export const selectGeneralSubscriptionDuration = (state: IAppState): string =>
  state.user.status?.config.subscription_duration || ''
export const selectGeneralSubscriptionPriceId = (state: IAppState): string =>
  state.user.status?.config.price_id || ''
export const selectGeneralSubscriptionTrialPeriod = (
  state: IAppState,
): string => state.user.status?.config.trial_period || ''
export const selectGeneralSubscriptionTrialPrice = (state: IAppState): string =>
  state.user.status?.config.trial_price || ''
export const selectIsDownloadVisited = (state: IAppState): boolean =>
  state.user.status?.config.is_download_visited || false
export const selectIsDiscountApplied = (state: IAppState): boolean =>
  state.user.status?.config.discount_applied || false
export const selectPeriodQuantity = (state: IAppState): string =>
  state.user.status?.config.period_quantity?.toString() || ''
export const selectSecret = (state: IAppState): string => state.user.secret
export const selectAuthToken = (state: IAppState): string =>
  state.user.authToken
export const selectUserSubscriptionInfo = (
  state: IAppState,
): IUserSubscriptionsInfo | null => state.user.userSubscriptionsInfo
export const selectUserContactEmail = (state: IAppState): string =>
  state.user.userContactEmail
export const selectDeletedSubscriptionId = (state: IAppState): string =>
  state.user.deletedSubscriptionId
export const selectTestEnvironmentQueryParam = (state: IAppState): string =>
  state.user.testEnvironmentQueryParam
export const selectUserCountryCode = (state: IAppState): string =>
  state.user.status?.countryCode || ''
export const selectUserConfig = (
  state: IAppState,
): IUserStatusConfig | undefined => state.user.status?.config
export const selectUserAvailableSpins = (state: IAppState): number =>
  state.user.status?.config?.availableSpins || 0
export const selectUserPaymentSystem = (state: IAppState): TCardPaymentSystem =>
  state.user.status?.config?.payment_system || PaymentSystem.STRIPE
export const selectAutologinToken = (state: IAppState): string =>
  state.user.autologinToken
export const selectUserProductName = (state: IAppState): string =>
  state.user.status?.config?.product_name || ''
export const selectUserUpsellProductName = (state: IAppState): string =>
  state.user.status?.config?.upsell_product_name || ''
export const selectUserUpsellProductPrice = (state: IAppState): string =>
  state.user.status?.config?.upsell_product_price || ''
export const selectIsUpsellPurchased = (state: IAppState): boolean =>
  state.user.status?.upsell.isUpsellPurchased || false
export const selectIsEmailConsentTried = (state: IAppState): boolean =>
  state.user.status?.email?.isEmailConsentTried || false
export const selectHasEmail = (state: IAppState): boolean =>
  state.user.status?.email?.hasEmail || false
export const selectUserPaymentId = (state: IAppState): string =>
  state.user.status?.config?.payment_id || ''
