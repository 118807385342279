import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Answer, answerTheme } from 'storybook-ui'

import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Cohort } from 'root-constants'

import { StyledTryWorkouts as S } from './TryWorkouts.styles'
import { OPTION_VALUES, QUESTION, QUESTION_LEGS } from './constants'

export const TryWorkouts: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const isLegsFlow = useMemo(() => cohortToUse === Cohort.DB_LEGS, [
    cohortToUse,
  ])

  const handleContinue = useNextStep({
    pageId,
    question: isLegsFlow ? QUESTION_LEGS : QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <S.Container isLarge $isLegsFlow={isLegsFlow}>
      <PageTitle marginBottom={24}>
        <Trans
          i18nKey={
            isLegsFlow
              ? 'onboarding.tryWorkouts.titleLegs'
              : 'onboarding.tryWorkouts.title'
          }
        />
      </PageTitle>
      {OPTION_VALUES.map(({ value, title }) => (
        <Answer
          {...optionProps}
          disabled={isAnswersDisabled}
          theme={answerTheme.DANCEBIT}
          maxWidth="180px"
          margin="0 0 16px 0"
          borderRadius="16px"
          key={value}
          value={value}
        >
          {t(title)}
        </Answer>
      ))}
    </S.Container>
  )
}
