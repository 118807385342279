import { StyledAgeFlowContainer } from 'pages/questions/Gender/Gender.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'
import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

export const StyledGoalWeight = {
  WeightLabel: styled.div`
    width: 90px;
    height: 34px;
    margin: 0 auto 30px;
    border-radius: 25px;
    background-color: ${Color.LIGHT};
    font-weight: 500;
    font-size: 14px;
    line-height: 34px;
    color: ${Color.DARK};
    text-align: center;
  `,
  Container: styled(Container)<{ $isAgeFlow: boolean }>`
    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        padding: 0 16px;
        ${StyledAgeFlowContainer};
      `}
  `,
  WeightDisclaimer: styled.div<{ $bgColor: string }>`
    height: 106px;
    width: 100%;
    border-radius: 12px;
    background: ${({ $bgColor }) => $bgColor};
    padding: 12px 16px;
    margin-top: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  `,
  Description: styled.p`
    opacity: 0.5;
    margin-top: 2px;
  `,
  Title: styled.p<{ $color: string }>`
    color: ${({ $color }) => $color};
    font-weight: 900;
    line-height: 20px;
    margin-bottom: 6px;
  `,
  Icon: styled(SvgImage)`
    margin-right: 4px;
    margin-bottom: -1px;
  `,
}
