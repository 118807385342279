import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { NavigationButton } from 'components/NavigationButton'
import { PageTitle } from 'components/PageTitle'

import {
  selectAnswers,
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import heartInHeadphonesImage from 'assets/images/heart-in-headphones.png'
import heartInHeadphonesImageWebp from 'assets/images/heart-in-headphones.webp'
import niceToMeetYouBgMx from 'assets/images/nice-to-meet-you-mx.png'
import niceToMeetYouBgMxWebp from 'assets/images/nice-to-meet-you-mx.webp'
import niceToMeetYouBg from 'assets/images/nice-to-meet-you.png'
import niceToMeetYouBgWebp from 'assets/images/nice-to-meet-you.webp'

import { ADULT_COHORT, Cohort, GROWTHBOOK_EXPERIMENT } from 'root-constants'

import { StyledNiceToMeet as S } from './NiceToMeet.styles'
import { QUESTION } from './constants'

export const NiceToMeet: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const answers = useSelector(selectAnswers)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const hasNewIllustrations = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_599)

  const cohortToUse = parentCohort || cohort

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])

  return (
    <div>
      <S.Container $isAgeFlow={isAgeFlow}>
        <PageTitle>
          {t(
            isAgeFlow
              ? 'onboarding.niceToMeet.questionAgeFlow'
              : 'onboarding.niceToMeet.question',
            { name: answers?.name },
          )}
        </PageTitle>
        {!isAgeFlow && (
          <S.PictureContainer>
            <picture>
              <source srcSet={heartInHeadphonesImageWebp} type="image/webp" />
              <img src={heartInHeadphonesImage} alt="nice to meet you" />
            </picture>
          </S.PictureContainer>
        )}

        <S.Subtitle>
          {t(
            isAgeFlow
              ? 'onboarding.niceToMeet.subtitleAgeFlow'
              : 'onboarding.niceToMeet.subtitle',
          )}
        </S.Subtitle>

        {isAgeFlow && (
          <S.AgeFlowPictureContainer>
            <picture>
              <source
                srcSet={
                  hasNewIllustrations
                    ? niceToMeetYouBgMxWebp
                    : niceToMeetYouBgWebp
                }
                type="image/webp"
              />
              <img
                src={hasNewIllustrations ? niceToMeetYouBgMx : niceToMeetYouBg}
                alt="nice to meet you"
              />
            </picture>
          </S.AgeFlowPictureContainer>
        )}
      </S.Container>

      <NavigationButton
        type="button"
        isAgeFlow={isAgeFlow}
        onClick={handleClick}
      >{t`actions.continue`}</NavigationButton>
    </div>
  )
}
