import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { Container } from 'components/Container'
import { NavigationButton } from 'components/NavigationButton'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import tenMxMillionPeopleImage from 'assets/images/ten-million-mx-people.png'
import tenMillionPeopleImage from 'assets/images/ten-million-people.png'

import { GROWTHBOOK_EXPERIMENT } from 'root-constants'

import { StyledTenMillionPeople as S } from './TenMillionPeople.styles'
import { QUESTION } from './constants'

export const TenMillionPeople: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const hasNewIllustrations = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_599)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])

  return (
    <>
      <S.PictureContainer>
        <img
          src={
            hasNewIllustrations
              ? tenMxMillionPeopleImage
              : tenMillionPeopleImage
          }
          alt="10 million people have chosen dancebit"
        />
      </S.PictureContainer>
      <Container>
        <S.Title>{t('onboarding.tenMillionPeople.question')}</S.Title>
        <S.Subtitle>{t('onboarding.tenMillionPeople.subtitle')}</S.Subtitle>

        <NavigationButton
          type="button"
          onClick={handleClick}
        >{t`actions.continue`}</NavigationButton>
      </Container>
    </>
  )
}
