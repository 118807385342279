import React, { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import Swiper, { Autoplay, Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import {
  selectAnswers,
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectIsStayFitFlow,
  selectLanguage,
} from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import {
  SLIDES_RESULT,
  SLIDES_RESULT_LEGS,
} from 'modules/purchase/pages/PurchaseWithoutIntro/constant'

import bgImage from 'assets/images/reach-target-stay-fit-bg.png'

import {
  Cohort,
  GROWTHBOOK_EXPERIMENT,
  Locale,
  MAIN_GOALS,
  MeasurementSystem,
} from 'root-constants'

import { StyledReachTarget as S } from './ReachTarget.styles'

Swiper.use([Pagination, Autoplay])

export const ReachTarget: FC = () => {
  const { t } = useTranslation()

  const userAnswer = useSelector(selectAnswers)
  const isStayFitGoal = useSelector(selectIsStayFitFlow)
  const language = useSelector(selectLanguage)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const cohortToUse = parentCohort || cohort

  const { title } = useDynamicPaywallConfig()

  const hasPaywallTestBelly = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_802)
  const hasPaywallTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_734)

  const isMetricSystemSelected = useMemo(
    () => userAnswer?.measurementSystem === MeasurementSystem.METRIC,
    [userAnswer],
  )

  const isStayFitFlow = useMemo(
    () =>
      isStayFitGoal &&
      cohortToUse !== Cohort.DB_FLOW_2 &&
      cohortToUse !== Cohort.DB_BELLY,
    [cohortToUse, isStayFitGoal],
  )

  const isSpanish = useMemo(() => language === Locale.SPANISH, [language])
  const isFrench = useMemo(() => language === Locale.FRENCH, [language])
  const isItalian = useMemo(() => language === Locale.ITALIAN, [language])
  const isGerman = useMemo(() => language === Locale.DEUTSCH, [language])
  const isPortuguese = useMemo(() => language === Locale.PORTUGUESE, [language])

  return (
    <S.Wrapper
      $isStayFitFlow={isStayFitFlow}
      $isLarge={
        cohortToUse === Cohort.DB_BELLY || cohortToUse === Cohort.DB_LEGS
      }
    >
      <S.Text
        $isStayFitFlow={isStayFitFlow}
        $isSpanish={isSpanish}
        $isFrench={isFrench}
        $isItalian={isItalian}
        $isGerman={isGerman}
        $isPortuguese={isPortuguese}
        $isMetricSystemSelected={isMetricSystemSelected}
      >
        {title || (
          <Trans
            i18nKey="purchaseWithoutIntro.textReachTarget"
            values={{
              target: userAnswer?.goalWeight || 77,
              measurementSystem: isMetricSystemSelected
                ? t('commonComponents.metric')
                : t('commonComponents.imperial'),
              context: isStayFitFlow
                ? MAIN_GOALS.STAY_FIT
                : MAIN_GOALS.LOSE_WEIGHT,
            }}
            components={{
              strong: <strong />,
              span: <span />,
            }}
          />
        )}
      </S.Text>

      <S.CarouselContainer
        $hasPaywallTest={hasPaywallTest || hasPaywallTestBelly}
      >
        {isStayFitFlow ? (
          <S.BgImage src={bgImage} />
        ) : (
          <Carousel
            spaceBetween={12}
            slidesPerView={1}
            loop
            pagination={{ clickable: false }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
          >
            {(cohortToUse === Cohort.DB_LEGS
              ? SLIDES_RESULT_LEGS
              : SLIDES_RESULT
            ).map((src) => (
              <SwiperSlide key={src}>
                <S.PhotoResultWrapper>
                  <S.Image src={src} alt="before" />
                </S.PhotoResultWrapper>
              </SwiperSlide>
            ))}
          </Carousel>
        )}
      </S.CarouselContainer>
    </S.Wrapper>
  )
}
