import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { HeaderNewYear } from 'components/HeaderNewYear'

import { sendFacebookParamsAction } from 'root-redux/actions/user'
import { selectUUID } from 'root-redux/selects/user'

import { useNextStep } from 'hooks/useNextStep'
import { useStartSession } from 'hooks/useStartSession'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { StyledIntroNewYear as S } from './IntroNewYear.styles'
import { QUESTION } from './constants'

export const IntroNewYear: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)

  useStartSession()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    dispatch(sendFacebookParamsAction())
    window.fbq('track', 'CustomizeProduct', {}, { eventID: uuid })
    googleAnalyticsLogger.logFirstPageCompleted()
    eventLogger.logFirstPageCompleted({
      question: QUESTION,
      answers: '',
    })

    handleContinue('')
  }, [handleContinue, uuid, dispatch])

  return (
    <>
      <S.Root>
        <HeaderNewYear />
        <S.Container>
          <S.Title>{t`onboarding.introNewYear.title`}</S.Title>
          <S.Subtitle>{t`onboarding.introNewYear.subtitle`}</S.Subtitle>
        </S.Container>

        <S.Container>
          <S.Button
            onClick={handleNextClick}
          >{t`onboarding.introNewYear.buttonText`}</S.Button>
          <S.Footer />
        </S.Container>
      </S.Root>
    </>
  )
}
