import styled, { css } from 'styled-components'

import { Color, DynamicDiscountTheme } from 'root-constants'

export const StyledDynamicTimer = {
  Container: styled.div<{ $theme?: string }>`
    display: flex;
    width: 100%;
    height: 44px;
    padding: 8px 10px;
    justify-content: center;
    align-items: baseline;
    border-radius: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.WHITE};
    margin-bottom: 32px;

    & > div {
      margin: 0 4px;
    }

    ${({ $theme }) => {
      switch ($theme) {
        case DynamicDiscountTheme.CORAL:
          return css`
            background: linear-gradient(91deg, #ff5343 -8.21%, #e1226c 95.97%);
          `
        case DynamicDiscountTheme.YELLOW:
          return css`
            background: linear-gradient(91deg, #ffce3c -8.21%, #ff9000 95.97%);
          `
        case DynamicDiscountTheme.VIOLET:
          return css`
            background: linear-gradient(270deg, #7470ff 0%, #e2a3f8 103.03%);
          `
        default:
          return css`
            background: linear-gradient(270deg, #7470ff 0%, #e2a3f8 103.03%);
          `
      }
    }}
  `,
}
