import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectUserConfig } from 'root-redux/selects/user'

import { CookieConsentOption } from 'root-constants'

export const useCookieConsentAnswer = () => {
  const config = useSelector(selectUserConfig)

  // todo: uncomment when CookiesConsent feature is ready
  // const isEUUser = EU_COUNTRY_CODES.includes(userCountryCode.toUpperCase())
  const isEUUser = false

  const { userCookieConsentAnswer } = useMemo(
    () => ({
      userCookieConsentAnswer: config?.cookie_consent as string[],
    }),
    [config],
  )

  const hasAdvertizingAndMarketingCookie = useMemo(
    () =>
      userCookieConsentAnswer?.includes(
        CookieConsentOption.ADVERTIZING_AND_TARGETING,
      ),
    [userCookieConsentAnswer],
  )

  const hasFunctionalCookie = useMemo(
    () =>
      userCookieConsentAnswer?.includes(CookieConsentOption.FUNCTIONAL_COOKIES),
    [userCookieConsentAnswer],
  )

  const hasAnalyticsAndPerformanceCookie = useMemo(
    () =>
      userCookieConsentAnswer?.includes(
        CookieConsentOption.ANALYTICS_AND_PERFORMANCE,
      ),
    [userCookieConsentAnswer],
  )

  const isPersonalDataAllowed = useMemo(
    () => (isEUUser && hasFunctionalCookie) || !isEUUser,
    [isEUUser, hasFunctionalCookie],
  )

  return {
    isEUUser,
    hasAdvertizingAndMarketingCookie,
    hasFunctionalCookie,
    hasAnalyticsAndPerformanceCookie,
    userCookieConsentAnswer,
    isPersonalDataAllowed,
  }
}
