import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSubscriptionsBlockNewYear = {
  Wrapper: styled.div`
    max-width: 360px;
  `,
  SubscriptionContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  `,
  CardShirt: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 106px;
    min-height: 148px;
    background: ${Color.LAVENDER_LIGHT};
    border-radius: 16px;
    cursor: initial;
  `,
  NoPrize: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 106px;
    min-height: 148px;
    padding: 10px 6px;
    background: ${Color.LAVENDER_LIGHT};
    border: 3px solid #eff2f5;
    border-radius: 16px;
    order: 2;
  `,
  FailText: styled.p`
    font-weight: 800;
    font-size: 12px;
    line-height: 14px;
  `,
  Disclaimer: styled.p`
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #a7adb6;
  `,
}
