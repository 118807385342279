import React, { FC, useMemo, useRef } from 'react'
import { Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { BannerWithButton } from 'components/BannerWithButton'
import { OptionType } from 'components/Option'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { SvgImage } from 'components/SvgImage'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { sendFacebookParamsAction } from 'root-redux/actions/user'
import { selectUUID } from 'root-redux/selects/user'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'
import { useStartSession } from 'hooks/useStartSession'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import brenchSvg from 'assets/images/brench.svg'
import gloriaImg from 'assets/images/gloria-img.jpg'
import arrowSvg from 'assets/images/sprite/arrow.svg'

import { PageId } from 'page-constants'
import { PagesSource } from 'root-constants'

import { StyledPersonalHistory as S } from './PersonalHistory.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const PersonalHistory: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const buttonWrapperElementRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)
  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  useStartSession()

  const handleContinue = useNextStep({
    pageId: PageId.GENDER,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        dispatch(sendFacebookParamsAction())
        window.fbq('track', 'CustomizeProduct', {}, { eventID: uuid })
        googleAnalyticsLogger.logFirstPageCompleted()
        eventLogger.logFirstPageCompleted({
          question: QUESTION,
          answers: value,
        })
        eventLogger.logSelectedGender(value)
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [
      pageId,
      dispatch,
      uuid,
      handleChange,
      handleContinue,
      setIsAnswersDisabled,
    ],
  )

  return (
    <>
      <S.BgImage />
      <S.Container>
        <S.TitleContainer>
          <S.SVGContainer />

          <S.Title>
            <Trans
              i18nKey="onboarding.personalHistory.title"
              components={[<strong />]}
            />
          </S.Title>
          <S.Subtitle>
            <Trans i18nKey="onboarding.personalHistory.subtitle" />
          </S.Subtitle>
        </S.TitleContainer>

        <S.BeforeAfterBlock>
          <S.BeforeAfterImg src={gloriaImg} alt="Gloria’s results" />
        </S.BeforeAfterBlock>

        <S.ButtonBlock ref={buttonWrapperElementRef}>
          <S.ButtonTitle>
            <Trans i18nKey="onboarding.personalHistory.befortAfterTitle" />
          </S.ButtonTitle>

          <S.ButtonContainer>
            <S.StyledOption
              {...optionProps}
              withoutMargin
              disabled={isAnswersDisabled}
              value={OPTION_VALUES.FEMALE}
            >
              <S.Action>
                <Trans
                  i18nKey="onboarding.personalHistory.female"
                  components={{
                    icon: <SvgImage svg={arrowSvg} height={10} width={16} />,
                  }}
                />
              </S.Action>
            </S.StyledOption>
            <S.StyledOption
              {...optionProps}
              withoutMargin
              disabled={isAnswersDisabled}
              value={OPTION_VALUES.MALE}
            >
              <S.Action>
                <Trans
                  i18nKey="onboarding.personalHistory.male"
                  components={{
                    icon: <SvgImage svg={arrowSvg} height={10} width={16} />,
                  }}
                />
              </S.Action>
            </S.StyledOption>
          </S.ButtonContainer>
        </S.ButtonBlock>

        <div>
          <S.HistoryText>
            <Trans i18nKey="onboarding.personalHistory.firstParagraphs" />
          </S.HistoryText>

          <S.HistoryText>
            <Trans i18nKey="onboarding.personalHistory.secondParagraphs" />
          </S.HistoryText>

          <S.HistoryText>
            <Trans i18nKey="onboarding.personalHistory.thirdParagraphs" />
          </S.HistoryText>

          <S.HistoryText>
            <Trans i18nKey="onboarding.personalHistory.fourthParagraphs" />
          </S.HistoryText>

          <S.HistoryText>
            <Trans i18nKey="onboarding.personalHistory.fifthParagraphs" />
          </S.HistoryText>
        </div>

        <S.ButtonBlock>
          <S.ButtonTitle>
            <Trans i18nKey="onboarding.personalHistory.startNow" />
          </S.ButtonTitle>

          <S.ButtonContainer>
            <S.StyledOption
              {...optionProps}
              withoutMargin
              disabled={isAnswersDisabled}
              value={OPTION_VALUES.FEMALE}
            >
              <S.Action>
                <Trans
                  i18nKey="onboarding.personalHistory.female"
                  components={{
                    icon: <SvgImage svg={arrowSvg} height={10} width={16} />,
                  }}
                />
              </S.Action>
            </S.StyledOption>
            <S.StyledOption
              {...optionProps}
              withoutMargin
              disabled={isAnswersDisabled}
              value={OPTION_VALUES.MALE}
            >
              <S.Action>
                <Trans
                  i18nKey="onboarding.personalHistory.male"
                  components={{
                    icon: <SvgImage svg={arrowSvg} height={10} width={16} />,
                  }}
                />
              </S.Action>
            </S.StyledOption>
          </S.ButtonContainer>
        </S.ButtonBlock>

        <S.Text>
          <Trans i18nKey="onboarding.personalHistory.findNew" />
        </S.Text>

        <S.TrusedBlock>
          <S.Brench src={brenchSvg} />
          <S.TrusedInfo>
            <span>
              <Trans i18nKey="onboarding.personalHistory.trust" />
            </span>
            <span>
              <Trans
                i18nKey="onboarding.personalHistory.users"
                components={[<strong />]}
              />
            </span>
            <span>
              <Trans
                i18nKey="onboarding.personalHistory.members"
                components={[<strong />]}
              />
            </span>
          </S.TrusedInfo>
          <S.Brench src={brenchSvg} />
        </S.TrusedBlock>

        <S.Disclaimer>
          <Trans i18nKey="onboarding.personalHistory.disclaimer" />
        </S.Disclaimer>

        <S.Terms>
          <p>
            <Trans i18nKey="footer.disclaimer" />
          </p>
          <TermsOfUseLink source={PagesSource.LANDING} />
          <S.Separator />
          <PrivacyPolicyLink source={PagesSource.LANDING} />
          <p>
            <Trans
              i18nKey="footer.allRightsReserved"
              values={{
                year: new Date().getFullYear(),
              }}
            />
          </p>
        </S.Terms>
      </S.Container>
      <BannerWithButton
        elemForComparisonRef={buttonWrapperElementRef}
        isAnswersDisabled={isAnswersDisabled}
        optionProps={optionProps}
      />
    </>
  )
}
