/* eslint-disable max-lines */
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { PrimerPaymentForm } from 'components/PrimerPaymentForm'
import { Spinner } from 'components/Spinner'

import {
  resetErrorAction,
  setSubscriptionListAction,
} from 'root-redux/actions/common'

import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useHasPayPalButton } from 'hooks/useHasPayPalButton'
import { useVatInfo } from 'hooks/useVatInfo'

import { createProductId } from 'helpers/createProductId'
import { getCalculatedPrice } from 'helpers/getCalculatedPrice'

import { CreditCardFormCancelOffer } from 'modules/purchase/components/CreditCardFormCancelOffer'
import { PayPalButton } from 'modules/purchase/components/PayPalButton'
import { PaymentPrimerWaitingModal } from 'modules/purchase/components/PaymentPrimerWaitingModal'
import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { StripeSeparatePaymentRequestButton } from 'modules/purchase/components/StripeSeparatePaymentRequestButton'
import {
  CANCEL_OFFER_ADDITIONAL_DISCOUNT,
  DECORATING_DISCOUNT,
  DEFAULT_CANCEL_OFFER_DYNAMIC_DISCOUNT,
  DEFAULT_DYNAMIC_DISCOUNT,
  ONE_HUNDRED_PERCENTS,
  PaymentMethod,
} from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  GET_PRIMER_CLIENT_SESSION_TOKEN,
  SET_PAYMENT_FORM_IS_LOADING,
} from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import SecuritySystems from 'assets/images/security-systems.png'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  Cohort,
  GROWTHBOOK_EXPERIMENT,
  SEVEN_DAY_PLAN_COHORTS,
  SEVEN_DAY_TRIAL_DURATION,
  ScreenName,
  TRIAL_COHORT,
} from 'root-constants'

import { StyledCheckoutSeparateMethods as S } from './CheckoutSeparateMethods.styles'
import { PAYMENT_METHODS_IMAGES } from './constants'

type TCheckoutExtended = {
  setIsCheckoutShown: (value: boolean) => void
  isCancelOfferPage?: boolean
  isCheckoutShown: boolean
}

const paymentMethodsInOrder = [
  PaymentMethod.CREDIT_CARD,
  PaymentMethod.PAYPAL,
  PaymentMethod.APPLE_PAY,
  PaymentMethod.GOOGLE_PAY,
]

export const CheckoutSeparateMethods: React.FC<TCheckoutExtended> = ({
  isCheckoutShown,
  setIsCheckoutShown,
  isCancelOfferPage = false,
}: TCheckoutExtended) => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const dispatch = useDispatch()

  const isPrimerActive = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_8)

  const hasVatInfo = useVatInfo()
  const hasExcludedVat = useVatInfo(true)

  const hasPaypalButton = useHasPayPalButton()

  const paymentFormRef = useRef<HTMLDivElement>(null)

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    PaymentMethod
  >(PaymentMethod.CREDIT_CARD)

  const [availablePaymentMethods, setAvailablePaymentMethods] = useState<
    PaymentMethod[]
  >([PaymentMethod.CREDIT_CARD])

  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState<boolean>(
    false,
  )
  const [isScrolled, setIsScrolled] = useState<boolean>(false)

  const {
    uuid,
    subscriptions,
    periodName,
    periodQuantity,
    trialPrice,
    fullPrice,
    currency,
    cohort,
    stripeAccountId,
    stripeAccountName,
    discountAmount,
    hasCancelOffer,
    screenName,
    screenId,
    discountPercentage,
    selectedSubscription,
    fetchingActionsList,
    threeDSecureIframeUrl,
    countryCode,
    fullPriceTax,
    oldPriceBeforeCustomPercentDiscount,
    taxAmount,
    dynamicDiscount,
    parentCohort,
  } = usePurchaseStore()

  const cohortToUse = parentCohort || cohort

  const isSaleFlow = useMemo(
    () => cohort === Cohort.DB_BELLY_SALE || cohort === Cohort.DB_ADULT_SALE,
    [cohort],
  )

  const isCheckoutReady = useMemo(
    () =>
      !fetchingActionsList.includes(SET_PAYMENT_FORM_IS_LOADING) &&
      !fetchingActionsList.includes(GET_PRIMER_CLIENT_SESSION_TOKEN) &&
      !fetchingActionsList.includes(CHECK_PAYMENT_REQUEST_BUTTON),
    [fetchingActionsList],
  )

  const currencyLabel = useMemo(() => currency.toUpperCase(), [currency])

  const productId = useMemo(
    () =>
      createProductId({
        periodName,
        periodQuantity,
        price: trialPrice || fullPrice,
      }),
    [periodName, periodQuantity, trialPrice, fullPrice],
  )

  const isSevenDayPlan = useMemo(
    () =>
      SEVEN_DAY_PLAN_COHORTS.includes(cohortToUse as Cohort) &&
      periodQuantity === Number(SEVEN_DAY_TRIAL_DURATION),
    [cohortToUse, periodQuantity],
  )

  const isTrialCohort = useMemo(
    () => TRIAL_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const getCurrentPrice = useCallback(
    (value?: number | string) =>
      hasVatInfo && value
        ? getCalculatedPrice(value, taxAmount, countryCode)
        : value || '',

    [countryCode, hasVatInfo, taxAmount],
  )

  const getPriceWithFormattedDiscount = useCallback(
    (subscriptionPrice: number, discount: number) => {
      const calculatedOldPrice =
        subscriptionPrice /
        ((ONE_HUNDRED_PERCENTS - discount) / ONE_HUNDRED_PERCENTS)

      return (
        Math.ceil(+getCurrentPrice(calculatedOldPrice)) - DECORATING_DISCOUNT
      )
    },
    [getCurrentPrice],
  )

  const subscription = useMemo(() => {
    if (isSaleFlow && selectedSubscription) {
      const defaultDiscount =
        dynamicDiscount?.amount ||
        (isCancelOfferPage
          ? DEFAULT_CANCEL_OFFER_DYNAMIC_DISCOUNT
          : DEFAULT_DYNAMIC_DISCOUNT)

      const percentage = isCancelOfferPage
        ? defaultDiscount + CANCEL_OFFER_ADDITIONAL_DISCOUNT
        : defaultDiscount

      const totalAmount = getPriceWithFormattedDiscount(
        selectedSubscription.trialPrices.fullPrice,
        percentage,
      )

      const taxDiscount = hasExcludedVat
        ? selectedSubscription.trialPrices.fullPriceTax
        : 0

      return {
        percentage,
        totalAmount: totalAmount.toFixed(2),
        discountAmount: (
          totalAmount -
          selectedSubscription.trialPrices.fullPrice +
          taxDiscount
        ).toFixed(2),
      }
    }

    if (selectedSubscription) {
      return {
        percentage: discountPercentage || 50,
        totalAmount:
          selectedSubscription.trialPrices.durationDays ===
          Number(SEVEN_DAY_TRIAL_DURATION)
            ? getCurrentPrice(
                selectedSubscription.trialPrices.oldPrices.beforeCustomDiscount
                  ?.fullPrice,
              )
            : getCurrentPrice(
                isTrialCohort
                  ? fullPrice
                  : oldPriceBeforeCustomPercentDiscount.fullPrice,
              ),
        discountAmount: getCurrentPrice(
          isTrialCohort
            ? discountAmount
            : oldPriceBeforeCustomPercentDiscount.amountOfDiscount,
        ),
      }
    }

    return {
      percentage: discountPercentage || 50,
      totalAmount: 0,
      discountAmount,
    }
  }, [
    isSaleFlow,
    selectedSubscription,
    discountPercentage,
    discountAmount,
    dynamicDiscount,
    isCancelOfferPage,
    getPriceWithFormattedDiscount,
    hasExcludedVat,
    getCurrentPrice,
    isTrialCohort,
    fullPrice,
    oldPriceBeforeCustomPercentDiscount,
  ])

  const totalDiscount = useMemo(() => {
    const discountValue = isSaleFlow
      ? subscription.discountAmount
      : getCurrentPrice(
          isTrialCohort
            ? discountAmount
            : oldPriceBeforeCustomPercentDiscount.amountOfDiscount,
        )

    return `-${discountValue} ${currencyLabel}`
  }, [
    getCurrentPrice,
    isTrialCohort,
    oldPriceBeforeCustomPercentDiscount,
    currencyLabel,
    discountAmount,
    isSaleFlow,
    subscription,
  ])

  const addAvailablePaymentMethod = useCallback(
    (paymentMethod: PaymentMethod) => {
      if (availablePaymentMethods.includes(paymentMethod)) return

      setAvailablePaymentMethods([...availablePaymentMethods, paymentMethod])
    },
    [availablePaymentMethods],
  )

  useLayoutEffect(() => {
    if (hasPaypalButton) {
      addAvailablePaymentMethod(PaymentMethod.PAYPAL)
    }
  }, [hasPaypalButton, addAvailablePaymentMethod])

  useEffect(() => {
    if (availablePaymentMethods.length && isCheckoutShown) {
      if (availablePaymentMethods.includes(PaymentMethod.APPLE_PAY)) {
        setSelectedPaymentMethod(PaymentMethod.APPLE_PAY)
        return
      }

      if (availablePaymentMethods.includes(PaymentMethod.GOOGLE_PAY)) {
        setSelectedPaymentMethod(PaymentMethod.GOOGLE_PAY)
        return
      }

      setSelectedPaymentMethod(PaymentMethod.CREDIT_CARD)
    }
  }, [isCheckoutShown, availablePaymentMethods])

  useAmplitudeInitialization(cohort as Cohort, ScreenName.CHECKOUT)

  useEffect(() => {
    if (productId && isCheckoutShown && selectedSubscription) {
      eventLogger.logPurchaseShown({
        productId,
        screenName,
        screenId,
        accountId: stripeAccountId,
        accountName: stripeAccountName,
      })

      window.fbq('track', 'InitiateCheckout', {}, { eventID: uuid })
      googleAnalyticsLogger.logCheckoutStarted(subscriptions)
      window.obApi && window.obApi('track', 'Checkout')
      window._tfa &&
        window._tfa.push({
          notify: 'event',
          name: 'start_checkout',
        })
    }
  }, [
    uuid,
    subscriptions,
    isCheckoutShown,
    productId,
    screenId,
    screenName,
    stripeAccountId,
    stripeAccountName,
    selectedSubscription,
  ])

  useEffect(() => {
    const { current: elem } = paymentFormRef
    setIsScrolled(false)

    if (
      elem &&
      isCheckoutReady &&
      selectedPaymentMethod === PaymentMethod.CREDIT_CARD
    ) {
      elem.scrollIntoView({
        behavior: 'smooth',
      })
      setIsScrolled(true)
    }
  }, [isCheckoutReady, selectedPaymentMethod])

  const setPaymentMethod = useCallback((paymentMethod: PaymentMethod) => {
    setSelectedPaymentMethod(paymentMethod)
    eventLogger.logPaymentMethodSelected(paymentMethod)
  }, [])

  const handleCloseCheckout = useCallback(() => {
    dispatch(resetErrorAction())
    eventLogger.logPurchaseScreenClosed({
      productId,
      screenName,
      screenId,
    })

    if (isCancelOfferPage || !hasCancelOffer) {
      setIsCheckoutShown(false)
      googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
      return
    }

    dispatch(setSubscriptionListAction([]))
    goTo({
      pathname: PageId.CANCEL_OFFER_QUESTION,
      search,
    })
  }, [
    dispatch,
    productId,
    screenName,
    screenId,
    isCancelOfferPage,
    hasCancelOffer,
    search,
    setIsCheckoutShown,
    pathname,
    cohort,
  ])

  if (threeDSecureIframeUrl) {
    return <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
  }

  return (
    selectedSubscription && (
      <S.Root>
        {!isCheckoutReady && <Spinner />}
        <S.ContentContainer $isVisible={isCheckoutReady}>
          <S.PaymentMethodsTitle>
            {availablePaymentMethods.length > 1
              ? t('checkoutExtended.selectMethods')
              : t('checkoutExtended.checkoutTitle')}
          </S.PaymentMethodsTitle>
          <S.CloseButton onClick={handleCloseCheckout} />
          {availablePaymentMethods.length > 1 && (
            <S.PaymentMethodsWrapper>
              {paymentMethodsInOrder.map((paymentMethod) => {
                if (!availablePaymentMethods.includes(paymentMethod)) {
                  return null
                }

                return (
                  <S.PaymentMethod
                    key={paymentMethod}
                    $isSelected={paymentMethod === selectedPaymentMethod}
                    onClick={() => setPaymentMethod(paymentMethod)}
                  >
                    {paymentMethod === PaymentMethod.CREDIT_CARD && (
                      <S.PaymentMethodText>
                        {t('checkoutExtended.creditCard')}
                      </S.PaymentMethodText>
                    )}
                    <img
                      src={
                        PAYMENT_METHODS_IMAGES[paymentMethod][
                          paymentMethod === selectedPaymentMethod
                            ? 'active'
                            : 'inactive'
                        ]
                      }
                      width={PAYMENT_METHODS_IMAGES[paymentMethod].imageWidth}
                      alt={paymentMethod}
                    />
                  </S.PaymentMethod>
                )
              })}
            </S.PaymentMethodsWrapper>
          )}

          <S.PriceDescription>
            <S.PriceInfo>
              <S.PriceBlockText>
                <Trans i18nKey="purchaseCancelOffer.checkout.personalizedPlan" />
              </S.PriceBlockText>
              <S.PriceBlockText>
                {subscription.totalAmount} {currencyLabel}
              </S.PriceBlockText>
            </S.PriceInfo>

            {!isSevenDayPlan && (
              <S.PriceInfo>
                <S.PriceBlockText>
                  {isSaleFlow && dynamicDiscount?.checkout ? (
                    <>
                      {subscription.percentage}% {dynamicDiscount.checkout}
                    </>
                  ) : (
                    <Trans
                      i18nKey="checkoutExtended.introductoryDiscount"
                      values={{
                        discountPercentage: subscription.percentage,
                      }}
                    />
                  )}
                </S.PriceBlockText>
                <S.TotalDiscount>{totalDiscount}</S.TotalDiscount>
              </S.PriceInfo>
            )}

            {hasExcludedVat && (
              <S.PriceInfo>
                <S.PriceBlockText>
                  {t(`purchase1.valueAddedTax`, { context: countryCode })}
                </S.PriceBlockText>
                <S.PriceBlockText>
                  {isSevenDayPlan || !isTrialCohort
                    ? fullPriceTax
                    : selectedSubscription?.trialPrices.fullPriceTax}{' '}
                  {currencyLabel}
                </S.PriceBlockText>
              </S.PriceInfo>
            )}
          </S.PriceDescription>

          <S.DailyPayment>
            <S.DailyText>{t('checkoutExtended.totalPerDay')}</S.DailyText>
            <S.DailyAmount>
              {getCurrentPrice(
                isSevenDayPlan || !isTrialCohort
                  ? selectedSubscription.mainPrices.daily
                  : selectedSubscription.trialPrices.daily,
              )}{' '}
              {currencyLabel}
            </S.DailyAmount>
          </S.DailyPayment>

          <S.TotalPayment>
            <S.TotalDescription>
              <S.TotalText>{t('checkoutExtended.total')}</S.TotalText>
              <S.TotalAmount>
                <Trans
                  i18nKey="checkoutExtended.totalAmount"
                  values={{
                    totalAmount:
                      isSevenDayPlan || !isTrialCohort
                        ? fullPrice
                        : selectedSubscription?.trialPrices.fullPrice,
                    currency: currencyLabel,
                    periodQuantity,
                    context:
                      isSevenDayPlan || !isTrialCohort
                        ? periodQuantity
                        : selectedSubscription.trialPrices.durationDays,
                  }}
                />
              </S.TotalAmount>
            </S.TotalDescription>
            {!isSevenDayPlan && (
              <S.SaveText>
                <Trans
                  i18nKey="checkoutExtended.savedAmount"
                  values={{
                    savedAmount: subscription.discountAmount,
                    currency: currencyLabel,
                    discountPercentage: subscription.percentage,
                  }}
                />
              </S.SaveText>
            )}
          </S.TotalPayment>

          <S.PaymentFormWrapper ref={paymentFormRef}>
            <S.PaymentMethodContainer
              $isVisible={selectedPaymentMethod === PaymentMethod.CREDIT_CARD}
            >
              {isPrimerActive ? (
                <>
                  {isCheckoutShown && (
                    <PrimerPaymentForm key={selectedSubscription?.id} />
                  )}
                </>
              ) : (
                <CreditCardFormCancelOffer
                  isCheckoutVisible={
                    isCheckoutShown &&
                    isScrolled &&
                    selectedPaymentMethod === PaymentMethod.CREDIT_CARD
                  }
                />
              )}
            </S.PaymentMethodContainer>

            <S.StripePaymentContainer
              $isVisible={
                selectedPaymentMethod === PaymentMethod.GOOGLE_PAY ||
                selectedPaymentMethod === PaymentMethod.APPLE_PAY
              }
            >
              <StripeSeparatePaymentRequestButton
                key={selectedSubscription?.id}
                setAlternativePaymentMethodCallback={addAvailablePaymentMethod}
              />
            </S.StripePaymentContainer>

            <S.PaymentMethodContainer
              $isVisible={selectedPaymentMethod === PaymentMethod.PAYPAL}
            >
              <PayPalButton />
            </S.PaymentMethodContainer>
          </S.PaymentFormWrapper>

          <S.PaymentsSystemImage src={SecuritySystems} alt="security-systems" />
          <S.PaymentsSystemText>{t`purchase2.checkout.moneyBackGuarantee`}</S.PaymentsSystemText>
        </S.ContentContainer>

        {isPrimerActive ? (
          <PaymentPrimerWaitingModal
            isPaymentWaitingShown={isPaymentWaitingShown}
            setIsPaymentWaitingShown={setIsPaymentWaitingShown}
          />
        ) : (
          <PaymentWaitingModal
            isPaymentWaitingShown={isPaymentWaitingShown}
            setIsPaymentWaitingShown={setIsPaymentWaitingShown}
          />
        )}
      </S.Root>
    )
  )
}
