import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import { Container } from 'components/Container'
import { ProgressRounded } from 'components/ProgressRounded'
import { SvgImage } from 'components/SvgImage'

import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import checkIconSvg from 'assets/images/sprite/check-icon-rounded.svg'
import emoji from 'assets/images/sprite/present-emoji.svg'

import { PageId } from 'page-constants'

import { StyledPreparePlanNewYear as S } from './PreparePlanNewYear.styles'
import { CounterBreakpoint, QUESTION } from './constants'

export const PreparePlanNewYear: React.FC<TPageProps> = ({ pageId }) => {
  const { t } = useTranslation()
  const { search } = useLocation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath: `/${PageId.ENTER_EMAIL}${search}`,
  })

  const handleNextClick = useCallback(() => handleContinue(''), [
    handleContinue,
  ])

  const { counter, linearCounterValue } = useCounter(true, {
    duration: 6000,
    callback: handleNextClick,
  })

  return (
    <Container>
      <S.Title>{t`onboarding.preparePlan.title`}</S.Title>
      <S.ProgressContainer>
        <ProgressRounded value={counter} />
      </S.ProgressContainer>

      <S.Text $isReady={linearCounterValue > CounterBreakpoint.INITIAL}>
        <S.CheckIcon svg={checkIconSvg} width={24} />
        {t`onboarding.preparePlan.analyzingGoals`}
      </S.Text>
      <S.Text $isReady={linearCounterValue > CounterBreakpoint.QUARTER}>
        <S.CheckIcon svg={checkIconSvg} width={24} />
        {t`onboarding.preparePlan.analyzingProblemAreas`}
      </S.Text>
      <S.Text $isReady={linearCounterValue > CounterBreakpoint.HALF}>
        <S.CheckIcon svg={checkIconSvg} width={24} />
        {t`onboarding.preparePlan.choosingDanceWorkouts`}
      </S.Text>
      <S.Text $isReady={linearCounterValue > CounterBreakpoint.THREE_QUARTERS}>
        <S.CheckIcon svg={checkIconSvg} width={24} />
        <Trans
          i18nKey="onboarding.preparePlan.preparingPrizes"
          components={{ emoji: <SvgImage svg={emoji} width={16} /> }}
        />
      </S.Text>
    </Container>
  )
}
