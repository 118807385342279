import arms from 'assets/images/arms-issue.png'
import elbowsLegs from 'assets/images/elbows-issue-legs.png'
import elbows from 'assets/images/elbows-issue.png'
import feetLegs from 'assets/images/feet-issue-legs.png'
import feet from 'assets/images/feet-issue.png'
import kneesLegs from 'assets/images/knees-issue-legs.png'
import knees from 'assets/images/knees-issue.png'
import legsLegs from 'assets/images/legs-issue-legs.png'
import legs from 'assets/images/legs-issue.png'
import neck from 'assets/images/neck-issue.png'
import noneLegs from 'assets/images/no-issue-legs.png'
import none from 'assets/images/none-issue.png'
import shouldersLegs from 'assets/images/shoulders-issue-legs.png'
import shoulders from 'assets/images/shoulders-issue.png'

export const QUESTION = 'Have you suffered any injuries recently?'
export const SELECT_NONE = 'noIssues'

export const OPTION_VALUES = [
  {
    value: SELECT_NONE,
    icon: none,
  },
  {
    value: 'knees',
    icon: knees,
  },
  {
    value: 'shoulders',
    icon: shoulders,
  },
  {
    value: 'neck',
    icon: neck,
  },
  {
    value: 'arms',
    icon: arms,
  },
  {
    value: 'elbows',
    icon: elbows,
  },
  {
    value: 'legs',
    icon: legs,
  },
  {
    value: 'feet',
    icon: feet,
  },
]
export const OPTION_VALUES_LEGS = [
  {
    value: SELECT_NONE,
    icon: noneLegs,
  },
  {
    value: 'knees',
    icon: kneesLegs,
  },
  {
    value: 'shoulders',
    icon: shouldersLegs,
  },
  {
    value: 'neck',
    icon: neck,
  },
  {
    value: 'arms',
    icon: arms,
  },
  {
    value: 'elbows',
    icon: elbowsLegs,
  },
  {
    value: 'legs',
    icon: legsLegs,
  },
  {
    value: 'feet',
    icon: feetLegs,
  },
]
