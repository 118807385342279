import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { useGrowthBook } from '@growthbook/growthbook-react'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectCurrentVariantSteps,
} from 'root-redux/selects/common'

import { growthBook } from 'hooks/useGrowthBook'

import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import {
  FEATURE_ID_TO_DEFAULT_PAGES_MAP,
  FEATURE_ID_TO_PAGES_MAP,
  PageId,
} from 'page-constants'
import {
  COHORT_TO_PAGES_TEST_MAP,
  Cohort,
  POST_PAYMENT_PAGES,
} from 'root-constants'

export const useGetPageInfo = () => {
  const { pathname } = useLocation()
  const steps = useSelector(selectCurrentVariantSteps)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = (parentCohort || cohort) as Cohort
  const growthBookState = useGrowthBook()

  // TODO remove this and use steps from redux store everywhere when ab test is done
  const currentVariantPagesSet = useMemo(() => {
    if (growthBookState?.ready && COHORT_TO_PAGES_TEST_MAP[cohortToUse]) {
      const runningTests = COHORT_TO_PAGES_TEST_MAP[cohortToUse]

      for (const experimentKey of runningTests) {
        if (growthBook.isOn(experimentKey)) {
          return FEATURE_ID_TO_PAGES_MAP[experimentKey]
        }
      }

      return FEATURE_ID_TO_DEFAULT_PAGES_MAP[runningTests[0]]
    }

    return null
  }, [cohortToUse, growthBookState?.ready])

  // TODO remove this and use steps from redux store everywhere when ab test is done
  const filteredSteps = useMemo(() => {
    if (currentVariantPagesSet?.length) {
      return currentVariantPagesSet.map((id) => {
        const i = steps.findIndex((step) => step.id === id)
        return steps[i]
      })
    }

    return steps
  }, [currentVariantPagesSet, steps])

  const currentPageId = useMemo(
    () => getPageIdFromPathName(pathname) as PageId,
    [pathname],
  )

  const currentPageIndex = useMemo(
    () => filteredSteps.findIndex(({ id }) => id === currentPageId),
    [currentPageId, filteredSteps],
  )

  const isSecondPage = useMemo(
    () => filteredSteps.findIndex(({ id }) => id === currentPageId) === 1,
    [currentPageId, filteredSteps],
  )

  const isPostPaymentPage = useMemo(
    () => POST_PAYMENT_PAGES.includes(currentPageId),
    [currentPageId],
  )

  const { hasHeader, hasProgressbar, hasBurgerMenu } = useMemo(
    () => ({
      hasHeader:
        filteredSteps[currentPageIndex]?.hasHeader || isPostPaymentPage,
      hasProgressbar: filteredSteps[currentPageIndex]?.hasProgressBar,
      hasBurgerMenu:
        !currentPageIndex && !filteredSteps[currentPageIndex].isSubscriptions,
    }),
    [currentPageIndex, isPostPaymentPage, filteredSteps],
  )

  const pagesWithProgressBar = useMemo(
    () => filteredSteps.filter(({ hasProgressBar }) => hasProgressBar),
    [filteredSteps],
  )

  const amountOfAllPages = useMemo(
    () =>
      filteredSteps.filter(
        ({ isSkippable, hasProgressBar }) => !isSkippable && hasProgressBar,
      ),
    [filteredSteps],
  )

  const currentPageNumber = useMemo(() => {
    const pageIndex = pagesWithProgressBar.findIndex(
      ({ id }) => id === currentPageId,
    )

    return pageIndex + 1
  }, [currentPageId, pagesWithProgressBar])

  const currentSubscriptionPageId = useMemo(
    () => filteredSteps.find((page) => page.isSubscriptions)?.id as PageId,
    [filteredSteps],
  )

  return {
    steps: filteredSteps,
    currentPageId,
    currentPageIndex,
    isSecondPage,
    hasHeader,
    hasProgressbar,
    hasBurgerMenu,
    pagesWithProgressBar: amountOfAllPages,
    currentPageNumber,
    currentSubscriptionPageId,
  }
}
