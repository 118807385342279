import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import { Container } from 'components/Container'
import { OptionType } from 'components/Option'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { SvgImage } from 'components/SvgImage'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { sendFacebookParamsAction } from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'
import { useScrolledPercentage } from 'hooks/useScrolledPercentage'
import { useStartSession } from 'hooks/useStartSession'

import { BodyAgeV3 } from 'modules/purchase/components/BodyAgeV3'
import { PhotoResultV3 } from 'modules/purchase/components/PhotoResultV3'
import {
  SLIDES_FEMALE,
  SLIDES_MALE,
} from 'modules/purchase/components/PhotoResultV3/constant'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import doubleArrowAnimation from 'assets/animation/doubleArrow.json'
import logosImage from 'assets/images/logos.png'
import arrowSvg from 'assets/images/sprite/arrow.svg'
import weightLossImg1 from 'assets/images/welcome-weight-loss-1.png'
import weightLossImg2 from 'assets/images/welcome-weight-loss-2.png'
import weightLossImg3 from 'assets/images/welcome-weight-loss-3.png'
import weightLossImg4 from 'assets/images/welcome-weight-loss-4.png'

import { Cohort, PagesSource } from 'root-constants'

import { DEFAULT_OPTIONS, OPTION_VALUES } from '../Welcome/constants'
import { StyledWelcomeWeightLoss as S } from './WelcomeWeightLoss.styles'
import { BUTTON_TEXT, QUESTION } from './constants'

export const WelcomeWeightLoss: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const uuid = useSelector(selectUUID)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const animationRef = useRef<HTMLDivElement>(null)
  const ageRangeRef = useRef<HTMLDivElement>(null)
  const firstElRef = useRef(null)
  const secondElRef = useRef(null)
  const thirdElRef = useRef(null)

  const [answer, setAnswer] = useState<string>('')

  const cohortToUse = parentCohort || cohort

  const scrolledPercentage = useScrolledPercentage(
    firstElRef,
    secondElRef,
    thirdElRef,
  )

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  useStartSession()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const cards = useMemo(
    () => OPTION_VALUES[cohortToUse as Cohort] || DEFAULT_OPTIONS,
    [cohortToUse],
  )

  useEffect(() => {
    if (animationRef.current) {
      lottie.loadAnimation({
        container: animationRef.current,
        animationData: doubleArrowAnimation,
        loop: true,
        name: 'arrowAnimation',
      })
    }

    return () => lottie.destroy('arrowAnimation')
  }, [])

  useEffect(() => {
    scrolledPercentage &&
      eventLogger.logFirstPageScrolled(`${scrolledPercentage}%`)
  }, [scrolledPercentage])

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        dispatch(sendFacebookParamsAction())
        window.fbq('track', 'CustomizeProduct', {}, { eventID: uuid })
        googleAnalyticsLogger.logFirstPageCompleted()
        eventLogger.logFirstPageCompleted({
          question: QUESTION,
          answers: value,
        })
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
        setAnswer(value)
      },
    }),
    [
      pageId,
      dispatch,
      uuid,
      handleChange,
      handleContinue,
      setIsAnswersDisabled,
    ],
  )

  const handleNextClick = useCallback((number: number) => {
    eventLogger.logFirstPageButtonClicked(number, BUTTON_TEXT)

    if (ageRangeRef.current) {
      ageRangeRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  return (
    <Container>
      <S.Root ref={ageRangeRef}>
        <S.Title>
          <Trans i18nKey="onboarding.welcomeWeightLoss.title" />
        </S.Title>
        <S.Subtitle>
          <Trans
            i18nKey="onboarding.welcomeWeightLoss.subtitle"
            components={{
              styled: <p />,
            }}
            values={{ context: cohortToUse }}
          />
        </S.Subtitle>
        <S.Actions>
          {cards.map(({ value, img, from, to }) => (
            <S.StyledOption
              {...optionProps}
              key={value}
              value={value}
              $isChecked={answer === value}
              disabled={isAnswersDisabled}
            >
              <S.Image $isChecked={answer === value} src={img} alt={value} />
              <S.Action $isChecked={answer === value}>
                <Trans
                  i18nKey="onboarding.welcome.optionTitle"
                  values={{
                    context: from,
                  }}
                  components={{
                    age: to
                      ? t('onboarding.welcome.age', {
                          from,
                          to,
                        })
                      : from,
                    icon: <SvgImage svg={arrowSvg} height={10} width={16} />,
                  }}
                />
              </S.Action>
            </S.StyledOption>
          ))}
        </S.Actions>
        <S.Terms>
          <p>{t('footer.disclaimer')}</p>
          <TermsOfUseLink source={PagesSource.LANDING} />
          <S.Separator />
          <PrivacyPolicyLink source={PagesSource.LANDING} />
          <p>
            {t('footer.allRightsReserved', {
              year: new Date().getFullYear(),
            })}
          </p>
        </S.Terms>
      </S.Root>

      <S.Arrow ref={animationRef} />

      <S.WeightLossCardContainer ref={firstElRef}>
        <S.Card>
          <S.CardTitle>
            {t(`onboarding.welcomeWeightLoss.cards.first.title`)}
          </S.CardTitle>

          <S.CardSubtitle>
            {t(`onboarding.welcomeWeightLoss.cards.first.subtitle`)}
          </S.CardSubtitle>

          <S.CardImage
            src={weightLossImg1}
            alt="Your personalized dance weight loss plan"
          />
        </S.Card>

        <S.Card>
          <S.CardTitle>
            {t(`onboarding.welcomeWeightLoss.cards.second.title`)}
          </S.CardTitle>

          <S.CardSubtitle>
            {t(`onboarding.welcomeWeightLoss.cards.second.subtitle`)}
          </S.CardSubtitle>

          <S.CardImage src={weightLossImg2} alt="Live classes with experts" />
        </S.Card>

        <S.Card>
          <S.CardTitle>
            {t(`onboarding.welcomeWeightLoss.cards.third.title`)}
          </S.CardTitle>

          <S.CardSubtitle>
            {t(`onboarding.welcomeWeightLoss.cards.third.subtitle`)}
          </S.CardSubtitle>

          <S.CardImage src={weightLossImg3} alt="Big screen experience" />
        </S.Card>

        <S.Card>
          <S.CardTitle>
            <Trans i18nKey="onboarding.welcomeWeightLoss.cards.fourth.title" />
          </S.CardTitle>

          <S.CardSubtitle>
            {t(`onboarding.welcomeWeightLoss.cards.fourth.subtitle`)}
          </S.CardSubtitle>

          <S.CardImage
            src={weightLossImg4}
            alt="Collection of workouts to<br/>suit any preference"
          />
        </S.Card>
      </S.WeightLossCardContainer>

      <S.Wreath ref={secondElRef}>
        <S.NumberOne>
          #<strong>1</strong>
        </S.NumberOne>
        <Trans i18nKey="purchaseV2.numberOne" />
      </S.Wreath>

      <S.Button type="button" onClick={() => handleNextClick(1)}>
        {t`actions.getStarted`}
      </S.Button>

      <S.Sponsors src={logosImage} alt="Sponsors" />

      <BodyAgeV3 />

      <PhotoResultV3 femaleSlides={SLIDES_FEMALE} maleSlides={SLIDES_MALE} />

      <S.Button type="button" onClick={() => handleNextClick(2)}>
        {t`actions.getStarted`}
      </S.Button>

      <S.Terms ref={thirdElRef}>
        <p>{t('footer.disclaimer')}</p>
        <TermsOfUseLink source={PagesSource.LANDING} />
        <S.Separator />
        <PrivacyPolicyLink source={PagesSource.LANDING} />
        <p>
          {t('footer.allRightsReserved', {
            year: new Date().getFullYear(),
          })}
        </p>
      </S.Terms>
    </Container>
  )
}
