import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { CURRENCY_SYMBOLS, GeneralProduct } from 'modules/purchase/constants'
import {
  selectCurrency,
  selectProductId,
  selectSubscriptionFullPrice,
} from 'modules/purchase/redux/selects/common'

import bellyPlanImg from 'assets/images/upsell-belly-plan.png'
import bodyProgramImg from 'assets/images/upsell-body-program.png'

import { GROWTHBOOK_EXPERIMENT } from 'root-constants'

import { StyledUpsellDescription as S } from './UpsellDescription.styles'

export const UpsellDescription: React.FC = () => {
  const { t } = useTranslation()

  const isPaidUpsell = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_474)

  const currentPrice = useSelector(selectSubscriptionFullPrice)
  const currency = useSelector(selectCurrency)
  const productId = useSelector(selectProductId)

  const isFree = useMemo(
    () => !isPaidUpsell && productId === GeneralProduct.SEVEN_DAY,
    [isPaidUpsell, productId],
  )

  return (
    <S.Wrapper>
      <S.Title>{t`upsell.upsellDescription.title`}</S.Title>
      <S.TextContainer>
        <S.Image src={bellyPlanImg} alt="belly-plan" />
        <S.Text>{t`upsell.upsellDescription.bellyPlan`}</S.Text>
      </S.TextContainer>

      <S.TextContainer>
        <S.Image src={bodyProgramImg} alt="body-program" />
        <S.Text>{t`upsell.upsellDescription.bodyProgram`}</S.Text>
      </S.TextContainer>
      <S.PriceBadge $isCrossedOut={isFree}>
        <span>
          <Trans
            i18nKey="upsell.upsellDescription.priceBadge"
            components={[<strong />]}
            values={{
              price: currentPrice,
              currency: CURRENCY_SYMBOLS[currency],
            }}
          />
        </span>

        {isFree && (
          <S.TrialPrice>
            {t('upsell.upsellDescription.trialPrice', {
              currency: CURRENCY_SYMBOLS[currency],
            })}
          </S.TrialPrice>
        )}
      </S.PriceBadge>
      <S.Disclaimer>
        <Trans
          i18nKey="upsell.upsellDescription.disclaimer"
          components={[<strong />]}
        />
      </S.Disclaimer>
    </S.Wrapper>
  )
}
