import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import { Container } from 'components/Container'
import { NavigationButton } from 'components/NavigationButton'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectIsStayFitFlow,
  selectLanguage,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import branchSvg from 'assets/images/branch-blue.svg'
import logoImg from 'assets/images/dancebit-logo-white.png'
import mapImg from 'assets/images/world-map.png'

import { CDN_FOLDER_LINK, Cohort, MAIN_GOALS } from 'root-constants'

import { StyledWorldwideAchieving as S } from './WorldwideAchieving.styles'
import { QUESTION } from './constants'

export const WorldwideAchieving: FC<TPageProps> = ({
  nextPagePath,
  pageId,
}) => {
  const { t } = useTranslation()

  const animationRef = useRef<HTMLDivElement>(null)

  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const isStayFitGoal = useSelector(selectIsStayFitFlow)
  const language = useSelector(selectLanguage)

  const cohortToUse = parentCohort || cohort

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const isStayFitFlow = useMemo(
    () => cohortToUse !== Cohort.DB_FLOW_2 && isStayFitGoal,
    [cohortToUse, isStayFitGoal],
  )

  useEffect(() => {
    if (animationRef.current) {
      lottie.loadAnimation({
        container: animationRef.current,
        path: `${CDN_FOLDER_LINK}/animations/numbersAnimation_${language}.json`,
        loop: false,
        name: 'numbersAnimation',
      })
    }

    return () => lottie.destroy('numbersAnimation')
  }, [language])

  const handleClick = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])

  return (
    <S.Root>
      <Container>
        <S.LogoContainer>
          <S.BoxLogo src={logoImg} alt="DanceBit" />
        </S.LogoContainer>
        <S.MapImg src={mapImg} />

        <S.UsersBlock ref={animationRef} />

        <S.Description>
          <Trans i18nKey="onboarding.worldwideAchieving.description" />
        </S.Description>

        <S.ReachedGoals>
          <S.Branch src={branchSvg} />
          <Trans
            i18nKey="onboarding.worldwideAchieving.reachedGoals"
            values={{
              context: isStayFitFlow
                ? MAIN_GOALS.STAY_FIT
                : MAIN_GOALS.LOSE_WEIGHT,
            }}
          />
          <S.Branch src={branchSvg} />
        </S.ReachedGoals>
      </Container>

      <NavigationButton
        type="button"
        onClick={handleClick}
      >{t`actions.soundsGood`}</NavigationButton>
    </S.Root>
  )
}
