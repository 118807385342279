import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import { App } from 'components/App'

import { store } from 'root-redux/store'

import { initSentry } from 'helpers/initSentry'

import './i18n'
import './styles.css'

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    // Facebook Pixel
    fbq: any
    // Reddit Pixel
    rdt: any
    // TikTok Pixel
    ttq: any
    // Snap Pixel
    snaptr: any
    // Outbrain Pixel
    obApi: any
    // Taboola Pixel
    _tfa: any
    // AppsFlyer WebSDK
    AF: any
    // ZenDesk plugin
    zE: any
    zESettings: any
    // Google analytics
    gtag: any
    // Google optimize API
    // eslint-disable-next-line camelcase
    google_optimize: any
    MSStream: any
    // Pinterest tag
    pintrk: any
    LiveChatWidget: any
  }
}

initSentry()
const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)
