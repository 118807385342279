export const PLAN_HIGHLIGHTS_FULL = [
  { id: 1, textPath: 'purchaseNewYear.planHighlights.supportYourSuccess' },
  { id: 2, textPath: 'purchaseNewYear.planHighlights.moreEnergy' },
  { id: 3, textPath: 'purchaseNewYear.planHighlights.betterHealth' },
  { id: 4, textPath: 'purchaseNewYear.planHighlights.motivatingInsights' },
  { id: 5, textPath: 'purchaseNewYear.planHighlights.happierYou' },
]

export const PLAN_HIGHLIGHTS_SHORT = [
  { id: 1, textPath: 'purchaseNewYear.planHighlights.moreEnergy' },
  { id: 2, textPath: 'purchaseNewYear.planHighlights.betterHealth' },
  { id: 3, textPath: 'purchaseNewYear.planHighlights.happierYou' },
]
