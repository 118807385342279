import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import forgetPlanksImage from 'assets/images/flow-belly/forget-planks.png'

import { CDN_FOLDER_LINK, Cohort } from 'root-constants'

import { StyledForgetPlanks as S } from './ForgetPlanks.styles'
import { QUESTION, QUESTION_LEGS } from './constants'

export const ForgetPlanks: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort

  const isLegsFlow = useMemo(() => cohortToUse === Cohort.DB_LEGS, [
    cohortToUse,
  ])

  const handleContinue = useNextStep({
    pageId,
    question: isLegsFlow ? QUESTION_LEGS : QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(
    () => handleContinue(isLegsFlow ? QUESTION_LEGS : QUESTION),
    [handleContinue, isLegsFlow],
  )

  return (
    <>
      <ContainerBeforePinnedButton>
        <S.Container isLarge>
          <PageTitle marginBottom={8}>
            <Trans
              i18nKey={
                isLegsFlow
                  ? 'onboarding.forgetPlanks.titleLegs'
                  : 'onboarding.forgetPlanks.title'
              }
            />
          </PageTitle>
          <S.Subtitle>
            {t(
              isLegsFlow
                ? 'onboarding.forgetPlanks.subtitleLegs'
                : 'onboarding.forgetPlanks.subtitle',
            )}
          </S.Subtitle>
          {isLegsFlow ? (
            <S.ContentWrapper>
              <video
                muted
                loop
                autoPlay
                controls={false}
                playsInline
                width="100%"
                poster={`${CDN_FOLDER_LINK}/videos/db_legs.png`}
              >
                <source
                  src={`${CDN_FOLDER_LINK}/videos/db_legs.mov`}
                  type="video/mp4"
                />
              </video>
            </S.ContentWrapper>
          ) : (
            <S.ContentWrapper>
              <img
                width="100%"
                height="100%"
                src={forgetPlanksImage}
                alt="Forget planks, push-ups, and crunches!"
              />
            </S.ContentWrapper>
          )}
        </S.Container>
      </ContainerBeforePinnedButton>

      <NavigationButtonBelly type="button" onClick={handleClick}>
        {t('onboarding.forgetPlanks.awesome')}
      </NavigationButtonBelly>
    </>
  )
}
