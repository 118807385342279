import styled from 'styled-components'

import { Input } from 'components/Input'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { PageTitleDescription } from 'components/PageTitleDescription'

import { Color } from 'root-constants'

type TCurrentHeightProps = {
  $isMetricSystemSelected?: boolean
  $isFirstLabel?: boolean
}

export const StyledCurrentHeightBelly = {
  Form: styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  Actions: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  `,
  Subtitle: styled(PageTitleDescription)`
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.DARK};
    text-align: center;
  `,
  InputContainer: styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 464px;
  `,
  InputWrapper: styled.div`
    position: relative;
    width: 100%;
    display: flex;

    &:first-child {
      justify-content: flex-start;
    }

    &:last-child {
      justify-content: flex-end;
    }
  `,
  InputIn: styled(Input)<TCurrentHeightProps>`
    max-width: ${({ $isMetricSystemSelected }) =>
      !$isMetricSystemSelected && '160px'};
  `,
  InputFt: styled(Input)<TCurrentHeightProps>`
    max-width: ${({ $isMetricSystemSelected }) =>
      !$isMetricSystemSelected && '160px'};
    margin-left: 4px;
  `,
  Suffix: styled.span<TCurrentHeightProps>`
    position: absolute;
    top: 13px;
    right: ${({ $isFirstLabel }) => ($isFirstLabel ? '30px' : '16px')};
    font-size: 30px;
    color: ${Color.GRAY_60};
    font-weight: 700;
    line-height: 38px;
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)`
    border-top: none;
    padding-top: 24px;
  `,
}
