import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { CANCEL_OFFER_ADDITIONAL_DISCOUNT } from 'modules/purchase/constants'
import { selectDynamicDiscount } from 'modules/purchase/redux/selects/common'

import { StyledLimitedDeal as S } from './LimitedDeal.styles'

export const LimitedDeal: React.FC<{ isCancelOffer?: boolean }> = ({
  isCancelOffer = false,
}) => {
  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const { t } = useTranslation()

  return (
    <S.Container $theme={dynamicDiscount?.theme}>
      <S.DiscountLabel $theme={dynamicDiscount?.theme}>
        {t('purchaseCancelOffer.personalPlan.discountText', {
          discountAmount:
            isCancelOffer && dynamicDiscount?.amount
              ? dynamicDiscount.amount + CANCEL_OFFER_ADDITIONAL_DISCOUNT
              : dynamicDiscount?.amount,
        })}
      </S.DiscountLabel>
      <S.Text $theme={dynamicDiscount?.theme}>
        {t('purchaseWithoutIntro.limitedDeal')}
      </S.Text>
    </S.Container>
  )
}
