import styled from 'styled-components'

import { Container } from 'components/Container'
import { PageDescription } from 'components/PageDescription'

import imageBg from 'assets/images/flow-belly/how-long-train-bg.png'
import imageBgLegs from 'assets/images/flow-belly/how-long-train-legs-bg.png'

export const StyledHowLongTrain = {
  Container: styled(Container)<{ $isLegsFlow: boolean }>`
    min-height: 560px;
    background-image: url(${({ $isLegsFlow }) =>
      $isLegsFlow ? imageBgLegs : imageBg});
    background-repeat: no-repeat;
    background-size: 375px 500px;
    background-position: bottom;
  `,
  PageDescription: styled(PageDescription)`
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  `,
}
