import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import { setPlanAdditionsAction } from 'modules/purchase/redux/actions/common'

import {
  GROWTHBOOK_EXPERIMENT,
  PLAN_ADDITIONS_MAP,
  PlanAddition,
} from 'root-constants'

export const useSetPlanAdditions = (): void => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const hasPaywallTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_734)
  const hasPaywallTestBelly = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_802)

  useEffect(() => {
    const currentPageId = getPageIdFromPathName(pathname)
    const planAdditions = PLAN_ADDITIONS_MAP[currentPageId] || []

    if (hasPaywallTest || hasPaywallTestBelly) {
      planAdditions.push(PlanAddition.FREE_OFFER)
    }

    if (planAdditions.length) {
      dispatch(setPlanAdditionsAction(planAdditions))
    }
  }, [dispatch, pathname, hasPaywallTest, hasPaywallTestBelly])
}
