import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useTargetHiddenOnScroll } from 'hooks/useTargetHiddenOnScroll'

import { Cohort } from 'root-constants'

import { StyledSubheaderWithTimer as S } from './SubheaderWithTimer.styles'

type TProps = {
  onButtonClick: (event) => void
  elemForComparisonRef: React.RefObject<HTMLDivElement>
  isInfluencerFlow?: boolean
  className?: string
}

export const SubheaderWithTimer: React.FC<TProps> = ({
  elemForComparisonRef,
  onButtonClick,
  isInfluencerFlow = false,
  className,
}) => {
  const { t } = useTranslation()
  const isButtonVisible = useTargetHiddenOnScroll(elemForComparisonRef)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const isBellyFlow = useMemo(() => {
    const cohortToUse = parentCohort || cohort

    return cohortToUse === Cohort.DB_BELLY || cohortToUse === Cohort.DB_LEGS
  }, [cohort, parentCohort])

  return (
    <S.TimerContainer
      className={className}
      $isButtonVisible={isButtonVisible}
      $isInfluencerFlow={isInfluencerFlow}
    >
      <S.TimerText $isButtonVisible={isButtonVisible}>
        {t`purchase1.subheaderText`}
      </S.TimerText>
      <S.SubheaderTimer isButtonVisible />
      <S.TimerText $isButtonVisible={isButtonVisible}>
        {t`purchase1.subheaderTimeUnit`}
      </S.TimerText>
      <S.ButtonContainer $isButtonVisible={isButtonVisible}>
        <S.TimerButton
          data-order="1"
          data-text="get_now"
          $isBellyFlow={isBellyFlow}
          onClick={onButtonClick}
        >{t`purchaseWithoutIntro.subheaderTimer.getNowButton`}</S.TimerButton>
      </S.ButtonContainer>
    </S.TimerContainer>
  )
}
