import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { Cohort, NEW_YEAR_COHORTS } from 'root-constants'

export const useIsNewYearCohort = (): boolean => {
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort

  return useMemo(() => NEW_YEAR_COHORTS.includes(cohortToUse as Cohort), [
    cohortToUse,
  ])
}
