import { PaymentMethod } from 'modules/purchase/constants'

import applePayActiveImage from 'assets/images/apple-pay-active.png'
import applePayInactiveImage from 'assets/images/apple-pay-inactive.png'
import cardActiveImage from 'assets/images/card-icons-active.png'
import cardInactiveImage from 'assets/images/card-icons-inactive.png'
import googlePayActiveImage from 'assets/images/google-pay-active.png'
import googlePayInactiveImage from 'assets/images/google-pay-inactive.png'
import paypalActiveImage from 'assets/images/pay-pal-active.png'
import paypalInactiveImage from 'assets/images/pay-pal-inactive.png'

export const PAYMENT_METHODS_IMAGES = {
  [PaymentMethod.APPLE_PAY]: {
    active: applePayActiveImage,
    inactive: applePayInactiveImage,
    imageWidth: 72,
  },
  [PaymentMethod.GOOGLE_PAY]: {
    active: googlePayActiveImage,
    inactive: googlePayInactiveImage,
    imageWidth: 72,
  },
  [PaymentMethod.CREDIT_CARD]: {
    active: cardActiveImage,
    inactive: cardInactiveImage,
    imageWidth: 94,
  },
  [PaymentMethod.PAYPAL]: {
    active: paypalActiveImage,
    inactive: paypalInactiveImage,
    imageWidth: 69,
  },
}
