import styled, { keyframes } from 'styled-components'

import wheelPointer from 'assets/images/wheel-pointer.svg'

import { Color } from 'root-constants'

export const wheelAnimation = keyframes`
  0% { transform: rotate(0deg); }
  25% { transform: rotate(-4deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(4deg); }
  100% { transform: rotate(0deg); }
`

export const StyledFortuneWheel = {
  Wrapper: styled.div`
    margin-bottom: 14px;
  `,
  Title: styled.h2`
    padding-bottom: 24px;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  `,

  AnimationContainer: styled.div`
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      min-width: 35px;
      min-height: 67px;
      background: url(${wheelPointer}) center no-repeat;
      background-size: contain;
      z-index: 2;
    }
  `,
  WheelAnimation: styled.div`
    animation: ${wheelAnimation} 3s infinite ease-in-out;
    z-index: 1;
  `,
  ConfettiAnimation: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  `,
  SpinButton: styled.button`
    position: absolute;
    bottom: 42px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 230px;
    width: 100%;
    height: 64px;
    padding: 12px 46px;
    background: linear-gradient(180deg, #fcea85 0%, #f7c767 100%);
    border: 1px solid #fcc85d;
    box-shadow: 0 4px 10px rgba(193, 147, 18, 0.6);
    border-radius: 40px;
    cursor: pointer;
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-align: center;
    line-height: 0;
    z-index: 3;

    &:disabled {
      background: linear-gradient(
        180deg,
        ${Color.LAVENDER_LIGHT} 0%,
        #dadee4 100%
      );
      box-shadow: none;
      border: none;

      span {
        color: #b9bec5;
      }
    }
  `,
  SpinButtonText: styled.span`
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    color: #e50000;
  `,
  SpinButtonAttempts: styled.span`
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: ${Color.DARK};
  `,
}
