import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import { Button } from 'components/Button'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectLanguage,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { ADULT_COHORT, CDN_FOLDER_LINK, Cohort } from 'root-constants'

import { StyledDancingVsExercises as S } from './DancingVsExercises.styles'
import { AGE_FLOW_ANIMATION_PATH, ANIMATION_PATH, QUESTION } from './constants'

export const DancingVsExercises: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const animationRef = useRef<HTMLDivElement>(null)

  const language = useSelector(selectLanguage)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  useEffect(() => {
    if (animationRef.current) {
      lottie.loadAnimation({
        container: animationRef.current,
        path: `${CDN_FOLDER_LINK}${
          isAgeFlow ? AGE_FLOW_ANIMATION_PATH : ANIMATION_PATH
        }_${language}.json`,
        loop: false,
      })
    }

    return () => lottie.destroy()
  }, [language, isAgeFlow])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => handleContinue(''), [
    handleContinue,
  ])

  return (
    <div>
      <S.Container $isAgeFlow={isAgeFlow}>
        <PageTitle marginBottom={isAgeFlow ? 16 : 10} isAgeFlow={isAgeFlow}>
          {t`onboarding.dancingVsExercises.question`}
        </PageTitle>
        <S.AnimationContainer>
          <S.AnimationTitle>
            {t`onboarding.dancingVsExercises.animationTitle`}
          </S.AnimationTitle>
          <S.Animation $isAgeFlow={isAgeFlow}>
            <div ref={animationRef} />
          </S.Animation>
        </S.AnimationContainer>

        <S.ButtonContainer>
          <Button
            type="button"
            onClick={handleNextClick}
          >{t`actions.gotIt`}</Button>
        </S.ButtonContainer>
      </S.Container>
    </div>
  )
}
