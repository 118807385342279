import { useLayoutEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { sendUserSCCID } from 'root-redux/actions/user'
import { selectSnapPixelId } from 'root-redux/selects/common'
import {
  selectUUID,
  selectUserCountryCode,
  selectUserOnboardingEmail,
} from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'

import { getCookie } from 'helpers/getCookie'

export const useSnapPixelInitialization = (): void => {
  const dispatch = useDispatch()
  const snapPixelId = useSelector(selectSnapPixelId)
  const email = useSelector(selectUserOnboardingEmail)
  const uuid = useSelector(selectUUID)
  const userCountryCode = useSelector(selectUserCountryCode)

  const {
    isEUUser,
    hasAdvertizingAndMarketingCookie,
    hasFunctionalCookie,
    userCookieConsentAnswer,
  } = useCookieConsentAnswer()

  const shouldSNAPTRBePaused = useMemo(
    () =>
      (isEUUser && !userCookieConsentAnswer?.length) ||
      (isEUUser && !hasAdvertizingAndMarketingCookie),
    [isEUUser, userCookieConsentAnswer, hasAdvertizingAndMarketingCookie],
  )

  const isPersonalDataAllowed = useMemo(
    () => (isEUUser && hasFunctionalCookie) || !isEUUser,
    [isEUUser, hasFunctionalCookie],
  )

  useLayoutEffect(() => {
    if (!snapPixelId || !uuid || !userCountryCode || shouldSNAPTRBePaused) {
      return
    }

    window.snaptr('init', snapPixelId, {
      user_email: isPersonalDataAllowed ? email : '',
    })
    window.snaptr('track', 'PAGE_VIEW', {
      user_email: isPersonalDataAllowed ? email : '',
    })

    const searchParams = new URLSearchParams(document.location.search)
    const sccid = searchParams.get('sccid')
    const scid = getCookie('_scid')

    if (sccid) dispatch(sendUserSCCID(sccid, scid))
  }, [
    snapPixelId,
    email,
    dispatch,
    uuid,
    userCountryCode,
    shouldSNAPTRBePaused,
    isPersonalDataAllowed,
  ])
}
