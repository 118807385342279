import curvyImgLegs from 'assets/images/flow-belly/dream-body-curvy-legs.png'
import curvyImg from 'assets/images/flow-belly/dream-body-curvy.png'
import healthyImgLegs from 'assets/images/flow-belly/dream-body-healthy-legs.png'
import healthyImg from 'assets/images/flow-belly/dream-body-healthy.png'
import thinImgLegs from 'assets/images/flow-belly/dream-body-thin-legs.png'
import thinImg from 'assets/images/flow-belly/dream-body-thin.png'
import tonedImgLegs from 'assets/images/flow-belly/dream-body-toned-legs.png'
import tonedImg from 'assets/images/flow-belly/dream-body-toned.png'

export const QUESTION = 'What is your dream body?'

export const OPTION_VALUES = [
  { value: 'thin', title: 'onboarding.dreamBodyBelly.thin', icon: thinImg },
  { value: 'toned', title: 'onboarding.dreamBodyBelly.toned', icon: tonedImg },
  { value: 'curvy', title: 'onboarding.dreamBodyBelly.curvy', icon: curvyImg },
  {
    value: 'healthy',
    title: 'onboarding.dreamBodyBelly.healthy',
    icon: healthyImg,
  },
]

export const OPTION_VALUES_LEGS = [
  { value: 'thin', title: 'onboarding.dreamBodyBelly.thin', icon: thinImgLegs },
  {
    value: 'toned',
    title: 'onboarding.dreamBodyBelly.toned',
    icon: tonedImgLegs,
  },
  {
    value: 'curvy',
    title: 'onboarding.dreamBodyBelly.curvy',
    icon: curvyImgLegs,
  },
  {
    value: 'healthy',
    title: 'onboarding.dreamBodyBelly.healthy',
    icon: healthyImgLegs,
  },
]
