import styled from 'styled-components'

import reviewBackground from 'assets/images/newYearOnboarding/customer-review-bg-blue.png'

import { Color } from 'root-constants'

export const StyledCustomerReviewsNewYear = {
  Wrapper: styled.div`
    margin-bottom: 24px;
  `,
  Header: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 24px;
  `,
  Title: styled.div`
    margin-bottom: 15px;
    font-size: 30px;
    font-weight: bold;
    line-height: 34px;
    text-align: left;

    strong {
      color: ${Color.PRIMARY};
      text-transform: uppercase;
    }
  `,
  Picture: styled.picture`
    max-width: 117px;
  `,
  Comments: styled.ul``,
  Comment: styled.li`
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
    padding: 30px 20px;
    border-radius: 24px;
    background-image: url(${reviewBackground});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: left;
    overflow: hidden;

    &:last-child {
      margin-bottom: 0;
    }
  `,
  Author: styled.p`
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  `,
  Rating: styled.div`
    display: flex;
    justify-content: space-between;
    width: 85px;
    padding: 8px 0;

    svg {
      width: 14px;
      height: 14px;
    }
  `,
  Text: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${Color.DISABLED};
  `,
}
