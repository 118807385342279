import React, { FC, useCallback } from 'react'
import { Trans } from 'react-i18next'

import { CharacteristicCard } from 'components/CharacteristicCard'
import { Container } from 'components/Container'
import { NavigationButton } from 'components/NavigationButton'
import { PageTitle } from 'components/PageTitle'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledSocialProof as S } from './SocialProof.styles'
import { CHARACTERISTIC_COMMETS, QUESTION } from './constants'

export const SocialProof: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])

  return (
    <Container>
      <PageTitle>
        <Trans i18nKey="onboarding.socialProof.title" components={[<br />]} />
      </PageTitle>

      <S.CardsContainer>
        {CHARACTERISTIC_COMMETS.map((comment) => (
          <CharacteristicCard
            key={comment.name}
            danceStyles={comment.danceStyles}
            img={comment.img}
            name={comment.name}
            text={comment.text}
          />
        ))}
      </S.CardsContainer>

      <NavigationButton type="button" onClick={handleClick}>
        <Trans i18nKey="onboarding.socialProof.continue" />
      </NavigationButton>
    </Container>
  )
}
