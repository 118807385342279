import React from 'react'
import { useTranslation } from 'react-i18next'

import featuredImagePng from 'assets/images/featured.png'
import featuredImageWebp from 'assets/images/featured.webp'

import { StyledFeaturedBlockNewYear as S } from './FeaturedBlockNewYear.styles'

export const FeaturedBlockNewYear: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <S.Title>{t`purchase1.featuredBlockTitle`}</S.Title>
      <picture>
        <source srcSet={featuredImageWebp} type="image/webp" />
        <img src={featuredImagePng} alt="body" />
      </picture>
    </S.Wrapper>
  )
}
