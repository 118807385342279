import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { NavigationButton } from 'components/NavigationButton'

import {
  selectCurrentUserGender,
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import dancingStyleIntroAgeFlow from 'assets/images/dancing-style-intro-age-flow.png'
import dancingStyleIntroAgeFlowWebp from 'assets/images/dancing-style-intro-age-flow.webp'
import dancingStyleIntroImage from 'assets/images/dancing-style-intro.png'
import dancingStyleIntroImageWebp from 'assets/images/dancing-style-intro.webp'

import { ADULT_COHORT, Cohort, GROWTHBOOK_EXPERIMENT } from 'root-constants'

import { StyledDancingStylesIntro as S } from './DancingStylesIntro.styles'
import { DANCING_STYLE_INTRO_IMAGE_MX_AGE, QUESTION } from './constants'

export const DancingStylesIntro: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const gender = useSelector(selectCurrentUserGender)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const hasNewIllustrations = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_599)

  const cohortToUse = parentCohort || cohort

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => handleContinue(''), [
    handleContinue,
  ])

  return (
    <div>
      <S.Container $isAgeFlow={isAgeFlow}>
        {!isAgeFlow && (
          <S.ImageContainer>
            <picture>
              <source srcSet={dancingStyleIntroImageWebp} type="image/webp" />
              <img src={dancingStyleIntroImage} alt="dancing styles intro" />
            </picture>
          </S.ImageContainer>
        )}
        <S.Title $isAgeFlow={isAgeFlow}>
          {t(
            isAgeFlow
              ? `onboarding.dancingStylesIntro.questionAgeFlow`
              : `onboarding.dancingStylesIntro.question`,
          )}
        </S.Title>
        {isAgeFlow && (
          <S.AgeFlowPictureContainer>
            <picture>
              <source
                srcSet={
                  hasNewIllustrations
                    ? DANCING_STYLE_INTRO_IMAGE_MX_AGE[gender].WEBP
                    : dancingStyleIntroAgeFlowWebp
                }
                type="image/webp"
              />
              <img
                src={
                  hasNewIllustrations
                    ? DANCING_STYLE_INTRO_IMAGE_MX_AGE[gender].PNG
                    : dancingStyleIntroAgeFlow
                }
                alt="dancing styles intro"
              />
            </picture>
          </S.AgeFlowPictureContainer>
        )}
      </S.Container>

      <NavigationButton
        type="button"
        isAgeFlow={isAgeFlow}
        onClick={handleNextClick}
      >{t`actions.continue`}</NavigationButton>
    </div>
  )
}
