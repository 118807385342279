import styled from 'styled-components'

import { Button } from 'components/Button'

import blueCheck from 'assets/images/check-icon-with-stroke.svg'

import { Color } from 'root-constants'

export const StyledPurchaseNewYear = {
  Wrapper: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 16px 24px;
  `,
  ProgramPlanContainer: styled.div`
    padding: 0 14px;
    margin-bottom: 24px;
  `,
  ProgramPlanTitle: styled.h2`
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  `,
  List: styled.ul`
    padding: 0 30px;
  `,
  ListItem: styled.li`
    position: relative;
    margin-bottom: 18px;
    padding-left: 28px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -8px;
      transform: translateY(-50%);
      display: block;
      width: 24px;
      height: 24px;
      background: url(${blueCheck}) center center no-repeat;
      background-size: contain;
    }
  `,
  Button: styled(Button)`
    max-width: 328px;
    margin-bottom: 24px;
  `,
  Disclaimer: styled.p`
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: ${Color.DISABLED};

    a {
      color: ${Color.DISABLED};
      text-decoration: underline;
    }
  `,
  CheckoutContainer: styled.div<{ $isVisible: boolean }>`
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  `,
}
