import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { Spinner } from 'components/Spinner'

import { resetErrorAction } from 'root-redux/actions/common'

import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useHasPayPalButton } from 'hooks/useHasPayPalButton'

import { createProductId } from 'helpers/createProductId'

import { CreditCardFormCancelOffer } from 'modules/purchase/components/CreditCardFormCancelOffer'
import { PayPalButton } from 'modules/purchase/components/PayPalButton'
import { PaymentRequestButton } from 'modules/purchase/components/PaymentRequestButton'
import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { Separator } from 'modules/purchase/components/Separator'
import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { CHECK_PAYMENT_REQUEST_BUTTON } from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import securitySystems from 'assets/images/security-systems.png'

import { Cohort, ScreenName } from 'root-constants'

import { StyledCheckoutTrial as S } from './CheckoutTrial.styles'

type TProps = {
  setIsCheckoutShown: (value: boolean) => void
  isCheckoutShown: boolean
}

export const CheckoutTrial: React.FC<TProps> = ({
  setIsCheckoutShown,
  isCheckoutShown,
}) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  const {
    uuid,
    cohort,
    currency,
    fetchingActionsList,
    periodQuantity,
    periodName,
    screenName,
    trialPrice,
    trialPeriodDays,
    threeDSecureIframeUrl,
    stripeAccountId,
    stripeAccountName,
    screenId,
    selectedSubscription,
    subscriptions,
  } = usePurchaseStore()

  const hasPayPalButton = useHasPayPalButton()

  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState(false)

  const productId = useMemo(
    () =>
      createProductId({
        periodName,
        periodQuantity,
        price: trialPrice,
      }),
    [periodName, periodQuantity, trialPrice],
  )
  const isCheckoutReady = useMemo(
    () => !fetchingActionsList.includes(CHECK_PAYMENT_REQUEST_BUTTON),
    [fetchingActionsList],
  )

  useAmplitudeInitialization(cohort as Cohort, ScreenName.CHECKOUT)

  useEffect(() => {
    if (productId && isCheckoutShown && selectedSubscription) {
      eventLogger.logPurchaseShown({
        accountId: stripeAccountId,
        accountName: stripeAccountName,
        productId,
        screenName,
        screenId,
      })

      window.fbq('track', 'InitiateCheckout', {}, { eventID: uuid })
      googleAnalyticsLogger.logCheckoutStarted(subscriptions)
      window.obApi && window.obApi('track', 'Checkout')
      window._tfa &&
        window._tfa.push({
          notify: 'event',
          name: 'start_checkout',
        })
    }
  }, [
    subscriptions,
    uuid,
    isCheckoutShown,
    selectedSubscription,
    stripeAccountId,
    stripeAccountName,
    productId,
    screenName,
    screenId,
  ])

  const handleCloseCheckout = useCallback(() => {
    dispatch(resetErrorAction())
    setIsCheckoutShown(false)

    googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
    eventLogger.logPurchaseScreenClosed({
      productId,
      screenName,
      screenId,
    })
  }, [
    dispatch,
    setIsCheckoutShown,
    pathname,
    cohort,
    productId,
    screenName,
    screenId,
  ])

  return (
    <>
      {threeDSecureIframeUrl ? (
        <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
      ) : (
        <>
          <S.Wrapper $isVisible={isCheckoutReady}>
            <S.Title>{t`purchase1.checkout.title`}</S.Title>
            <S.CloseButton
              data-testid="close-btn"
              onClick={handleCloseCheckout}
            />
            <S.PriceContainer>
              <S.PriceText>
                {t('purchase3.subscriptionsBlock.trialText', {
                  trialPeriodDays,
                })}
              </S.PriceText>
              <S.Price>
                <Trans
                  i18nKey="purchase3.subscriptionsBlock.price"
                  values={{
                    price: trialPrice,
                    currency: CURRENCY_SYMBOLS[currency],
                  }}
                />
              </S.Price>
            </S.PriceContainer>

            <S.PaymentContainer>
              {isCheckoutReady && <CreditCardFormCancelOffer />}
              {hasPayPalButton && (
                <>
                  <Separator />
                  <PayPalButton />
                </>
              )}
              <S.ButtonContainer>
                <PaymentRequestButton />
              </S.ButtonContainer>

              <S.Image src={securitySystems} alt="security-systems" />
              <S.Text>{t`purchase1.checkout.moneyBackGuarantee`}</S.Text>
            </S.PaymentContainer>
          </S.Wrapper>
          <PaymentWaitingModal
            isPaymentWaitingShown={isPaymentWaitingShown}
            setIsPaymentWaitingShown={setIsPaymentWaitingShown}
          />
          {!isCheckoutReady && <Spinner />}
        </>
      )}
    </>
  )
}
