import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSecurityInfoNewYear = {
  Wrapper: styled.div`
    position: relative;
    margin-bottom: 24px;
    padding: 40px 16px 24px;
    border: 2px solid #b3e5fc;
    border-radius: 24px;
  `,
  Title: styled.h2`
    padding-bottom: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  `,
  Text: styled.p`
    padding-bottom: 24px;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: ${Color.DISABLED};

    &:last-of-type {
      padding-bottom: 0;
    }

    a {
      font-weight: 700;
      color: ${Color.PRIMARY};
    }
  `,
  SecureBadge: styled.img`
    position: absolute;
    top: 18px;
    right: 16px;
    max-width: 104px;
  `,
}
