import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { NavigationButton } from 'components/NavigationButton'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentUserGender,
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import armsLineSvg from 'assets/images/sprite/arms-line-age-flow.svg'
import armsLineLongSvg from 'assets/images/sprite/arms-line.svg'
import bellyLineSvg from 'assets/images/sprite/belly-line-age-flow.svg'
import buttockLineLongSvg from 'assets/images/sprite/buttock-line-long.svg'
import buttockLineSvg from 'assets/images/sprite/buttock-line.svg'
import chestLineSvg from 'assets/images/sprite/chest-line-age-flow.svg'
import hipsLineSvg from 'assets/images/sprite/hips-line-age-flow.svg'
import hipsLineLongSvg from 'assets/images/sprite/hips-line.svg'
import legsLineSvg from 'assets/images/sprite/legs-line-age-flow.svg'

import { ADULT_COHORT, Cohort, GROWTHBOOK_EXPERIMENT } from 'root-constants'

import { ImproveAreasMx } from '../ImproveAreasMx'
import { StyledImproveAreas as S } from './ImproveAreas.styles'
import {
  BODY_IMAGE_MAP,
  BODY_IMAGE_MAP_AGE_FLOW,
  IMPROVE_AREAS_VALUES,
  QUESTION,
  STYLES_MAP,
  STYLES_MAP_AGE_FLOW,
} from './constants'

export const ImproveAreas: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  alternativePagePath,
}) => {
  const { t } = useTranslation()
  const gender = useSelector(selectCurrentUserGender)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const cohortToUse = parentCohort || cohort

  const hasNewIllustrations = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_599)

  const [answers, setAnswers] = useState<string[]>([])

  const countOfAllOptions = IMPROVE_AREAS_VALUES.FULL_BODY.split(',').length

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const mainImage = useMemo(
    () =>
      isAgeFlow ? BODY_IMAGE_MAP_AGE_FLOW[gender] : BODY_IMAGE_MAP[gender],
    [gender, isAgeFlow],
  )

  const areasProps = useMemo(
    () => (isAgeFlow ? STYLES_MAP_AGE_FLOW : STYLES_MAP),
    [isAgeFlow],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.CHECKBOX,
      onChange: (value, isChecked) => {
        const options = value.split(',')
        if (isChecked) {
          options.length === countOfAllOptions
            ? setAnswers([...options])
            : setAnswers([...answers, ...options])
        } else {
          const newValues =
            options.length === countOfAllOptions
              ? []
              : answers.filter((item) => item !== value)
          setAnswers(newValues)
        }
      },
    }),
    [answers, countOfAllOptions, pageId],
  )

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => handleContinue(answers), [
    answers,
    handleContinue,
  ])

  if (hasNewIllustrations) {
    return (
      <ImproveAreasMx
        pageId={pageId}
        nextPagePath={nextPagePath}
        alternativePagePath={alternativePagePath}
      />
    )
  }

  return (
    <div>
      <S.Container $isAgeFlow={isAgeFlow}>
        <S.TitleContainer>
          <PageTitle marginBottom={30} isAgeFlow>
            {t`onboarding.improveAreas.question`}
          </PageTitle>
        </S.TitleContainer>
        <Option
          {...optionProps}
          value={IMPROVE_AREAS_VALUES.FULL_BODY}
          checked={answers.length === countOfAllOptions}
        >
          <S.OptionContent
            withCheckbox
            $isAgeFlow={isAgeFlow}
            $isChecked={answers.length === countOfAllOptions}
          >
            <S.OptionText>{t`onboarding.improveAreas.fullBody`}</S.OptionText>
          </S.OptionContent>
        </Option>
        <S.Row>
          <picture>
            <source srcSet={mainImage.WEBP} type="image/webp" />
            <S.Image src={mainImage.PNG} width="202" alt="body" />
          </picture>

          <S.Col>
            <Option
              {...optionProps}
              value={IMPROVE_AREAS_VALUES.ARMS}
              checked={answers.includes(IMPROVE_AREAS_VALUES.ARMS)}
            >
              <S.OptionContent
                withCheckbox
                $isAgeFlow={isAgeFlow}
                $isChecked={answers.includes(IMPROVE_AREAS_VALUES.ARMS)}
                {...areasProps[gender][IMPROVE_AREAS_VALUES.ARMS]}
              >
                <S.OptionText>{t`onboarding.improveAreas.arms`}</S.OptionText>
                <S.SvgImage
                  $isAgeFlow={isAgeFlow}
                  svg={isAgeFlow ? armsLineSvg : armsLineLongSvg}
                  width={isAgeFlow ? 66 : 74}
                  $top={28}
                />
              </S.OptionContent>
            </Option>

            <Option
              {...optionProps}
              value={IMPROVE_AREAS_VALUES.CHEST}
              checked={answers.includes(IMPROVE_AREAS_VALUES.CHEST)}
            >
              <S.OptionContent
                withCheckbox
                $isAgeFlow={isAgeFlow}
                $isChecked={answers.includes(IMPROVE_AREAS_VALUES.CHEST)}
                {...areasProps[gender][IMPROVE_AREAS_VALUES.CHEST]}
              >
                <S.OptionText>{t`onboarding.improveAreas.chest`}</S.OptionText>
                <S.SvgImage
                  $isAgeFlow={isAgeFlow}
                  svg={chestLineSvg}
                  width={107}
                  $top={30}
                  $left={1}
                />
              </S.OptionContent>
            </Option>

            <Option
              {...optionProps}
              value={IMPROVE_AREAS_VALUES.BELLY}
              checked={answers.includes(IMPROVE_AREAS_VALUES.BELLY)}
            >
              <S.OptionContent
                withCheckbox
                $isChecked={answers.includes(IMPROVE_AREAS_VALUES.BELLY)}
                {...areasProps[gender][IMPROVE_AREAS_VALUES.BELLY]}
                $isAgeFlow={isAgeFlow}
              >
                <S.OptionText>{t`onboarding.improveAreas.belly`}</S.OptionText>
                <S.SvgImage
                  $isAgeFlow={isAgeFlow}
                  svg={bellyLineSvg}
                  width={112}
                  $top={hasNewIllustrations ? -20 : 26}
                />
              </S.OptionContent>
            </Option>

            <Option
              {...optionProps}
              value={IMPROVE_AREAS_VALUES.BUTTOCKS}
              checked={answers.includes(IMPROVE_AREAS_VALUES.BUTTOCKS)}
            >
              <S.OptionContent
                withCheckbox
                $isChecked={answers.includes(IMPROVE_AREAS_VALUES.BUTTOCKS)}
                {...areasProps[gender][IMPROVE_AREAS_VALUES.BUTTOCKS]}
                $isAgeFlow={isAgeFlow}
              >
                <S.OptionText>{t`onboarding.improveAreas.buttocks`}</S.OptionText>
                <S.SvgImage
                  $isAgeFlow={isAgeFlow}
                  svg={isAgeFlow ? buttockLineSvg : buttockLineLongSvg}
                  width={isAgeFlow ? 77 : 97}
                  $top={isAgeFlow ? -10 : 0}
                />
              </S.OptionContent>
            </Option>

            <Option
              {...optionProps}
              value={IMPROVE_AREAS_VALUES.HIPS}
              checked={answers.includes(IMPROVE_AREAS_VALUES.HIPS)}
            >
              <S.OptionContent
                withCheckbox
                $isAgeFlow={isAgeFlow}
                {...areasProps[gender][IMPROVE_AREAS_VALUES.HIPS]}
                $isChecked={answers.includes(IMPROVE_AREAS_VALUES.HIPS)}
              >
                <S.OptionText>{t`onboarding.improveAreas.hips`}</S.OptionText>
                <S.SvgImage
                  $isAgeFlow={isAgeFlow}
                  svg={isAgeFlow ? hipsLineSvg : hipsLineLongSvg}
                  width={isAgeFlow ? 80 : 109}
                  $top={-20}
                />
              </S.OptionContent>
            </Option>

            <Option
              {...optionProps}
              value={IMPROVE_AREAS_VALUES.LEGS}
              checked={answers.includes(IMPROVE_AREAS_VALUES.LEGS)}
            >
              <S.OptionContent
                withCheckbox
                $isAgeFlow={isAgeFlow}
                {...areasProps[gender][IMPROVE_AREAS_VALUES.LEGS]}
                $isChecked={answers.includes(IMPROVE_AREAS_VALUES.LEGS)}
              >
                <S.OptionText>{t`onboarding.improveAreas.legs`}</S.OptionText>
                <S.SvgImage
                  $isAgeFlow={isAgeFlow}
                  svg={legsLineSvg}
                  width={75}
                  $top={-22}
                />
              </S.OptionContent>
            </Option>
          </S.Col>
        </S.Row>
        <S.ProgramDescription $isAgeFlow={isAgeFlow}>
          <Trans
            i18nKey={
              isAgeFlow
                ? 'onboarding.improveAreas.programDescriptionAgeFlow'
                : 'onboarding.improveAreas.programDescription'
            }
            components={[<br />]}
          />
        </S.ProgramDescription>
      </S.Container>

      <NavigationButton
        type="button"
        isAgeFlow={isAgeFlow}
        disabled={!answers.length}
        onClick={handleNextClick}
      >
        {t`actions.continue`}
      </NavigationButton>
    </div>
  )
}
