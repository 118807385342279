import styled from 'styled-components'

import { Button } from 'components/Button'

import { Color } from 'root-constants'

import { Timer } from '../Timer'

export const StyledHeaderWithTimer = {
  TimerContainer: styled.div`
    position: sticky;
    top: 0;
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${Color.WHITE};
    z-index: 3;
    margin: 0 auto;
    box-shadow: 0 8px 20px 0 rgba(18, 22, 39, 0.1);
  `,
  TextContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `,
  ButtonContainer: styled.div`
    width: 100%;
    max-width: 160px;
    max-height: 48px;
  `,
  Text: styled.span`
    color: ${Color.DARK};
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  `,
  Timer: styled(Timer)`
    & div {
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      color: ${Color.DARK};
    }
  `,
  Button: styled(Button)`
    max-height: 48px;
    height: 48px;
  `,
}
